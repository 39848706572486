import {
  Button,
  DreamcatcherOption,
  Modal,
  TextInput,
} from "@pairtreefamily/ui";
import { useState } from "react";
import MetadataInput from "./MetadataInput";

export interface OptionCreatorProps {
  createOption: (step: DreamcatcherOption) => void;
}
const OptionCreator = (props: OptionCreatorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState<DreamcatcherOption>({
    label: "",
    id: "",
  });
  function handleOptionChange(value: string, name: string) {
    setOption({ ...option, [name]: value });
  }

  return (
    <>
      <Button style="primary" color="teal" onClick={() => setIsOpen(true)}>
        Add a new option
      </Button>
      <Modal
        title="Create option"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        primaryAction={{
          label: "Select",
          onSubmit: async () => {
            props.createOption(option);
            return true;
          },
        }}
      >
        <>
          <TextInput
            name="label"
            value={option.label}
            onChange={(data) => handleOptionChange(data, "label")}
            placeholder="Option Label"
            label="Option Label"
          />
          <TextInput
            name="id"
            value={option.id}
            onChange={(data) => handleOptionChange(data, "id")}
            placeholder="Option ID"
            label="Option ID"
          />
          <MetadataInput
            metadata={option.metadata ?? {}}
            documentationLink="https://docs.pairtreefamily.com/uhvhwNxzutFsz5/b/4D8FE105-8CF0-4CCE-9E4B-5DFB3AAFEB09/Metadata-on-Option"
            setMetadata={(metadata) => setOption({ ...option, metadata })}
          />
        </>
      </Modal>
    </>
  );
};

export default OptionCreator;
