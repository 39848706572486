import { createContext, useContext, useState } from "react";
import { DreamcatcherValue, pathsEqual } from "../schemas";

export type DreamcatcherPathContextType = {
  path: string[];
  setValues: (value: DreamcatcherValue[]) => void;
  values: DreamcatcherValue[];
};

export const DreamcatcherPathContext =
  createContext<DreamcatcherPathContextType | null>(null);

export const normalizePath = (path: string[]) => {
  return path.join(".");
};

export const useDreamcatcherPathContext = () => {
  const context = useContext(DreamcatcherPathContext);
  if (!context) {
    throw new Error(
      "useDreamcatcherPathContext must be used within a DreamcatcherPathProvider"
    );
  }
  return context;
};

export const useDreamcatcherInputPathAt = (relativePath: string) => {
  const context = useDreamcatcherPathContext();
  const path = [...context.path, relativePath];
  return {
    path,
    setValue: (value: DreamcatcherValue["value"]) => {
      let newValues = context.values.filter((v) => !pathsEqual(v.path, path));
      newValues.push({ path, value });
      context.setValues(newValues);
    },
    value: context.values.find((v) => {
      return pathsEqual(v.path, path);
    })?.value,
  };
};

export const useDreamcatcherPathAt = (relativePath: string) => {
  const { path: rootPath, values } = useDreamcatcherPathContext();
  const path = [...rootPath, relativePath];
  return {
    path,
    values: values.filter((v) => {
      return normalizePath(v.path).indexOf(normalizePath(path)) == 0;
    }),
  };
};

export const useDreamcatcherValueAccessor = (): ((
  path: string[]
) => DreamcatcherValue | null) => {
  const { values } = useDreamcatcherPathContext();
  return (path: string[]) => {
    const vals = values.filter((val) => pathsEqual(val.path, path));
    // there should only be one entry (set of values) per path
    return vals[0] ?? null;
  };
};

interface DreamcatcherValueProviderProps {
  path?: string;
  children: React.ReactNode;
  values?: DreamcatcherValue[];
  setValues?: (value: DreamcatcherValue[]) => void;
}

export const DreamcatcherPathProvider = ({
  path,
  children,
  values,
  setValues,
}: DreamcatcherValueProviderProps) => {
  const parent = useContext(DreamcatcherPathContext);
  // Create the stack by appending the new path to the parent's stack
  let pathStack;
  if (path) {
    pathStack = parent ? [...parent.path, path] : [path];
  } else {
    pathStack = parent ? [...parent.path] : [];
  }

  const [_values, _setValues] = useState<DreamcatcherValue[]>([]);

  return (
    <DreamcatcherPathContext.Provider
      value={{
        path: pathStack,
        setValues: setValues ?? parent?.setValues ?? _setValues,
        values: values ?? parent?.values ?? _values,
      }}
    >
      {children}
    </DreamcatcherPathContext.Provider>
  );
};
