import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

export type DropdownProps = {
  trigger: JSX.Element;
  children: JSX.Element;
  disabled?: boolean;
};

export function Dropdown(props: DropdownProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className="flex w-full justify-center gap-x-1.5"
        disabled={props.disabled}
      >
        {props.trigger}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-[25px] bg-white p-8 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>{props.children}</Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
