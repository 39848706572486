import { getClassName } from "@pairtreefamily/utils";
import { DreamcatcherCube, DreamcatcherCubeProps } from "./DreamcatcherCube";

export type DreamcatcherCubeGroupProps = {
  launcherActions: DreamcatcherCubeProps[];
};

export const DreamcatcherCubeGroup = ({
  launcherActions,
}: DreamcatcherCubeGroupProps) => {
  return (
    <div className="flex">
      <div className={getClassName("m-auto flex flex-wrap gap-6")}>
        {launcherActions.map((action) => (
          <div key={action.text}>
            <DreamcatcherCube {...action} />
          </div>
        ))}
      </div>
    </div>
  );
};
