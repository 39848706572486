import {
  BaseDreamcatcherInput,
  DreamcatcherCubeGroup,
  useDreamcatcherInputPathAt,
} from "../../..";
import { DreamcatcherInputPlugin } from "../";
import {
  DreamcatcherInputMetadataWithOptions,
  DreamcatcherInputMetadataWithOptionsSchema,
} from "./shared";
import { useDreamcatcherOptionMetadata } from ".";
import { DreamcatcherInputMetadataWithLabelSchema } from "../../metadata";
import { MetadataLabel } from "../../metadata";
import { z } from "zod";
import { DreamcatcherInputBaseMetadataSchema } from "../../metadata";

const DreamcatcherSingleSelectMetadataSchema =
  DreamcatcherInputMetadataWithOptionsSchema.merge(
    DreamcatcherInputMetadataWithLabelSchema
  ).merge(DreamcatcherInputBaseMetadataSchema);

export type DreamcatcherSingleSelectMetadata = z.infer<
  typeof DreamcatcherSingleSelectMetadataSchema
>;

export type DreamcatcherSingleSelectInput = BaseDreamcatcherInput<
  DreamcatcherSingleSelectMetadata,
  "single-select"
>;

export const DreamcatcherSingleSelectComponent = (
  props: DreamcatcherSingleSelectInput
) => {
  const { setValue, value } = useDreamcatcherInputPathAt(props.id);
  const optionsRes = useDreamcatcherOptionMetadata(props.metadata);

  if (!optionsRes.supportsOptions) {
    return null;
  }

  return (
    <div>
      {props.metadata && <MetadataLabel metadata={props.metadata} />}
      <DreamcatcherCubeGroup
        launcherActions={optionsRes.options.map((option) => ({
          text: option.label,
          action: { type: "noop" },
          style: "input",
          selected: value?.join(",") == option.id,
          "data-test": option.id,
          actionCallback: () => setValue([option.id]),
        }))}
      />
    </div>
  );
};

const DreamcatcherSingleSelectInputPlugin: DreamcatcherInputPlugin<
  DreamcatcherSingleSelectMetadata,
  "single-select"
> = {
  type: "single-select",
  name: "Single Select",
  component: DreamcatcherSingleSelectComponent,
  metadataSchema: DreamcatcherSingleSelectMetadataSchema,
};

export default DreamcatcherSingleSelectInputPlugin;
