export const XMarkIcon = (props: any) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5601 12.4393C15.1466 13.0257 15.1466 13.9737 14.5601 14.5601C14.2677 14.8526 13.8837 14.9996 13.4997 14.9996C13.1157 14.9996 12.7317 14.8526 12.4393 14.5601L7.5 9.62089L2.56075 14.5601C2.26826 14.8526 1.88428 14.9996 1.5003 14.9996C1.11632 14.9996 0.732338 14.8526 0.439853 14.5601C-0.146618 13.9737 -0.146618 13.0257 0.439853 12.4393L5.37911 7.5L0.439853 2.56075C-0.146618 1.97428 -0.146618 1.02632 0.439853 0.439853C1.02632 -0.146618 1.97428 -0.146618 2.56075 0.439853L7.5 5.37911L12.4393 0.439853C13.0257 -0.146618 13.9737 -0.146618 14.5601 0.439853C15.1466 1.02632 15.1466 1.97428 14.5601 2.56075L9.62089 7.5L14.5601 12.4393Z"
        fill="#414141"
      />
    </svg>
  );
};
