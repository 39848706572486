import { Spinner } from "@pairtreefamily/ui";

export type LoadingProps = {};

export function Loading(props: LoadingProps) {
  return (
    <div
      className="flex w-full items-center justify-center p-8 text-teal"
      aria-label="Loading..."
    >
      <Spinner />
    </div>
  );
}

export default Loading;
