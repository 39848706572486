import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { getClassName } from "@pairtreefamily/utils";
import {
  getColor,
  getListInputClassName,
  getOptionClassName,
  getOptionListClassName,
  getWrapperClassName,
} from "../../shared";

export type SelectProps<T extends string = string> = {
  options: SelectOption<T>[];
  value: T | null;
  onChange: (option: T) => void;
  placeholder?: string;
  name?: string;
  label?: string;
  whiteBorder?: boolean;
  color?:
    | "backgroundgray"
    | "teal"
    | "darkblue"
    | "green"
    | "backgroundgray-shade";
  disabled?: boolean;
  invalid?: boolean;
  "data-test"?: string;
};

export type SelectOption<T extends string = string> = {
  id: T;
  label: string;
};

export function Select<T extends string = string>(props: SelectProps<T>) {
  const {
    options,
    value,
    label,
    onChange,
    placeholder,
    whiteBorder,
    color,
    disabled,
    invalid,
  } = props;

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className={getWrapperClassName({ disabled })}>
          {label && <span className="text-body-4-semi mb-[5px]">{label}</span>}
          <Listbox.Button
            className={getClassName(
              getListInputClassName({ color, invalid, whiteBorder }),
              "cursor-default"
            )}
          >
            <span data-test={props["data-test"]} className="flex items-center">
              {value && (
                <span
                  className={getClassName(
                    "ml-3 block truncate",
                    whiteBorder && "text-white"
                  )}
                >
                  <strong>{options.find((o) => o.id === value)?.label}</strong>
                </span>
              )}
              {!value && (
                <span className={getClassName(whiteBorder && "text-white")}>
                  <em>{placeholder}</em>
                </span>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className={getClassName("h6 w-6", getColor({ type: "text" }))}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={getOptionListClassName({ color })}>
              {options.map((item) => (
                <Listbox.Option
                  key={item.id}
                  className={({ selected, active }) =>
                    getOptionClassName({ selected, active, color })
                  }
                  value={item.id}
                >
                  {({ selected }) => (
                    <>
                      <div className="flex items-center">
                        <span
                          className={getClassName(
                            selected ? "font-semibold" : "font-normal",
                            "ml-3 block truncate"
                          )}
                        >
                          {item.label}
                        </span>
                      </div>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export default Select;
