import { useQuestionAnswer } from "@/domains/questionAnswering";
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
  Button,
  BaseDreamcatcherInput,
  Metadata,
  TextInput,
  useConfirmationModal,
  DreamcatcherConditionalConfig,
  DreamcatcherCondition,
  DreamcatcherStepProps,
  DreamcatcherInput,
  DreamcatcherFlowProps,
} from "@pairtreefamily/ui";
import { v4 as uuidv4 } from "uuid";
import { useReducer, useState } from "react";
import ConditionalEditor from "./ConditionalEditor";
import { runIdempotentBackwardsCompatibilityFlowTransformations } from "./backwardsCompatibilityTransforms";
import FlowPreview from "./FlowPreview";
import FlowSelector from "./FlowSelector";
import InputCreator from "./InputCreator";
import InputEditor from "./InputEditor";
import MetadataInput from "./MetadataInput";
import StepCreator from "./StepCreator";
import StepEditor from "./StepEditor";

// XOR type - our action applies to either a step or an input
type StepXORInput =
  | {
      stepId: string;
      inputId?: never;
    }
  | {
      stepId?: never;
      inputId: string;
    };

type UpdateConditionalConfigAction = {
  type: "update_conditional_config";
  logicalOperator: DreamcatcherConditionalConfig["logicalOperator"];
} & StepXORInput;

type UpdateConditionAction = {
  type: "update_condition";
  condition: DreamcatcherCondition;
} & StepXORInput;

type AddConditionAction = {
  type: "add_condition";
  condition: Omit<DreamcatcherCondition, "id">;
} & StepXORInput;

type DeleteConditionAction = {
  type: "delete_condition";
  condition: DreamcatcherCondition;
} & StepXORInput;

type FlowAction =
  // Step
  | {
      type: "add_step";
      step: DreamcatcherStepProps;
    }
  | {
      type: "update_step";
      stepId: string;
      step: Partial<Omit<DreamcatcherStepProps, "inputs">>;
    }
  | {
      type: "delete_step";
      stepId: string;
    }
  // Input
  | {
      type: "add_input";
      stepId: string;
      input: DreamcatcherInput;
    }
  | {
      type: "update_input";
      stepId: string;
      inputId: string;
      input: Partial<Omit<BaseDreamcatcherInput, "type">>;
    }
  | {
      type: "delete_input";
      stepId: string;
      inputId: string;
    }
  // Flow
  | {
      type: "update_flow";
      flow: Partial<Omit<DreamcatcherFlowProps, "steps">>;
    }
  | {
      type: "load_flow";
      flow: DreamcatcherFlowProps;
    }
  | UpdateConditionalConfigAction
  | AddConditionAction
  | UpdateConditionAction
  | DeleteConditionAction;

const flowReducer = (
  state: DreamcatcherFlowProps,
  action: FlowAction
): DreamcatcherFlowProps => {
  if (action.type == "update_flow") {
    return {
      ...state,
      ...action.flow,
    };
  }
  if (action.type == "load_flow") {
    const flow = runIdempotentBackwardsCompatibilityFlowTransformations(
      action.flow
    );
    return {
      ...flow,
    };
  }
  if (action.type == "add_step") {
    return {
      ...state,
      steps: [...state.steps, action.step],
    };
  }
  if (action.type == "update_step") {
    return {
      ...state,
      steps: state.steps.map((step) => {
        if (step.id == action.stepId) {
          const newStep = {
            ...step,
            ...action.step,
          };
          if (action.step.nextStep === null || action.step.nextStep == "") {
            newStep.nextStep = null;
          }
          return newStep;
        }
        return step;
      }),
    };
  }
  if (action.type == "delete_step") {
    return {
      ...state,
      steps: state.steps.filter((step) => step.id != action.stepId),
    };
  }
  if (action.type == "add_input") {
    return {
      ...state,
      steps: state.steps.map((step) => {
        if (step.id == action.stepId) {
          return {
            ...step,
            inputs: [...step.inputs, action.input],
          };
        }
        return step;
      }),
    };
  }
  if (action.type == "update_input") {
    return {
      ...state,
      steps: state.steps.map((step) => {
        if (step.id == action.stepId) {
          return {
            ...step,
            inputs: step.inputs.map((input) => {
              if (input.id == action.inputId) {
                return {
                  ...input,
                  ...action.input,
                };
              }
              return input;
            }),
          };
        }
        return step;
      }),
    };
  }
  if (action.type == "delete_input") {
    return {
      ...state,
      steps: state.steps.map((step) => {
        if (step.id == action.stepId) {
          return {
            ...step,
            inputs: step.inputs.filter((input) => input.id != action.inputId),
          };
        }
        return step;
      }),
    };
  }
  if (action.type === "update_conditional_config") {
    return {
      ...state,
      steps: state.steps.map((step) => {
        if (action.stepId && step.id === action.stepId) {
          return {
            ...step,
            conditional: {
              conditions: step.conditional?.conditions ?? [],
              logicalOperator: action.logicalOperator,
            },
          };
        }
        return {
          ...step,
          inputs: step.inputs.map((input) => {
            if (action.inputId && input.id === action.inputId) {
              return {
                ...input,
                conditional: {
                  conditions: input.conditional?.conditions ?? [],
                  logicalOperator: action.logicalOperator,
                },
              };
            }
            return input;
          }),
        };
      }),
    };
  }
  if (action.type === "add_condition") {
    const newCondition: DreamcatcherCondition = {
      id: uuidv4(),
      ...action.condition,
    };
    const newConditionalConfig: DreamcatcherConditionalConfig = {
      logicalOperator: "or",
      conditions: [newCondition],
    };

    return {
      ...state,
      steps: state.steps.map((step) => {
        if (action.stepId && step.id === action.stepId) {
          if (!step.conditional) {
            return {
              ...step,
              conditional: newConditionalConfig,
            };
          }
          return {
            ...step,
            conditional: {
              ...step.conditional,
              conditions: [...step.conditional.conditions, newCondition],
            },
          };
        }
        return {
          ...step,
          inputs: step.inputs.map((input) => {
            if (action.inputId && input.id === action.inputId) {
              if (!input.conditional) {
                return {
                  ...input,
                  conditional: newConditionalConfig,
                };
              }
              return {
                ...input,
                conditional: {
                  ...input.conditional,
                  conditions: [...input.conditional.conditions, newCondition],
                },
              };
            }
            return input;
          }),
        };
      }),
    };
  }
  if (action.type === "update_condition") {
    const updateMatchingCondition = (c: DreamcatcherCondition) => {
      if (c.id === action.condition.id) {
        return action.condition;
      }
      return c;
    };

    return {
      ...state,
      steps: state.steps.map((step) => {
        if (action.stepId && step.id === action.stepId) {
          if (!step.conditional) {
            return step;
          }
          return {
            ...step,
            conditional: {
              ...step.conditional,
              conditions: step.conditional.conditions.map(
                updateMatchingCondition
              ),
            },
          };
        }
        return {
          ...step,
          inputs: step.inputs.map((input) => {
            if (action.inputId && input.id === action.inputId) {
              if (!input.conditional) {
                return input;
              }
              return {
                ...input,
                conditional: {
                  ...input.conditional,
                  conditions: input.conditional?.conditions.map(
                    updateMatchingCondition
                  ),
                },
              };
            }
            return input;
          }),
        };
      }),
    };
  }
  if (action.type === "delete_condition") {
    return {
      ...state,
      steps: state.steps.map((step) => {
        if (action.stepId && step.id === action.stepId) {
          if (!step.conditional) {
            return step;
          }
          const newConditions = step.conditional.conditions.filter(
            (c) => c.id !== action.condition.id
          );

          if (newConditions.length === 0) {
            // if we've just deleted the last condition, remove the
            // configuration entirely
            return {
              ...step,
              conditional: undefined,
            };
          }
          return {
            ...step,
            conditional: {
              ...step.conditional,
              conditions: newConditions,
            },
          };
        }
        return {
          ...step,
          inputs: step.inputs.map((input) => {
            if (action.inputId && input.id === action.inputId) {
              if (!input.conditional) {
                return input;
              }
              const newConditions = input.conditional.conditions.filter(
                (c) => c.id !== action.condition.id
              );

              if (newConditions.length === 0) {
                // if we've just deleted the last condition, remove the
                // configuration entirely
                return {
                  ...input,
                  conditional: undefined,
                };
              }
              return {
                ...input,
                conditional: {
                  ...input.conditional,
                  conditions: newConditions,
                },
              };
            }
            return input;
          }),
        };
      }),
    };
  }

  return state;
};

const FlowBuilder = (_props: {}) => {
  const { questionAnswerService } = useQuestionAnswer();
  const [flow, dispatchFlowAction] = useReducer(flowReducer, {
    id: "",
    steps: [],
    object: "flow",
    metadata: {},
  });

  const [quickFlowEmail, setQuickFlowEmail] = useState("");

  function handleFlowChange(value: string, name: string) {
    dispatchFlowAction({
      type: "update_flow",
      flow: { [name]: value },
    });
  }

  function handleInputChange(
    stepId: string,
    inputId: string,
    input: Partial<DreamcatcherInput>
  ) {
    dispatchFlowAction({
      type: "update_input",
      stepId: stepId,
      inputId: inputId,
      input,
    });
  }

  function handleAddStep(step: DreamcatcherStepProps) {
    dispatchFlowAction({
      type: "add_step",
      step: step,
    });
  }

  function handleStepChange(stepId: string, value: string, name: string) {
    dispatchFlowAction({
      type: "update_step",
      stepId: stepId,
      step: { [name]: value },
    });
  }

  function handleAddInput(stepId: string, input: DreamcatcherInput) {
    dispatchFlowAction({
      type: "add_input",
      stepId: stepId,
      input: input,
    });
  }

  const handleConditionalConfigChange = (
    args: {
      logicalOperator: "and" | "or";
    } & StepXORInput
  ) => {
    dispatchFlowAction({
      type: "update_conditional_config",
      ...args,
    });
  };

  const handleUpdateCondition = (
    args: {
      condition: DreamcatcherCondition;
    } & StepXORInput
  ) => {
    dispatchFlowAction({
      type: "update_condition",
      ...args,
    });
  };

  const handleAddCondition = (
    args: {
      condition: Omit<DreamcatcherCondition, "id">;
    } & StepXORInput
  ) => {
    dispatchFlowAction({
      type: "add_condition",
      ...args,
    });
  };

  const handleDeleteCondition = (
    args: {
      condition: DreamcatcherCondition;
    } & StepXORInput
  ) => {
    dispatchFlowAction({
      type: "delete_condition",
      ...args,
    });
  };

  async function saveFlow() {
    let flowId;
    try {
      const data = await questionAnswerService.storeFlow(flow);
      flowId = data.flowId;
    } catch (e) {
      console.log(e);
      // TODO show error
      return;
    }
    return flowId;
  }
  async function makeQuickFlow() {
    // on /quickFlow?flow=... it expects a URL encoded JSON string
    // so we need to encode the JSON string
    // and then encode the URL
    const url = new URL(window.location.href);
    const flowId = await saveFlow();
    if (!flowId) {
      return;
    }
    const encodedURL = `${url.origin}/quickFlow?flow=${flowId}&emailAddress=${quickFlowEmail}`;

    window.open(encodedURL, "_blank");
  }

  const { withConfirmation } = useConfirmationModal();

  return (
    <div className="space-between mb-24 flex">
      <div className="mr-8 flex-1">
        <div className="mb-5">
          <FlowSelector
            onFlowSelected={(flowId) => {
              questionAnswerService.getFlow(flowId).then((flow) => {
                if (flow) {
                  dispatchFlowAction({ type: "load_flow", flow });
                }
              });
            }}
          />
        </div>
        <h2 className="text-head-7">Flow</h2>
        <TextInput
          name="id"
          value={flow.id}
          onChange={(data) => handleFlowChange(data, "id")}
          label="Flow ID"
          placeholder="Flow ID"
        />

        <MetadataInput
          metadata={flow.metadata ?? {}}
          setMetadata={(metadata) => {
            dispatchFlowAction({
              type: "update_flow",
              flow: { metadata },
            });
          }}
        />

        <div className="flex items-center justify-between">
          <h2 className="text-head-6">Steps</h2>

          <div className="my-5">
            <StepCreator stepCreated={handleAddStep} />
          </div>
        </div>
        <div>
          {flow.steps.map((step, idx) => (
            <div key={idx} className="mb-1 border border-backgroundgray">
              <Accordion>
                <div className="flex w-full items-center justify-between bg-backgroundgray-tint px-2 py-1">
                  <AccordionButton className="flex w-full items-start">
                    <h3 className="text-head-7">{step.id}</h3>
                  </AccordionButton>
                  <Button
                    style="secondary"
                    color="peach"
                    onClick={() => {
                      withConfirmation(
                        "All inputs and options will be deleted.",
                        () => {
                          dispatchFlowAction({
                            type: "delete_step",
                            stepId: step.id,
                          });
                        }
                      );
                    }}
                  >
                    Delete
                  </Button>
                </div>
                <AccordionPanel>
                  <div className="p-2">
                    <StepEditor
                      step={step}
                      flowSteps={flow.steps}
                      handleStepChange={(value, name) =>
                        handleStepChange(step.id, value, name)
                      }
                    />
                    <ConditionalEditor
                      updateConditionalConfig={(o) => {
                        handleConditionalConfigChange({
                          stepId: step.id,
                          logicalOperator: o,
                        });
                      }}
                      deleteCondition={(c) => {
                        handleDeleteCondition({
                          stepId: step.id,
                          condition: c,
                        });
                      }}
                      upsertCondition={(c, id) => {
                        if (id) {
                          handleUpdateCondition({
                            stepId: step.id,
                            condition: { ...c, id: id },
                          });
                        } else {
                          handleAddCondition({
                            stepId: step.id,
                            condition: c,
                          });
                        }
                      }}
                      flowId={flow.id}
                      flowSteps={flow.steps}
                      conditional={step.conditional}
                    />
                    <div>
                      <h2 className="text-head-6">Inputs</h2>
                      <div className="my-5">
                        <InputCreator
                          inputCreated={(input) =>
                            handleAddInput(step.id, input)
                          }
                        />
                      </div>
                      {step.inputs.map((input, idx) => (
                        <div
                          key={idx}
                          className="mb-1 border border-backgroundgray bg-backgroundgray-tint"
                        >
                          <Accordion>
                            <div className="flex w-full items-center justify-between bg-backgroundgray-tint px-2 py-1">
                              <AccordionButton className="flex w-full items-start">
                                <h3 className="text-head-7">{input.id}</h3>
                              </AccordionButton>
                              <Button
                                style="secondary"
                                color="peach"
                                onClick={() => {
                                  withConfirmation(
                                    "All options in this input will also be deleted.",
                                    () =>
                                      dispatchFlowAction({
                                        type: "delete_input",
                                        stepId: step.id,
                                        inputId: input.id,
                                      })
                                  );
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                            <AccordionPanel>
                              <div className="p-2">
                                <InputEditor
                                  input={input}
                                  setInput={(newInput) =>
                                    handleInputChange(
                                      step.id,
                                      input.id,
                                      newInput
                                    )
                                  }
                                />

                                <ConditionalEditor
                                  updateConditionalConfig={(o) => {
                                    handleConditionalConfigChange({
                                      inputId: input.id,
                                      logicalOperator: o,
                                    });
                                  }}
                                  deleteCondition={(c) => {
                                    handleDeleteCondition({
                                      inputId: input.id,
                                      condition: c,
                                    });
                                  }}
                                  upsertCondition={(c, id) => {
                                    if (id) {
                                      handleUpdateCondition({
                                        inputId: input.id,
                                        condition: { ...c, id: id },
                                      });
                                    } else {
                                      handleAddCondition({
                                        inputId: input.id,
                                        condition: c,
                                      });
                                    }
                                  }}
                                  flowId={flow.id}
                                  flowSteps={flow.steps}
                                  conditional={input.conditional}
                                />
                              </div>
                            </AccordionPanel>
                          </Accordion>
                        </div>
                      ))}
                    </div>
                  </div>
                </AccordionPanel>
              </Accordion>
            </div>
          ))}
        </div>

        <br />
        <Button onClick={saveFlow} style="primary" color="teal">
          Save Flow
        </Button>
        <br />
        <TextInput
          name="quickFlowEmail"
          value={quickFlowEmail}
          onChange={(value: string) => setQuickFlowEmail(value)}
          placeholder="jack@pairtreefamily.com"
          label="Quick Flow Email"
        />
        <Button onClick={makeQuickFlow} style="primary" color="purple">
          Make Quick Flow
        </Button>
      </div>
      <div className="sticky top-0">
        <FlowPreview
          flow={flow}
          setFlow={(flow) => {
            dispatchFlowAction({ type: "load_flow", flow });
          }}
        />
      </div>
    </div>
  );
};

export default FlowBuilder;
