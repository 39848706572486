import { useCallback } from "react";

export type TextAreaProps = {
  value: string;
  onChange: (value: string) => void;
  label: JSX.Element | string;
  name?: string;
};

export function TextArea({ name, onChange, label, value }: TextAreaProps) {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <label className="my-4 flex flex-col items-start">
      <span className="text-body-4-semi mb-[5px]">{label}</span>

      <textarea
        name={name}
        className="text-body-2 h-[100px] w-full rounded-lg border border-platinum px-4 py-1.5 placeholder:italic placeholder:text-quicksilver focus:border-teal disabled:opacity-50"
        value={value}
        onChange={handleChange}
      />
    </label>
  );
}
