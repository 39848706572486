import { Ok as OkResult, Err as ErrResult } from "./types/result";

export function Ok<T>(success: T): OkResult<T> {
  return {
    ok: true,
    content: success,
  };
}

export function Err<E>(failure: E): ErrResult<E> {
  return {
    ok: false,
    error: failure,
  };
}
