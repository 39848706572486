import { useContext } from "react";
import { AuthStatusContext, AuthStatusData } from "../components";
export { type AuthStatusData } from "../components";

export const useAuthStatus = (): AuthStatusData => {
  const data = useContext(AuthStatusContext);
  if (data === null) {
    throw new Error(
      "Tried to useAuthStatus without a suitable parent provider."
    );
  }

  return data;
};
