export function ActiveBadgeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6995 9.99964C14.6995 12.5953 12.5953 14.6995 9.99964 14.6995C7.40397 14.6995 5.2998 12.5953 5.2998 9.99964C5.2998 7.40397 7.40397 5.2998 9.99964 5.2998C12.5953 5.2998 14.6995 7.40397 14.6995 9.99964Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M9.99961 1.09961C5.08444 1.09961 1.09961 5.08444 1.09961 9.99961C1.09961 14.9151 5.08444 18.8996 9.99961 18.8996C14.9151 18.8996 18.8996 14.9151 18.8996 9.99961C18.8996 5.08444 14.9151 1.09961 9.99961 1.09961ZM9.99961 16.6996C6.29944 16.6996 3.29961 13.6998 3.29961 9.99961C3.29961 6.29944 6.29944 3.29961 9.99961 3.29961C13.6998 3.29961 16.6996 6.29944 16.6996 9.99961C16.6996 13.6998 13.6998 16.6996 9.99961 16.6996Z"
        fill="currentColor"
      />
    </svg>
  );
}
