export function BellIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 25.1794C6.71667 25.1794 6.47917 25.0835 6.2875 24.8918C6.09583 24.7 6 24.4624 6 24.179C6 23.8955 6.09583 23.658 6.2875 23.4666C6.47917 23.2751 6.71667 23.1794 7 23.1794H8.41027V13.2307C8.41027 11.4375 8.96368 9.85203 10.0705 8.47425C11.1773 7.09648 12.5983 6.21529 14.3333 5.83069V4.99992C14.3333 4.53696 14.4952 4.14345 14.819 3.81939C15.1428 3.4953 15.5359 3.33325 15.9985 3.33325C16.461 3.33325 16.8547 3.4953 17.1794 3.81939C17.5042 4.14345 17.6666 4.53696 17.6666 4.99992V5.83069C19.4016 6.21529 20.8226 7.09648 21.9294 8.47425C23.0363 9.85203 23.5897 11.4375 23.5897 13.2307V23.1794H24.9999C25.2833 23.1794 25.5208 23.2753 25.7124 23.467C25.9041 23.6588 25.9999 23.8964 25.9999 24.1798C25.9999 24.4633 25.9041 24.7007 25.7124 24.8922C25.5208 25.0836 25.2833 25.1794 24.9999 25.1794H7ZM15.9977 28.9229C15.3343 28.9229 14.7671 28.6869 14.2961 28.2149C13.8252 27.7429 13.5897 27.1755 13.5897 26.5127H18.4102C18.4102 27.1776 18.174 27.7456 17.7015 28.2165C17.2291 28.6875 16.6611 28.9229 15.9977 28.9229ZM10.4102 23.1794H21.5897V13.2307C21.5897 11.6871 21.044 10.3695 19.9525 9.27809C18.8611 8.18662 17.5436 7.64089 16 7.64089C14.4564 7.64089 13.1388 8.18662 12.0474 9.27809C10.9559 10.3695 10.4102 11.6871 10.4102 13.2307V23.1794Z"
        fill="currentColor"
      />
    </svg>
  );
}
