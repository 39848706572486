import { Button, TextInput } from "@pairtreefamily/ui";
import { Modal } from "@pairtreefamily/ui/src/components/Modal";
import { useState } from "react";
import { useQuestionAnswer } from "../..";

// Uses localstorage to store recent selections.
// Uses state for reactivity, but backed by localstorage.
const useRecentSelections = () => {
  const [recentSelections, setRecentSelections] = useState<string[]>(() => {
    const recentSelections = localStorage.getItem("recentSelections");
    if (recentSelections) {
      return JSON.parse(recentSelections);
    }
    return [];
  });

  const addRecentSelection = (flowId: string) => {
    const newRecentSelections = [
      flowId,
      ...recentSelections.filter((id) => id !== flowId),
    ];
    setRecentSelections(newRecentSelections);
    localStorage.setItem(
      "recentSelections",
      JSON.stringify(newRecentSelections)
    );
  };

  return { addRecentSelection, recentSelections };
};

export interface FlowSelector {
  onFlowSelected: (flowId: string) => void;
}
const FlowSelector = (props: FlowSelector) => {
  const [flowId, setFlowId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { questionAnswerService } = useQuestionAnswer();
  const { addRecentSelection, recentSelections } = useRecentSelections();
  return (
    <>
      <div>
        <Button style="primary" color="teal" onClick={() => setIsOpen(true)}>
          Select an existing flow
        </Button>
      </div>
      <Modal
        title="Supply a flow ID"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        primaryAction={{
          label: "Select",
          onSubmit: async () => {
            const flow = await questionAnswerService.getFlow(flowId);
            if (flow) {
              props.onFlowSelected(flowId);
              addRecentSelection(flowId);
              setIsOpen(false);
              return true;
            }
            return false;
          },
        }}
      >
        <>
          <TextInput value={flowId} onChange={setFlowId} label="Flow ID" />
          {recentSelections.length > 0 && (
            <div className="mt-4">
              <div className="text-body-3 font-medium">Recent selections</div>
              <div className="mt-2">
                {recentSelections.map((flowId) => (
                  <div
                    key={flowId}
                    className="hover:bg-gray-100 cursor-pointer rounded-md p-2"
                    onClick={() => {
                      props.onFlowSelected(flowId);
                      setIsOpen(false);
                    }}
                  >
                    {flowId}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      </Modal>
    </>
  );
};

export default FlowSelector;
