import { Palette } from "@mui/material";

type CustomPalette = Omit<
  Palette,
  "common" | "tonalOffset" | "getContrastText" | "augmentColor"
> & { background: { light: string } };

export const palette: CustomPalette = {
  primary: {
    main: "#44978D",
    dark: "#3C867D",
    light: "#4CA99E",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#99CA44",
    dark: "#8DBF36",
    light: "#A4D058",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#DE4A39",
    dark: "#FFC107",
    light: "#E25F50",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#FFC107",
    dark: "#BD5942",
    light: "#FFC107",
    contrastText: "#000000",
  },
  info: {
    main: "#00A7E1",
    dark: "#00A7E1",
    light: "#00A7E1",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#414141",
    dark: "#333333",
    light: "#5E5E5E",
    contrastText: "#FFFFFF",
  },
  text: {
    primary: "#414141",
    secondary: "#333333",
    disabled: "#CCCCCC",
  },
  background: {
    default: "#FFFFFF",
    paper: "#FFFFFF",
    light: "#F7F7F7",
  },
  action: {
    active: "#FFBD00",
    hover: "#F6F6F6",
    hoverOpacity: 0.08,
    selected: "#000000",
    selectedOpacity: 0.16,
    disabled: "#CCCCCC",
    disabledBackground: "#000000",
    disabledOpacity: 0.38,
    focus: "#000000",
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
  divider: "#EBEBEB",
  grey: {
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FFFFFF",
    400: "#FFFFFF",
    500: "#FFFFFF",
    600: "#FFFFFF",
    700: "#FFFFFF",
    800: "#FFFFFF",
    900: "#FFFFFF",
    A100: "#FFFFFF",
    A200: "#FFFFFF",
    A400: "#FFFFFF",
    A700: "#FFFFFF",
  },
  mode: "light",
  contrastThreshold: 3,
};
