import { getClassName } from "@pairtreefamily/utils";

export type SpinnerProps = {
  // if true, icon will adapt to the container size
  adaptive?: boolean;
  className?: string;
};

export function Spinner(props: SpinnerProps) {
  return (
    <svg
      className={getClassName(props.className, "animate-spin")}
      width={props.adaptive ? undefined : "50"}
      height={props.adaptive ? undefined : "50"}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1854 25.0294C2.1854 37.6106 12.3611 47.8096 24.9136 47.8096C37.466 47.8096 47.6417 37.6106 47.6417 25.0294H49.8271C49.8271 38.8203 38.6729 50 24.9136 50C11.1542 50 0 38.8203 0 25.0294H2.1854Z"
        fill="#EBEBEB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9136 2.24924C12.3611 2.24924 2.1854 12.4483 2.1854 25.0294H0C0 11.2386 11.1542 0.0588379 24.9136 0.0588379C38.6729 0.0588379 49.8271 11.2386 49.8271 25.0294H47.6417C47.6417 12.4483 37.466 2.24924 24.9136 2.24924Z"
        fill="#EBEBEB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1283 8.86253C50.0078 17.7623 50.0595 32.0094 41.1872 40.9021L42.7325 42.4509C52.4654 32.6958 52.3994 17.0618 42.6736 7.31368C37.1771 1.80453 29.7498 -0.591732 22.5776 0.123218L22.7939 2.30286C29.3422 1.65011 36.1145 3.83721 41.1283 8.86253Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Spinner;
