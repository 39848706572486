export const InfoIconFilled = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM26 15C26 16.1046 25.1046 17 24 17C22.8954 17 22 16.1046 22 15C22 13.8954 22.8954 13 24 13C25.1046 13 26 13.8954 26 15ZM22 22C22 20.8954 22.8954 20 24 20C25.1046 20 26 20.8954 26 22V32C26 33.1046 25.1046 34 24 34C22.8954 34 22 33.1046 22 32V22Z"
        fill="currentColor"
      />
    </svg>
  );
};
