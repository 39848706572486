export type StatusType = "success" | "error" | "warning" | "neutral";

const filledStatusMap: Record<StatusType, string> = {
  success: "fill-green",
  warning: "fill-mango",
  error: "fill-rust",
  neutral: "fill-lightgray",
};

const unfilledStatusMap: Record<StatusType, string> = {
  success: "stroke-green",
  warning: "stroke-mango",
  error: "stroke-rust",
  neutral: "stroke-lightgray",
};

export const StatusIcon = ({
  filled = true,
  status,
}: {
  filled?: boolean;
  status: StatusType;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    className="mr-2"
  >
    {filled ? (
      <circle className={filledStatusMap[status]} cx="3.5" cy="3.5" r="3.5" />
    ) : (
      <circle className={unfilledStatusMap[status]} cx="3.5" cy="3.5" r="3.5" />
    )}
  </svg>
);
