import { FirebaseApp } from "firebase/app";
import { createContext, ReactNode } from "react";
import { useFirebaseAuth } from "../../hooks/useFirebaseAuth";

export type AuthStatusData = {
  getToken: () => Promise<string | null>;
  authedUser: {
    email: string | null;
  } | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  needsAccount: boolean | null;
  signOut: () => void;
  isAdmin: boolean;
  isFlowBuilderUser: boolean;
};

export const AuthStatusContext = createContext<AuthStatusData | null>(null);

export type AuthStatusProviderProps = {
  app: FirebaseApp;
  children: ReactNode;
  getSSOCustomJwt?: () => string | null;
};

export const AuthStatusProvider = (props: AuthStatusProviderProps) => {
  const firebaseAuthData = useFirebaseAuth({
    app: props.app,
    getSSOCustomJwt: props.getSSOCustomJwt,
  });

  const val: AuthStatusData = {
    getToken: async () =>
      (await firebaseAuthData.currentUser?.getIdToken()) ?? null,
    isAuthenticated: firebaseAuthData.isAuthenticated,
    isLoading: firebaseAuthData.isLoading,
    authedUser: firebaseAuthData.currentUser,
    signOut: firebaseAuthData.signOut,
    needsAccount: firebaseAuthData.needsAccount,
    isAdmin: firebaseAuthData.isAdmin,
    isFlowBuilderUser: firebaseAuthData.isFlowBuilderUser,
  };

  return (
    <AuthStatusContext.Provider value={val}>
      {props.children}
    </AuthStatusContext.Provider>
  );
};
