import { getClassName } from "@pairtreefamily/utils";
import { ButtonColor, ButtonProps, ButtonStyle } from ".";

const primaryColorMap: Record<ButtonColor, string> = {
  colorless: "",
  teal: getClassName(
    "bg-teal enabled:hover:bg-teal-tint text-white active:enabled:bg-teal disabled:bg-lightgray disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-teal enabled:hover:border-teal-tint active:enabled:border-teal disabled:text-white"
  ),
  green: getClassName(
    "bg-green enabled:hover:bg-green-tint text-white active:enabled:bg-green disabled:bg-lightgray disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-green enabled:hover:border-green-tint active:enabled:border-green disabled:text-white"
  ),
  purple: getClassName(
    "bg-purple enabled:hover:bg-purple-tint text-white active:enabled:bg-purple disabled:bg-lightgray disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-purple enabled:hover:border-purple-tint active:enabled:border-purple disabled:text-white"
  ),
  peach: getClassName(
    "bg-peach enabled:hover:bg-peach-tint text-white active:enabled:bg-peach disabled:bg-lightgray disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-peach enabled:hover:border-peach-tint active:enabled:border-peach disabled:text-white"
  ),
  rust: getClassName(
    "bg-rust enabled:hover:bg-rust-tint text-white active:enabled:bg-rust disabled:bg-lightgray disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-rust enabled:hover:border-rust-tint active:enabled:border-rust disabled:text-white"
  ),
};

const secondaryColorMap: Record<ButtonColor, string> = {
  colorless: "",
  teal: getClassName(
    "bg-none enabled:hover:bg-teal-tint active:enabled:bg-teal text-teal enabled:hover:text-white disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-teal enabled:hover:border-teal-tint active:enabled:border-teal disabled:text-lightgray"
  ),
  green: getClassName(
    "bg-none enabled:hover:bg-green-tint active:enabled:bg-green text-green enabled:hover:text-white disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-green enabled:hover:border-green-tint active:enabled:border-green disabled:text-lightgray"
  ),
  purple: getClassName(
    "bg-none enabled:hover:bg-purple-tint active:enabled:bg-purple text-purple enabled:hover:text-white disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-purple enabled:hover:border-purple-tint active:enabled:border-purple disabled:text-lightgray"
  ),
  peach: getClassName(
    "bg-none enabled:hover:bg-peach-tint active:enabled:bg-peach text-peach enabled:hover:text-white disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-peach enabled:hover:border-peach-tint active:enabled:border-peach disabled:text-lightgray"
  ),
  rust: getClassName(
    "bg-none enabled:hover:bg-rust-tint active:enabled:bg-rust text-rust enabled:hover:text-white disabled:border-lightgray disabled:cursor-default",
    "border-[2px] border-rust enabled:hover:border-rust-tint active:enabled:border-rust disabled:text-lightgray"
  ),
};

const textColorMap: Record<ButtonColor, string> = {
  colorless: "",
  teal: getClassName(
    "text-teal enabled:hover:text-teal-tint active:enabled:text-teal disabled:text-lightgray disabled:cursor-default"
  ),
  green: getClassName(
    "text-green enabled:hover:text-green-tint active:enabled:text-green disabled:text-lightgray disabled:cursor-default"
  ),
  purple: getClassName(
    "text-purple enabled:hover:text-purple-tint active:enabled:text-purple disabled:text-lightgray disabled:cursor-default"
  ),
  peach: getClassName(
    "text-peach enabled:hover:text-peach-tint active:enabled:text-peach disabled:text-lightgray disabled:cursor-default"
  ),
  rust: getClassName(
    "text-rust enabled:hover:text-rust-tint active:enabled:text-rust disabled:text-lightgray disabled:cursor-default"
  ),
};

const buttonStyleMap: Record<ButtonStyle, Record<ButtonColor, string>> = {
  primary: primaryColorMap,
  secondary: secondaryColorMap,
  text: textColorMap,
};

type ButtonStyleArgs = Pick<
  ButtonProps,
  "color" | "style" | "disabled" | "loading" | "textAlign" | "height"
>;
export function getButtonStyle({
  color = "colorless",
  style = "primary",
  textAlign = "center",
  height = "50px",
  disabled,
  loading,
}: ButtonStyleArgs): string {
  const buttonStyle = getClassName(
    // size + shape
    "inline-block",
    "rounded-full",
    style !== "text" ? "py-[16px] px-[20px]" : "",
    height === "50px" ? "h-[50px]" : "h-[25px]",
    "enabled:hover:scale-105",
    // animations
    "enabled:hover:transition-all duration-75",
    // text/content
    "flex items-center",
    textAlign === "center"
      ? "text-center justify-center"
      : "text-left justify-start",
    "text-body-3 font-bold",
    "leading-none", // set line-height of text contents to equal font height; creates better centering if text happens to wrap
    // interaction
    disabled || loading ? "cursor-default" : "cursor-pointer",
    // color
    buttonStyleMap[style][color]
  );
  return buttonStyle;
}
