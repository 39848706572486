var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dd9a4a3303aec8bc0d9cc8a6badfe05b88d30fef"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: process.env.NODE_ENV !== "development",
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  dsn:
    SENTRY_DSN ||
    "https://24664ade88ab47b695f2390b56ae0027@o1027472.ingest.sentry.io/4505290723033088",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // Record 10% of all session replays
  replaysSessionSampleRate: 0.1,
  // Record 100% of session replays with Errors
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      // send tracing headers when making requests to the core api
      tracePropagationTargets: [
        "localhost", // default sentry origin
        /^\//, // default sentry origin
        process.env.NEXT_PUBLIC_CORE_API_URL,
      ],
    }),
    new Sentry.Replay(),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
