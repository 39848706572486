import {
  Button,
  DreamcatcherInput,
  Modal,
  Select,
  TextInput,
  dreamcatcherInputList,
  getMetadataSchemaFromType,
  getMetadataSchema,
} from "@pairtreefamily/ui";
import { useReducer, useState } from "react";
import { getDefaultMetadataFromSchema } from "../../utils";
import MetadataInput from "./MetadataInput";

export interface InputCreatorProps {
  inputCreated: (input: DreamcatcherInput) => void;
}

type SetInputAction =
  | {
      type: "setInputType";
      value: DreamcatcherInput["type"];
    }
  | {
      type: "setInputData";
      value: Partial<DreamcatcherInput>;
    };

const InputCreator = (props: InputCreatorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [input, dispatchInput] = useReducer(
    (
      input: DreamcatcherInput | null,
      action: SetInputAction
    ): DreamcatcherInput | null => {
      switch (action.type) {
        case "setInputType":
          const metadataSchema = getMetadataSchemaFromType(action.value);
          return {
            ...(input ?? {
              id: "",
              object: "input",
            }),
            type: action.value,
            metadata: metadataSchema
              ? metadataSchema.parse(
                  getDefaultMetadataFromSchema(metadataSchema)
                )
              : {},
          } as DreamcatcherInput;
        case "setInputData":
          return {
            ...(input ?? {
              id: "",
              object: "input",
            }),
            ...action.value,
          } as DreamcatcherInput;
        default:
          return input;
      }
    },
    null
  );
  function handleInputChange(value: any, name: string) {
    dispatchInput({ type: "setInputData", value: { [name]: value } });
  }

  return (
    <>
      <Button style="primary" color="teal" onClick={() => setIsOpen(true)}>
        Add a new input
      </Button>
      <Modal
        title="Create input"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        primaryAction={{
          label: "Select",
          onSubmit: async () => {
            if (input === null || input.type === null) {
              return false;
            } else {
              props.inputCreated({
                ...input,
                // yes, this is necessary because otherwise `input` is still treated
                // as its original type that has a `null`able `type` property.
                // This line utilizes built in type narrowing in TypeScript.
                type: input.type,
              });
            }
            return true;
          },
        }}
      >
        <>
          <Select
            placeholder="Input Type"
            value={input?.type ?? null}
            options={[
              ...dreamcatcherInputList.map((input) => ({
                id: input.type,
                label: input.name,
              })),
            ]}
            onChange={(selectedType) => {
              dispatchInput({
                type: "setInputType",
                value: selectedType,
              });
            }}
          />
          {input && (
            <>
              <TextInput
                name="id"
                value={input.id ?? ""}
                onChange={(data) => handleInputChange(data, "id")}
                placeholder="age_select"
                label="Input ID"
              />
              <MetadataInput
                key={input.type}
                metadata={input.metadata ?? {}}
                documentationLink="https://docs.pairtreefamily.com/uhvhwNxzutFsz5/b/77044B5E-4F96-4BA7-BB9E-09F0CAE73910/Metadata-on-Input"
                setMetadata={(metadata) =>
                  handleInputChange(metadata, "metadata")
                }
                schemaValidator={getMetadataSchema(input)}
              />
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default InputCreator;
