export function CompletedDocumentIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3516 3.18401H14.4873C14.1987 3.18401 14.002 3.11948 13.8917 2.97808L12.2484 0.952512C11.7741 0.347078 11.0557 0 10.2755 0H2.50139C1.12193 0 0 1.12535 0 2.50886V18.1596C0 19.7531 1.29775 21.0492 2.8926 21.0492H5.82127C7.04625 23.3944 9.501 25 12.3253 25C15.1496 25 17.6047 23.3947 18.8296 21.0492H21.9606C23.4827 21.0492 24.587 19.834 24.587 18.1596V5.87335C24.587 4.31515 23.6468 3.18415 22.3518 3.18415L22.3516 3.18401ZM12.3253 23.2314C9.25641 23.2314 6.75972 20.7345 6.75972 17.6658C6.75972 14.5971 9.25663 12.1002 12.3253 12.1002C15.394 12.1002 17.8909 14.5971 17.8909 17.6658C17.8909 20.7345 15.394 23.2314 12.3253 23.2314ZM22.8181 18.1595C22.8181 18.4225 22.7564 19.2805 21.9606 19.2805H19.4787C19.596 18.7606 19.66 18.2207 19.66 17.6657C19.66 13.6214 16.3698 10.331 12.3253 10.331C8.28083 10.331 4.99061 13.6212 4.99061 17.6657C4.99061 18.2207 5.05464 18.7606 5.17194 19.2805H2.89209C2.26205 19.2805 1.7685 18.7882 1.7685 18.1595V2.50872C1.7685 2.1007 2.09707 1.7688 2.50124 1.7688H10.2754C10.5088 1.7688 10.7208 1.86968 10.8576 2.04592L12.507 4.079C12.8176 4.47777 13.4131 4.95288 14.487 4.95288H22.3512C22.6695 4.95288 22.8178 5.50197 22.8178 5.87338V18.1594L22.8181 18.1595Z"
        fill="currentColor"
      />
      <path
        d="M14.3823 15.32L11.4697 18.252L10.2665 17.0442C9.92172 16.6982 9.36187 16.6971 9.01556 17.0418C8.66958 17.3866 8.66849 17.9467 9.01323 18.2927L10.6434 19.9288C10.8642 20.1503 11.1577 20.2723 11.4701 20.2723H11.4711C11.7838 20.272 12.0779 20.1496 12.2978 19.9275L15.6371 16.5663C15.9813 16.2198 15.9795 15.6598 15.633 15.3156C15.2868 14.9714 14.7267 14.9729 14.3824 15.32L14.3823 15.32Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CompletedDocumentIcon;
