import { useCoreAPI } from "@/hooks/useCoreAPI";
import { createContext, useContext } from "react";
import {
  QuestionAnswerFlowService,
  QuestionAnswerFlowHttpService,
} from "../services/QuestionAnswerFlowService";

export const QuestionAnswerContext =
  createContext<QuestionAnswerContextData | null>(null);

export interface QuestionAnswerContextData {
  questionAnswerService: QuestionAnswerFlowService;
}

export const useQuestionAnswer = () => {
  const context = useContext(QuestionAnswerContext);
  if (!context) {
    throw new Error(
      "useQuestionAnswer must be used within a QuestionAnswerProvider"
    );
  }
  return context;
};

export const QuestionAnswerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const CoreAPI = useCoreAPI();
  const questionAnswerService = new QuestionAnswerFlowHttpService(
    "",
    CoreAPI.getUserAuthorizationHeader()
  );
  return (
    <QuestionAnswerContext.Provider value={{ questionAnswerService }}>
      {children}
    </QuestionAnswerContext.Provider>
  );
};
