import { RadioInput, RadioInputProps } from "../../RadioInput";
import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { ErrorMessage } from "../error-message";

export interface RadioListFieldProps
  extends Omit<
    RadioInputProps,
    "onChange" | "value" | "checked" | "groupName"
  > {
  name: string;
  options: { value: string | boolean | null; label: string }[];
  wrapperClassName?: string;
}

export const RadioListField = ({
  options,
  name,
  wrapperClassName = "",
  ...rest
}: RadioListFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <div className={wrapperClassName}>
          {options.map(({ value, label }) => {
            return (
              <RadioInput
                key={`${name}-${value}`}
                groupName={name}
                value={value}
                checked={field.value === value}
                label={label}
                onChange={field.onChange}
                {...rest}
              />
            );
          })}

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </div>
      );
    },
    [name, options, rest, wrapperClassName]
  );

  return <Controller name={name} render={render} />;
};
