import {
  Button,
  DreamcatcherCondition,
  DreamcatcherConditionalConfig,
  DreamcatcherStepProps,
} from "@pairtreefamily/ui";
import { useState } from "react";
import ConditionCard from "./ConditionCard";
import EditConditionModal from "./EditConditionModal";

export type ConditionalEditorProps = {
  conditional?: DreamcatcherConditionalConfig;
  flowSteps: DreamcatcherStepProps[];
  flowId: string;
  deleteCondition: (condition: DreamcatcherCondition) => void;
  // TODO better optional/partial type here
  upsertCondition: (
    condition: Omit<DreamcatcherCondition, "id">,
    id?: string
  ) => void;
  updateConditionalConfig: (logicalOperator: "and" | "or") => void;
};

export function ConditionalEditor({
  conditional,
  ...props
}: ConditionalEditorProps) {
  const [editorOpen, setEditorOpen] = useState<boolean>(false);
  const [selectedCondition, setSelectedCondition] =
    useState<DreamcatcherCondition | null>(null);

  return (
    <>
      <p className="text-body-1 text-black">
        <strong>Conditions</strong>
      </p>
      <Button style="primary" color="teal" onClick={() => setEditorOpen(true)}>
        Add condition
      </Button>
      {conditional && (
        <>
          <label className="text-body-4">
            <input
              type="checkbox"
              checked={conditional.logicalOperator === "and"}
              onChange={(e) =>
                props.updateConditionalConfig(e.target.checked ? "and" : "or")
              }
            />
            <span className="ml-2">
              all of the following conditions must be met
            </span>
          </label>
          <div className="grid grid-cols-3 gap-4">
            {conditional.conditions.map((c, i) => {
              return (
                <ConditionCard
                  key={i}
                  condition={c}
                  editCondition={() => {
                    setSelectedCondition(c);
                    setEditorOpen(true);
                  }}
                  deleteCondition={() => props.deleteCondition(c)}
                />
              );
            })}
          </div>
        </>
      )}
      <EditConditionModal
        condition={selectedCondition ?? undefined}
        flowId={props.flowId}
        updateCondition={props.upsertCondition}
        steps={props.flowSteps}
        isOpen={editorOpen}
        onClose={() => {
          setEditorOpen(false);
          setSelectedCondition(null);
        }}
      />
    </>
  );
}

export default ConditionalEditor;
