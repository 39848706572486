import { Box } from "@mui/material";
import { ReactNode } from "react";

type ModalActionsWrapperProps = {
  children: ReactNode;
};

export const ModalActionsWrapper = ({ children }: ModalActionsWrapperProps) => {
  return (
    <Box pt={4} sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
      {children}
    </Box>
  );
};
