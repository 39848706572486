import { palette } from "../palette";

export const MuiTabs = {
  styleOverrides: {
    root: {
      minHeight: "40px",
    },
    flexContainer: {
      gridGap: "32px",
    },
    indicator: {
      backgroundColor: palette.secondary.main,
      height: 3,
    },
  },
};
