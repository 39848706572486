import {
  BaseDreamcatcherInput,
  DreamcatcherCubeGroup,
  useDreamcatcherInputPathAt,
} from "../../..";
import { DreamcatcherInputPlugin } from "../";
import { DreamcatcherInputMetadataWithOptionsSchema } from "./shared";
import { useDreamcatcherOptionMetadata } from ".";
import { z } from "zod";
import { DreamcatcherInputBaseMetadataSchema } from "../../metadata";
import { DreamcatcherInputMetadataWithLabelSchema } from "../../metadata";
import { MetadataLabel } from "../../metadata";

const DreamcatcherMultiSelectMetadataSchema =
  DreamcatcherInputMetadataWithOptionsSchema.merge(
    DreamcatcherInputMetadataWithLabelSchema
  ).merge(DreamcatcherInputBaseMetadataSchema);

export type DreamcatcherMultiSelectMetadata = z.infer<
  typeof DreamcatcherMultiSelectMetadataSchema
>;

export type DreamcatcherMultiSelectInput = BaseDreamcatcherInput<
  DreamcatcherMultiSelectMetadata,
  "multi-select"
>;

export const DreamcatcherMultiSelectComponent = (
  props: DreamcatcherMultiSelectInput
) => {
  const { setValue, value } = useDreamcatcherInputPathAt(props.id);
  const optionsRes = useDreamcatcherOptionMetadata(props.metadata);

  if (!optionsRes.supportsOptions) {
    return null;
  }

  return (
    <div>
      {props.metadata && <MetadataLabel metadata={props.metadata} />}
      <DreamcatcherCubeGroup
        launcherActions={optionsRes.options.map((option) => ({
          text: option.label,
          action: { type: "noop" },
          style: "input",
          "data-test": option.id,
          selected: (value?.find((v) => v == option.id) ?? null) !== null,
          actionCallback: () => {
            if (!value) {
              setValue([option.id]);
              return;
            }
            if (value.find((v) => v == option.id)) {
              setValue(value?.filter((v) => v != option.id) ?? []);
            } else {
              setValue([...value, option.id]);
            }
          },
        }))}
      />
    </div>
  );
};

const DreamcatcherMultiSelectInputPlugin: DreamcatcherInputPlugin<
  DreamcatcherMultiSelectMetadata,
  "multi-select"
> = {
  type: "multi-select",
  name: "Multi Select",
  component: DreamcatcherMultiSelectComponent,
  metadataSchema: DreamcatcherMultiSelectMetadataSchema,
};

export default DreamcatcherMultiSelectInputPlugin;
