import { useContext } from "react";
import { CoreAPIContext } from "../components/CoreAPIProvider";

export const useCoreAPI = () => {
  const data = useContext(CoreAPIContext);
  if (data === null) {
    throw new Error("Tried to useCoreAPI without a suitable parent provider.");
  }

  return data;
};
