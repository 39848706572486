import {
  Button,
  DreamcatcherStepProps,
  Modal,
  TextInput,
} from "@pairtreefamily/ui";
import { useState } from "react";

export interface StepCreatorProps {
  stepCreated: (step: DreamcatcherStepProps) => void;
}
const StepCreator = (props: StepCreatorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<DreamcatcherStepProps>({
    id: "",
    title: "",
    inputs: [],
    confirmText: "",
    nextStep: null,
    object: "step",
  });
  function handleStepChange(value: string, name: string) {
    setStep({ ...step, [name]: value });
  }

  return (
    <>
      <Button style="primary" color="teal" onClick={() => setIsOpen(true)}>
        Add a new step
      </Button>
      <Modal
        title="Create new step"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        primaryAction={{
          label: "Select",
          onSubmit: async () => {
            props.stepCreated(step);
            return true;
          },
        }}
      >
        <>
          <TextInput
            name="id"
            value={step.id}
            onChange={(data) => handleStepChange(data, "id")}
            label="Step ID"
            placeholder="ID"
          />
          <TextInput
            name="title"
            value={step.title}
            onChange={(data) => handleStepChange(data, "title")}
            label="Step Title"
            placeholder="Title"
          />
          <TextInput
            name="description"
            value={step.description ?? ""}
            onChange={(data) => handleStepChange(data, "description")}
            label="Step Description"
            placeholder="Description"
          />
          <TextInput
            name="confirmText"
            value={step.confirmText}
            onChange={(data) => handleStepChange(data, "confirmText")}
            label="Confirmation Text"
            placeholder="Step Confirm Text"
          />
        </>
      </Modal>
    </>
  );
};

export default StepCreator;
