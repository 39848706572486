import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import DateInput, { DateInputProps } from "../../DateInput";
import { ErrorMessage } from "../error-message";

export interface DateInputFieldProps
  extends Omit<DateInputProps, "onChange" | "value"> {
  name: string;
}

export const DateInputField = ({ name, ...rest }: DateInputFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <div className="flex flex-col">
          <DateInput onChange={field.onChange} value={field.value} {...rest} />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </div>
      );
    },
    [rest]
  );

  return <Controller name={name} render={render} />;
};
