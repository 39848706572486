export const GoogleIcon = (props: any) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.0797 11.521C22.0806 10.7534 22.0157 9.9872 21.8857 9.23071H11.2633V13.5688H17.3473C17.2228 14.2617 16.9593 14.9222 16.5727 15.5105C16.1861 16.0987 15.6843 16.6026 15.0977 16.9918V19.8077H18.7287C20.8547 17.8475 22.0797 14.9487 22.0797 11.521Z"
      fill="currentColor"
    />
    <path
      d="M11.2632 22.5284C14.3029 22.5284 16.8623 21.5303 18.7286 19.8095L15.0976 16.9935C14.087 17.6787 12.7854 18.0699 11.2632 18.0699C8.32525 18.0699 5.83158 16.0893 4.93986 13.4204H1.19934V16.3224C2.13684 18.1879 3.5744 19.7562 5.35152 20.8521C7.12864 21.948 9.17538 22.5283 11.2632 22.5284Z"
      fill="currentColor"
    />
    <path
      d="M4.93991 13.4203C4.46849 12.0218 4.46849 10.5073 4.93991 9.10878V6.20679H1.19939C0.410734 7.77614 0 9.50817 0 11.2645C0 13.0209 0.410734 14.7529 1.19939 16.3223L4.93991 13.4203Z"
      fill="currentColor"
    />
    <path
      d="M11.2632 4.45932C12.8695 4.43308 14.4217 5.04 15.5841 6.14889L18.799 2.93402C16.7605 1.01929 14.0598 -0.0319245 11.2632 0.00073901C9.17538 0.000834166 7.12864 0.581223 5.35152 1.67711C3.5744 2.77299 2.13684 4.34125 1.19934 6.20678L4.93986 9.10877C5.83158 6.43987 8.32525 4.45932 11.2632 4.45932Z"
      fill="currentColor"
    />
  </svg>
);
