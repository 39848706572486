import { Button, Dropdown, useAuthStatus } from "@pairtreefamily/ui";
import HelpModalButton from "./HelpModalButton";
import { useAccount } from "@/hooks/useAccount";
import { UserIcon } from "./icons/user";

const NavBar = () => {
  const { account } = useAccount();
  const { signOut, isAuthenticated } = useAuthStatus();

  return (
    <div className="flex w-full justify-between py-[20px] px-[20px]">
      <img
        className="py-2"
        width={194}
        src="/PairTree_logo.svg"
        alt="PairTree"
      />
      <div className="flex items-center gap-4">
        <HelpModalButton />

        {account && isAuthenticated && (
          <>
            <div className="h-[40px] border-[1px] border-[#F3F4F5]"></div>
            <Dropdown
              trigger={
                <>
                  <span className="text-teal">
                    <UserIcon />
                  </span>
                </>
              }
            >
              <div className="md:whitespace-nowrap">
                Signed in as {account?.email}
                <div className="mt-4 flex">
                  <Button
                    otherStyles="w-full"
                    style="secondary"
                    color="teal"
                    onClick={signOut}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};

export default NavBar;
