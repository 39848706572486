export function WarningIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.57321 0C6.64753 0 6.03134 1.07161 4.79908 3.2148L1.53041 8.89947C0.304678 11.0312 -0.308122 12.0971 0.153878 12.8959C0.616011 13.6947 1.84548 13.6947 4.30454 13.6947H10.8419C13.3008 13.6947 14.5303 13.6947 14.9924 12.8959C15.4545 12.0971 14.8417 11.0312 13.616 8.89947L10.3472 3.2148C9.11492 1.07173 8.49881 0 7.57321 0ZM6.90514 3.69427H6.57321L6.7093 6.9608C6.73951 7.68621 6.75467 8.04892 6.98676 8.27159C7.21889 8.49424 7.58192 8.49424 8.30796 8.49424H8.37322L8.50374 5.36077C8.5364 4.57801 8.5527 4.18665 8.3165 3.94051C8.08035 3.69425 7.68863 3.69425 6.90517 3.69425L6.90514 3.69427ZM8.57328 10.6943C8.57328 11.2466 8.12557 11.6943 7.57328 11.6943C7.02093 11.6943 6.57328 11.2466 6.57328 10.6943C6.57328 10.142 7.02093 9.69427 7.57328 9.69427C8.12557 9.69427 8.57328 10.142 8.57328 10.6943Z"
        fill="currentColor"
      />
    </svg>
  );
}
