import { DreamcatcherOption, TextInput } from "@pairtreefamily/ui";
import MetadataInput from "./MetadataInput";

export interface OptionEditorProps {
  option: DreamcatcherOption;
  handleOptionChange: (data: any, name: string) => void;
}

const OptionEditor = ({ option, handleOptionChange }: OptionEditorProps) => {
  return (
    <div className="p-2">
      <TextInput
        name="label"
        value={option.label}
        onChange={(data) => handleOptionChange(data, "label")}
        placeholder="Option Label"
        label="Option Label"
      />
      <MetadataInput
        metadata={option.metadata ?? {}}
        documentationLink="https://docs.pairtreefamily.com/uhvhwNxzutFsz5/b/4D8FE105-8CF0-4CCE-9E4B-5DFB3AAFEB09/Metadata-on-Option"
        setMetadata={(metadata) => {
          handleOptionChange(metadata, "metadata");
        }}
      />
    </div>
  );
};

export default OptionEditor;
