import {
  DreamcatcherInput,
  Metadata,
  testMetadataHasOptions,
} from "@pairtreefamily/ui";
import OptionControl from "./OptionControl";

export type FlowBuilderInputControlProps<M extends Metadata> = {
  metadata: M;
  setMetadata: (metadata: M) => void;
};

export type InputWithType<T> = Extract<DreamcatcherInput, { type: T }>;

export type FlowBuilderInputControl<M extends Metadata> = React.FC<
  FlowBuilderInputControlProps<M>
>;

// CustomInputControl should get the type of the metadata and render a custom
// input control for that type. If there is no custom input control for that type,
// it should return null.
export const CustomInputControl: FlowBuilderInputControl<Metadata> = (
  props
) => {
  const res = testMetadataHasOptions(props.metadata);
  if (res.success) {
    return (
      <OptionControl
        setMetadata={(metadata) => {
          props.setMetadata(metadata);
        }}
        metadata={res.metadata}
      />
    );
  }
  return null;
};
