import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Checkbox, CheckboxProps } from "../../Checkbox";
import { ErrorMessage } from "../error-message";

export interface CheckboxFieldProps
  extends Omit<CheckboxProps, "onCheckboxChange" | "isChecked"> {
  name: string;
}

export const CheckboxField = ({ name, ...rest }: CheckboxFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <>
          <Checkbox
            isChecked={Boolean(field.value)}
            onCheckboxChange={field.onChange}
            name={name}
            {...rest}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </>
      );
    },
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
