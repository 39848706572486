import { typography } from "../typography";
import { tabClasses } from "@mui/material";
import { palette } from "../palette";

export const MuiTab = {
  styleOverrides: {
    root: {
      padding: 0,
      textTransform: "none" as const,
      minHeight: "48px",
      height: "44px",
      minWidth: 0,
      color: palette.text.primary,
      position: "relative" as const,
      ...typography.body4,

      "&:hover": {
        color: palette.secondary.main,
      },

      [`&.${tabClasses.selected}`]: {
        color: palette.text.primary,

        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
};
