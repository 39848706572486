import { Button, Modal, usePosthog } from "@pairtreefamily/ui";
import { useState } from "react";
import { InfoIcon } from "./icons/info";

const HelpModalButton = () => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const posthog = usePosthog();

  const onModalButtonClick = () => {
    setIsHelpModalOpen(true);
    posthog.capture("dreamcatcher-help-modal-opened");
  };
  const onModalClose = () => {
    setIsHelpModalOpen(false);
    posthog.capture("dreamcatcher-help-modal-closed");
  };

  return (
    <>
      <Modal
        title="Looking for help?"
        isOpen={isHelpModalOpen}
        onClose={onModalClose}
        maxWidth="400px"
      >
        <div>
          We are so excited to have you here! If you have any questions, please
          reach out to us at{" "}
          <a className="underline" href="mailto:hello@pairtreefamily.com">
            hello@pairtreefamily.com
          </a>
          .
        </div>
      </Modal>
      {/* -m-2 and p-2 are to make the clickable area greater */}
      <Button
        otherStyles="md:hidden -m-2 p-2"
        style="text"
        color="teal"
        onClick={onModalButtonClick}
      >
        <InfoIcon type="enabled" fillColor="teal" />
      </Button>
      <Button
        otherStyles="text-body-3 hidden md:block"
        style="text"
        color="teal"
        onClick={onModalButtonClick}
      >
        Looking for help?
      </Button>
    </>
  );
};

export default HelpModalButton;
