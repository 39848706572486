import { createContext, useState, useContext, useEffect } from "react";
import { usePosthog } from "@pairtreefamily/ui";

export interface BottomModalSheetContextData {
  state: "expanded" | "collapsed";
  isEnabled: boolean;
  setState: (state: "expanded" | "collapsed") => void;
  setIsEnabled: (isHidden: boolean) => void;
  popupMessage: string | null;
  setPopupMessage: (message: string) => void;
}

export const BottomModalSheetContext =
  createContext<BottomModalSheetContextData | null>(null);

export const useBottomModalSheet = () => {
  const data = useContext(BottomModalSheetContext);
  if (data === null) {
    throw new Error(
      "Tried to useBottomModalSheet without a suitable parent provider."
    );
  }
  return data;
};

export const BottomModalSheet = (
  props: BottomModalSheetContextData & { children: React.ReactNode }
) => {
  const { state, setState, isEnabled, popupMessage, children } = props;

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <div
        className={`fixed bottom-0 transform transition-transform duration-500 ease-in-out ${
          state == "expanded"
            ? "translate-x-0 translate-y-0 scale-100"
            : "translate-x-full translate-y-full scale-50"
        } z-20 mx-auto rounded-lg border-t-2 border-teal bg-white p-4 pb-12 shadow-xl`}
        style={{ width: "100%" }}
      >
        <div className="flex justify-end">
          <button
            onClick={() => setState("collapsed")}
            id="close-modal"
            className="bg-gray-200 hover:bg-gray-300 rounded-full p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>
        <div className="flex justify-center">
          <div>{children}</div>
        </div>
      </div>
      {state == "collapsed" && (
        <div
          className="fixed right-0 bottom-0 z-10 m-4"
          data-test="collapsed-view"
        >
          <div className="flex flex-col items-end">
            {popupMessage && (
              <div
                className="text-body-1 mb-1 w-64 rounded-md bg-teal p-2 text-white shadow-lg"
                data-test="popup-message"
              >
                {popupMessage}
              </div>
            )}
            <div className="flex justify-center rounded-full text-teal shadow-lg">
              <button
                onClick={() => setState("expanded")}
                className="bg-gray-200 hover:bg-gray-300 rounded-full"
              >
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                    fill="#44978D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.3333 23.3333V16.6667C23.3333 15.7462 24.0795 15 25 15C25.9205 15 26.6667 15.7462 26.6667 16.6667V23.3333H33.3333C34.2538 23.3333 35 24.0795 35 25C35 25.9205 34.2538 26.6667 33.3333 26.6667H26.6667V33.3333C26.6667 34.2538 25.9205 35 25 35C24.0795 35 23.3333 34.2538 23.3333 33.3333V26.6667H16.6667C15.7462 26.6667 15 25.9205 15 25C15 24.0795 15.7462 23.3333 16.6667 23.3333H23.3333Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const BottomModalSheetProvider = ({
  children,
  modalChildren,
}: {
  children: React.ReactNode;
  modalChildren: React.ReactNode;
}) => {
  const posthog = usePosthog();

  const [state, _setState] = useState<"expanded" | "collapsed">("collapsed");
  const setState = (newState: "expanded" | "collapsed") => {
    const oldState = state;
    _setState(newState);

    // Track modal sheet state changes.
    if (newState == oldState) {
      return;
    }
    if (newState == "expanded") {
      posthog.capture("dreamcatcher-modal-sheet-expanded");
    } else if (state == "collapsed") {
      posthog.capture("dreamcatcher-modal-sheet-collapsed");
    }
  };

  const [isEnabled, setIsEnabled] = useState(false);
  const [popupMessage, _setPopupMessage] = useState<string | null>(null);

  const setPopupMessage = (message: string) => {
    _setPopupMessage(message);
    setTimeout(() => {
      _setPopupMessage(null);
    }, 2500);
  };

  const value = {
    state,
    setState,
    isEnabled,
    setIsEnabled,
    popupMessage,
    setPopupMessage,
  };

  return (
    <BottomModalSheetContext.Provider value={value}>
      {children}
      <BottomModalSheet {...value}>{modalChildren}</BottomModalSheet>
    </BottomModalSheetContext.Provider>
  );
};
