import { DreamcatcherStepProps, Select, TextInput } from "@pairtreefamily/ui";
import MetadataInput from "./MetadataInput";

interface StepEditorProps {
  step: DreamcatcherStepProps;
  handleStepChange: (data: any, name: string) => void;
  flowSteps: DreamcatcherStepProps[];
}

const StepEditor = ({ step, handleStepChange, flowSteps }: StepEditorProps) => {
  return (
    <>
      <TextInput
        name="title"
        value={step.title}
        onChange={(data) => handleStepChange(data, "title")}
        label="Step Title"
        placeholder="Title"
      />
      <TextInput
        name="description"
        value={step.description ?? ""}
        onChange={(data) => handleStepChange(data, "description")}
        label="Step Description"
        placeholder="Description"
      />
      <TextInput
        name="confirmText"
        value={step.confirmText}
        onChange={(data) => handleStepChange(data, "confirmText")}
        label="Confirmation Text"
        placeholder="Step Confirm Text"
      />
      <Select
        options={[
          ...flowSteps.map((step) => ({
            id: step.id,
            label: step.id,
          })),
          { id: "", label: "None" },
        ]}
        value={step.nextStep ?? ""}
        onChange={(step) => handleStepChange(step, "nextStep")}
        label="Next Step"
        placeholder="Next Step"
      />
      <MetadataInput
        metadata={step.metadata ?? {}}
        setMetadata={(metadata) => {
          handleStepChange(metadata, "metadata");
        }}
      />
    </>
  );
};

export default StepEditor;
