export function Visible() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  );
}

export function Invisible() {
  return (
    <svg
      width="25"
      height="38"
      viewBox="0 0 25 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1693_4437)">
        <path
          d="M24.9046 18.1408C24.7617 17.8459 21.2342 10.9963 13.3485 10.5498H11.6517C3.76612 11.0232 0.238445 17.8462 0.0955566 18.1408C-0.0318522 18.3934 -0.0318522 18.6918 0.0955566 18.9445C0.238445 19.2393 3.76588 26.0889 11.6517 26.5354C11.9374 26.5354 12.2142 26.5802 12.4999 26.5802C12.7857 26.5802 13.0624 26.5802 13.3482 26.5354C21.2338 26.062 24.7614 19.2391 24.9043 18.9445C25.0317 18.6918 25.0317 18.3934 24.9043 18.1408H24.9046ZM18.7513 18.5427C18.7513 20.2007 18.0926 21.7908 16.9204 22.9632C15.748 24.1356 14.1579 24.7941 12.4999 24.7941C10.842 24.7941 9.25185 24.1354 8.07945 22.9632C6.90705 21.7908 6.24857 20.2007 6.24857 18.5427C6.24857 16.8848 6.90729 15.2947 8.07945 14.1223C9.25185 12.9499 10.842 12.2914 12.4999 12.2914C14.1579 12.2914 15.748 12.9501 16.9204 14.1223C18.0928 15.2947 18.7513 16.8848 18.7513 18.5427ZM1.91715 18.5427C2.85402 17.0329 4.06547 15.7121 5.48936 14.649C4.81588 15.8362 4.46199 17.1779 4.46247 18.5427C4.4627 19.9269 4.82278 21.2872 5.50746 22.49C4.0669 21.4189 2.84759 20.0783 1.91738 18.5427H1.91715ZM19.5191 22.4365C20.1895 21.2483 20.5403 19.9069 20.5372 18.5427C20.5369 17.1586 20.1769 15.7983 19.4922 14.5955C20.9263 15.6685 22.1394 17.0088 23.0644 18.5427C22.1356 20.0502 20.933 21.3707 19.5189 22.4365H19.5191Z"
          fill="currentColor"
        />
        <path
          d="M12.4997 22.1141C13.7759 22.1141 14.9552 21.4333 15.5932 20.328C16.2315 19.2228 16.2315 17.8611 15.5932 16.7558C14.9552 15.6506 13.7759 14.9697 12.4997 14.9697C12.359 14.9752 12.2187 14.9902 12.0801 15.0145C12.5016 15.5903 12.6212 16.3336 12.4016 17.0126C12.1818 17.6918 11.6495 18.224 10.9706 18.4436C10.2916 18.6634 9.54835 18.5438 8.97251 18.1221C8.94822 18.2609 8.93345 18.4012 8.92773 18.5417C8.92773 19.489 9.30401 20.3978 9.97392 21.0677C10.6438 21.7376 11.5526 22.1139 12.4999 22.1139L12.4997 22.1141Z"
          fill="#CCCCCC"
        />
      </g>
      <rect
        x="0.771182"
        y="2.70233"
        width="4.13736"
        height="39.4038"
        rx="2.06868"
        transform="rotate(-30 0.771182 2.70233)"
        fill="currentColor"
        stroke="white"
        stroke-width="2"
      />
      <defs>
        <clipPath id="clip0_1693_4437">
          <rect
            width="25"
            height="16.0302"
            fill="white"
            transform="translate(0 10.5498)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
