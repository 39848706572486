import { z } from "zod";
import { Metadata } from "../..";
import { MetadataTestResult, testMetadata } from "./testMetadata";

export const DreamcatcherMetadataWithTooltipSchema = z.object({
  tooltipLabel: z.string(),
  tooltipContent: z.string(),
});

export type DreamcatcherMetadataWithTooltip = z.infer<
  typeof DreamcatcherMetadataWithTooltipSchema
>;

export function testMetadataHasTooltip<M extends Metadata>(
  metadata: M
): MetadataTestResult<M, DreamcatcherMetadataWithTooltip> {
  return testMetadata(metadata, DreamcatcherMetadataWithTooltipSchema);
}
