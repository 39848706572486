import { DreamcatcherFlowProps, DreamcatcherValue } from "@pairtreefamily/ui";

export interface QuestionAnswerFlowService {
  storeFlow(flow: DreamcatcherFlowProps): Promise<{ flowId: string }>;
  getFlow(id: string): Promise<DreamcatcherFlowProps | null>;
  submitFlowAnswers(flowId: string, values: DreamcatcherValue[]): Promise<void>;
  isAuthorized(): boolean;
}

export class QuestionAnswerFlowFakeService
  implements QuestionAnswerFlowService
{
  private readonly flows: Record<string, DreamcatcherFlowProps> = {};
  private readonly answers: Record<string, DreamcatcherValue[]> = {};
  private _isAuthorized: boolean = false;

  async storeFlow(flow: DreamcatcherFlowProps) {
    const flowId = Math.random().toString();
    this.flows[flowId] = flow;
    return { flowId };
  }

  async getFlow(id: string): Promise<DreamcatcherFlowProps | null> {
    return this.flows[id] || null;
  }

  async submitFlowAnswers(flowId: string, values: DreamcatcherValue[]) {
    this.answers[flowId] = values;
  }

  setIsAuthorized(isAuthorized: boolean) {
    this._isAuthorized = isAuthorized;
  }

  isAuthorized(): boolean {
    return this._isAuthorized;
  }

  getAnswers(flowId: string): DreamcatcherValue[] {
    return this.answers[flowId] || [];
  }

  clearAnswers(flowId: string) {
    delete this.answers[flowId];
  }
}

export class QuestionAnswerFlowHttpService {
  private readonly baseUrl: string;
  private readonly authorizationHeader: string | null;

  constructor(baseUrl: string, authorizationHeader: string | null) {
    this.baseUrl = baseUrl;
    this.authorizationHeader = authorizationHeader;
  }

  isAuthorized(): boolean {
    return !!this.authorizationHeader;
  }

  async storeFlow(flow: DreamcatcherFlowProps) {
    const response = await fetch(`${this.baseUrl}/api/flow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(this.authorizationHeader && {
          Authorization: this.authorizationHeader,
        }),
      },
      body: JSON.stringify({ flow }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.error);
    }
    return { flowId: responseBody.flowId as string };
  }

  async getFlow(id: string): Promise<DreamcatcherFlowProps | null> {
    const response = await fetch(`${this.baseUrl}/api/flow?flowId=${id}`);
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.error);
    }
    return responseBody.flow;
  }

  async submitFlowAnswers(
    flowId: string,
    values: DreamcatcherValue[]
  ): Promise<void> {
    const response = await fetch(`${this.baseUrl}/api/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(this.authorizationHeader && {
          Authorization: this.authorizationHeader,
        }),
      },
      body: JSON.stringify({ flowId, values }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.error);
    }
  }
}
