import { ChangeEvent } from "react";
import { getClassName } from "@pairtreefamily/utils";

export type RadioInputProps = {
  value: string | boolean | null;
  label?: string;
  groupName: string;
  onChange: (checkedValue: string | boolean | null) => void;
  checked: boolean;
  disabled?: boolean;
};

export function RadioInput({
  value,
  label,
  groupName,
  onChange,
  checked = false,
  disabled = false,
}: RadioInputProps) {
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: eventValue } = event.target;
    const convertedValue =
      eventValue === "true"
        ? true
        : eventValue === "false"
        ? false
        : eventValue === "null"
        ? null
        : eventValue;

    onChange(convertedValue);
  };

  const stringValue = value === null ? "null" : value.toString();

  return (
    <label
      className={getClassName(
        "text-body-3-medium content-center",
        disabled && "text-lightgray"
      )}
    >
      <input
        type="radio"
        name={groupName}
        value={stringValue}
        checked={checked}
        disabled={disabled}
        onChange={handleValueChange}
        className={getClassName(
          "mr-1 text-teal",
          disabled && "border-lightgray"
        )}
      />
      {label ?? value}
    </label>
  );
}
