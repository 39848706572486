import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { TextAreaProps, TextArea } from "../../TextArea";
import { ErrorMessage } from "../error-message";

export interface TextAreaFieldProps
  extends Omit<TextAreaProps, "onChange" | "value"> {
  name: string;
}

export const TextAreaField = ({ name, ...rest }: TextAreaFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <>
          <TextArea
            onChange={field.onChange}
            value={field.value}
            name={name}
            {...rest}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </>
      );
    },
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
