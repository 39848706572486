import type { TruthyFilter } from "./types/truthyFilter";
export function truthyFilter<T>(...args: Array<TruthyFilter<T>>): T[] {
  // this predicate asserts that the item is actually T and not a falsy value
  return args.filter(truthyPredicate);
}

export function truthyPredicate<T>(item: TruthyFilter<T>): item is T {
  return !!item;
}

export function notNullPredicate<T>(item: T | null): item is T {
  return item !== null;
}
