import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import Select, { SelectProps } from "../../Select";
import { ErrorMessage } from "../error-message";

export interface SelectFieldProps
  extends Omit<SelectProps, "onChange" | "value"> {
  name: string;
  onChange?: (value: any) => void;
}

export const SelectField = ({ name, onChange, ...rest }: SelectFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      // Callback calls both the form's field.onChange and the custom onChange
      // for backward compatibility
      const handleChange = (value: any) => {
        field.onChange(value);
        onChange?.(value);
      };

      return (
        <>
          <Select
            onChange={handleChange}
            value={field.value}
            name={name}
            {...rest}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </>
      );
    },
    [name, rest, onChange]
  );

  return <Controller name={name} render={render} />;
};
