import { useState } from "react";
import type { FirebaseApp } from "firebase/app";
import { useFirebaseAuth } from "../..";
import { Button, EmailIcon, GoogleIcon, TextInput } from "..";
import { Modal } from "../Modal";

export interface LoginProps {
  app: FirebaseApp;
  // Disables acting on any of the login option buttons.
  disabled?: boolean;
  mode: "login" | "signup";
  onSuccess?: (token: string, needsAccount: boolean) => void;
  hideEmailOption?: boolean;
}

export function LoginAndSignUp(props: LoginProps) {
  const { app, disabled, mode, onSuccess, hideEmailOption } = props;
  const { signInWithEmail, signUpWithEmail, signInWithGoogle } =
    useFirebaseAuth({ app });
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const actionText = mode === "login" ? "Log In" : "Sign Up";

  const loginOrSignUpWithProvider = async (provider: "google" | "email") => {
    let response;
    setLoading(true);
    if (provider === "google") {
      try {
        response = await signInWithGoogle();
      } catch (e) {
        console.error(e);
        setError(`Error with ${actionText} with Google.`);
      }
    } else {
      if (mode === "login") {
        try {
          response = await signInWithEmail(email, password);
        } catch (e) {
          console.error(e);
          setError("Error logging in with this email.");
        }
      } else {
        try {
          response = await signUpWithEmail(email, password);
        } catch (e) {
          console.error(e);
          setError("Error signing up with this email.");
        }
      }
    }
    setLoading(false);
    if (response) {
      onSuccess && onSuccess(response.token, response.needsAccount);
      setError(null);
      setIsEmailModalOpen(false);
    }
  };

  return (
    <div>
      <Modal
        title={`${actionText} with Email`}
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
      >
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <TextInput
              label="Email"
              type="email"
              value={email}
              onChange={setEmail}
            />
            <TextInput
              label="Password"
              type="password"
              value={password}
              onChange={setPassword}
            />
          </div>
          {error && <div className="text-rust">{error}</div>}
          <Button
            onClick={() => loginOrSignUpWithProvider("email")}
            loading={loading}
          >
            {actionText}
          </Button>
        </div>
      </Modal>
      <div className="mt-12 flex flex-col items-center justify-center p-8 md:flex-row">
        {!hideEmailOption && (
          <Button
            disabled={disabled}
            loading={loading}
            style="primary"
            color="teal"
            otherStyles="mt-2.5 md:mr-2.5 w-full md:w-auto"
            onClick={() => setIsEmailModalOpen(true)}
          >
            <EmailIcon className="mr-2.5" />
            {actionText} with Email
          </Button>
        )}

        <Button
          disabled={disabled}
          loading={loading}
          style="primary"
          color="teal"
          otherStyles="mt-2.5 md:ml-2.5 w-full md:w-auto"
          onClick={() => loginOrSignUpWithProvider("google")}
        >
          <GoogleIcon className="mr-2.5" />
          {actionText} with Google
        </Button>
      </div>
    </div>
  );
}
