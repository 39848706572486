import { getClassName } from "@pairtreefamily/utils";
import { Colors } from "../../types/colors";

type FillColor = Extract<
  Colors.Solid,
  "teal" | "green" | "darkblue" | "lightgray"
>;

export type InfoProps = {
  type: "disabled" | "enabled";
  fillColor?: FillColor;
};

const fillMap: Record<FillColor, string> = {
  teal: "fill-teal",
  green: "fill-green",
  darkblue: "fill-darkblue",
  lightgray: "fill-lightgray",
};

export function InfoIcon(props: InfoProps) {
  if (props.type === "enabled") {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8.5"
          cy="8.5"
          r="8.5"
          className={getClassName(fillMap[props.fillColor ?? "lightgray"])}
        />
        <path
          d="M6.80005 12.8758V11.424H7.76228V8.48984H6.80005V7.02954H9.23275V11.4087H10.2001V12.8741H6.80006L6.80005 12.8758Z"
          fill="#FFFFFF"
        />
        <path
          d="M8.26875 4.12427C8.52652 4.12604 8.77316 4.22958 8.95496 4.41236C9.13675 4.59508 9.23901 4.84229 9.23945 5.10005C9.23503 5.36297 9.12728 5.61354 8.93963 5.79771C8.75198 5.98187 8.49942 6.08486 8.23644 6.08435C7.9806 6.07721 7.73773 5.97025 7.55981 5.7863C7.38185 5.6023 7.28301 5.35599 7.28444 5.10005C7.29014 4.84157 7.39595 4.59542 7.57956 4.41341C7.76317 4.23134 8.01027 4.1277 8.26875 4.12427Z"
          fill="#FFFFFF"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6152_11461)">
          <path
            d="M6.80005 12.8756V11.4238H7.76228V8.4896H6.80005V7.0293H9.23275V11.4085H10.2001V12.8739H6.80006L6.80005 12.8756Z"
            className={getClassName(fillMap[props.fillColor ?? "lightgray"])}
          />
          <path
            d="M8.26875 4.12402C8.52652 4.12579 8.77316 4.22933 8.95496 4.41212C9.13675 4.59484 9.23901 4.84205 9.23945 5.09981C9.23503 5.36273 9.12728 5.61329 8.93963 5.79746C8.75198 5.98163 8.49942 6.08462 8.23644 6.08411C7.9806 6.07697 7.73773 5.97 7.55981 5.78606C7.38185 5.60206 7.28301 5.35574 7.28444 5.09981C7.29014 4.84132 7.39595 4.59518 7.57956 4.41317C7.76317 4.2311 8.01027 4.12745 8.26875 4.12402Z"
            className={getClassName(fillMap[props.fillColor ?? "lightgray"])}
          />
          <path
            d="M8.52704 -0.000200101C13.1935 0.015073 17.027 3.852 16.9998 8.4862C16.9981 9.98248 16.6024 11.4518 15.8528 12.7468C15.1031 14.0417 14.0258 15.1165 12.729 15.8629C11.4324 16.6095 9.96199 17.0016 8.46585 16.9998C3.79595 17.0083 -0.0222487 13.1425 -0.000148684 8.425C0.0196067 6.17887 0.927598 4.03206 2.52534 2.45333C4.1232 0.874592 6.28079 -0.0074251 8.52705 -0.000200101H8.52704ZM8.56616 1.608H8.56611C7.35187 1.59627 6.15621 1.90655 5.1008 2.5072C4.04549 3.10784 3.16818 3.97742 2.55816 5.02731C1.94816 6.0773 1.62732 7.27018 1.62831 8.48454C1.58581 12.2398 4.70191 15.3746 8.46741 15.3831C9.68136 15.3859 10.8745 15.0674 11.9255 14.4601C12.9766 13.8528 13.8483 12.9783 14.4523 11.9253C15.0562 10.8722 15.3709 9.67814 15.3642 8.4642C15.3422 4.6987 12.2821 1.6132 8.56594 1.6081L8.56616 1.608Z"
            className={getClassName(fillMap[props.fillColor ?? "lightgray"])}
          />
        </g>
        <defs>
          <clipPath id="clip0_6152_11461">
            <rect width="17" height="17" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
