export const AppIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.16462 0.801742H1.28884C0.947242 0.802265 0.619663 0.938198 0.378168 1.1797C0.136573 1.42131 0.000536165 1.74879 2.67943e-05 2.0904V5.96655V5.96645C0.000550401 6.30805 0.136587 6.63554 0.378168 6.87714C0.619658 7.11864 0.947242 7.25457 1.28884 7.25511H5.16462C5.50622 7.25458 5.83369 7.11865 6.07529 6.87714C6.31689 6.63554 6.45282 6.30805 6.45343 5.96645V2.09057C6.45291 1.74885 6.31698 1.42137 6.07537 1.17976C5.83376 0.938152 5.5063 0.802227 5.16459 0.801691M14.6228 3.11665L11.8819 0.376703C11.6398 0.13552 11.3122 0 10.9705 0C10.6289 0 10.3011 0.135515 10.0592 0.376703L9.68027 0.755819L7.31877 3.11695C7.07739 3.35761 6.94197 3.68456 6.94251 4.02545C6.94052 4.36821 7.07603 4.69746 7.31877 4.93939L7.69828 5.3187L9.68006 7.30208L10.0596 7.68138V7.68149C10.3014 7.92298 10.6291 8.05859 10.971 8.05849C11.3127 8.05838 11.6405 7.92245 11.882 7.68074L14.2433 5.31867L14.6228 4.94009C14.8643 4.69828 15 4.37049 15 4.02877C15 3.68695 14.8643 3.35915 14.6228 3.11735M5.16462 8.54631H1.28884C0.947135 8.54673 0.619663 8.68267 0.37806 8.92428C0.136466 9.16588 0.00053618 9.49345 0 9.83508V13.7112C0.000523606 14.0528 0.136452 14.3804 0.37806 14.6219C0.619655 14.8635 0.947135 14.9995 1.28884 15H5.16462C5.50633 14.9995 5.8338 14.8635 6.0754 14.6219C6.31699 14.3804 6.45292 14.0528 6.45346 13.7112V9.83508C6.45294 9.49347 6.31701 9.16589 6.0754 8.92428C5.83379 8.68267 5.50633 8.54674 5.16462 8.54631ZM12.9087 8.54631H9.03289C8.69118 8.54673 8.36371 8.68267 8.1221 8.92428C7.8805 9.16589 7.74458 9.49345 7.74404 9.83508V13.7112C7.74457 14.0528 7.8805 14.3804 8.1221 14.6219C8.3637 14.8635 8.69118 14.9995 9.03289 15H12.9089H12.9088C13.2505 14.9995 13.578 14.8635 13.8196 14.6219C14.0612 14.3804 14.1971 14.0528 14.1977 13.7112V9.83508C14.1971 9.49347 14.0612 9.16589 13.8196 8.92428C13.578 8.68268 13.2505 8.54674 12.9088 8.54631"
      fill="currentColor"
    />
  </svg>
);
