export const EmailIcon = ({ className }: any) => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.78564 0C1.40493 0 0.285645 1.11929 0.285645 2.5V12.5C0.285645 13.8807 1.40493 15 2.78564 15H16.119C17.4997 15 18.619 13.8807 18.619 12.5V2.5C18.619 1.11929 17.4997 0 16.119 0H2.78564ZM4.98574 3.52691C4.63217 3.23227 4.1067 3.28004 3.81207 3.63361C3.51743 3.98717 3.5652 4.51264 3.91876 4.80728L7.85179 8.0848C8.77891 8.8574 10.1256 8.8574 11.0527 8.0848L14.9857 4.80728C15.3393 4.51264 15.3871 3.98717 15.0924 3.63361C14.7978 3.28004 14.2723 3.23227 13.9188 3.52691L9.98574 6.80443C9.6767 7.06196 9.2278 7.06196 8.91877 6.80443L4.98574 3.52691Z"
      fill="currentColor"
    />
  </svg>
);
