import { z } from "zod";
//import { MetadataSchema } from "../../../"; //schemas/input"; //circular dependency
import { MetadataSchema } from "../../../schemas/input"; //avoids circular dependency - TODO better fix

export const DreamcatcherOptionSchema = z.object({
  metadata: MetadataSchema.optional(),
  id: z.string(),
  label: z.string(),
});

export type DreamcatcherOption = z.infer<typeof DreamcatcherOptionSchema>;
