import {
  ConfidentialAccount,
  ConfidentialAdoptiveParentInformation,
  CreateAdoptiveParentPayload,
  CreateCheckoutSessionResponse,
  GetCheckoutSessionResultResponse,
  MinimalArchetype,
  MinimalPublicAdoptiveParentProfile,
  MinimalPublicProfessional,
  PricingResponse,
  ProfessionalSearchPayload,
  PublicAdoptiveParentProfile,
  RegisterPayload,
  UpdateAccountPayload,
  ProfileNotFoundError,
  ProfileSearchArgs,
  PaginatedResponse,
  ValidateTokenResponse,
  CoreValidateTokenPayload,
} from "../types/coreApiMirror";
import { truthyFilter } from "@pairtreefamily/utils";
import * as Sentry from "@sentry/nextjs";
import { Result, Err, Ok } from "@pairtreefamily/utils";
import jwt from "jsonwebtoken";

export function getCoreSignatureToken() {
  const payload = {
    iss: "com.pairtreefamily.platform",
  };

  if (typeof process === "undefined") {
    console.error("Cannot get signature token in browser");
    return "";
  }

  const secret = process.env.CORE_PLATFORM_STATIC_SECRET_KEY || "";

  return jwt.sign(payload, secret, { expiresIn: "1h" });
}

type BaseRequestArgs = {
  endpoint: string;
  params?: URLSearchParams;
};

type GetRequestArgs = BaseRequestArgs & {
  type: "GET";
};

type PostRequestArgs = BaseRequestArgs & {
  type: "POST";
  payload: Object;
};

type PutRequestArgs = BaseRequestArgs & {
  type: "PUT";
  payload: Object;
};

export type HTTPRequestArgs = PostRequestArgs | GetRequestArgs | PutRequestArgs;
export type CoreAPIError = UnknownError | ResponseError;

export type ResponseError = {
  type: "response";
  response: any;
};

export type UnknownError = {
  type: "unknown";
  error: unknown;
};

export type CoreAPIResult<T, E = CoreAPIError> = Result<T, CoreAPIError | E>;

type SendCoreEventArgs = {
  eventData?: any;
  eventType: string;
};

// for reference from swagger
type EventPayload = {
  specversion: "1.0";
  type: string;
  source: "com.pairtreefamily.platform";
  data: unknown;
};

export type PlatformUpdateAccountData = {
  email_address: string;
  account: UpdateAccountPayload;
  adoptive_parent1: any; // TODO UpdateAdoptiveParent
  adoptive_parent2: any; // TODO UpdateAdoptiveParent
  biological_parent: any; // TODO UpdateBiologicalParent
  professional: any; // TODO UpdateProfessional
};

export class CoreAPI {
  apiVersion = "v4";
  host = process.env.NEXT_PUBLIC_CORE_API_URL;
  protected authorizationHeader: string | null = null;

  constructor(props?: { host?: string }) {
    this.host = props?.host ?? this.host;
  }

  versionedEndpoint(endpoint: string) {
    return `${this.apiVersion}${endpoint}`;
  }

  createURL(endpoint: string) {
    return new URL(this.versionedEndpoint(endpoint), this.host);
  }

  isAuthenticated(): boolean {
    return !!this.authorizationHeader;
  }

  setUserAuthorizationHeader(token: string | null) {
    if (token) {
      this.authorizationHeader = `Bearer ${token}`;
    }
  }

  getUserAuthorizationHeader() {
    return this.authorizationHeader;
  }

  async sendCoreEvent<T, E>(
    args: SendCoreEventArgs
  ): Promise<CoreAPIResult<T, E>> {
    const token = getCoreSignatureToken();

    const authHeader = {
      "X-Signature": token,
    };

    const payload: EventPayload = {
      specversion: "1.0",
      type: args.eventType,
      source: "com.pairtreefamily.platform",
      data: args.eventData,
    };

    return this.httpRequest(
      {
        type: "POST",
        endpoint: `/event/`,
        payload: payload,
      },
      authHeader
    );
  }

  async validateToken(
    token: string
  ): Promise<CoreAPIResult<ValidateTokenResponse>> {
    const endpoint = `/authentication/validate-token`;

    const authToken = getCoreSignatureToken();
    const authHeader = {
      "X-Signature": authToken,
    };

    const payload: CoreValidateTokenPayload = {
      token: token,
    };
    return this.httpRequest(
      {
        type: "POST",
        endpoint,
        payload,
      },
      authHeader
    );
  }

  async sendFlowCompletedEvent(
    payload: PlatformUpdateAccountData
  ): Promise<CoreAPIResult<unknown>> {
    return this.sendCoreEvent({
      eventType: "flow_completed",
      eventData: payload,
    });
  }

  // internal request function
  protected async httpRequest<T>(
    args: HTTPRequestArgs,
    headers?: { [key: string]: string }
  ): Promise<CoreAPIResult<T>> {
    const { type, endpoint, params } = args;
    const resourceURL = this.createURL(endpoint);

    if (params) {
      resourceURL.search = params.toString();
    }

    const requestOptions: RequestInit = {
      method: type,
    };

    switch (type) {
      case "GET":
        break;
      case "PUT":
        requestOptions.body = JSON.stringify(args.payload);
        requestOptions.headers = {
          "Content-Type": "application/json",
        };
        break;
      case "POST":
        requestOptions.body = JSON.stringify(args.payload);
        requestOptions.headers = {
          "Content-Type": "application/json",
        };
        break;
    }
    if (this.authorizationHeader) {
      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: this.authorizationHeader,
      };
    }
    if (headers) {
      requestOptions.headers = {
        ...requestOptions.headers,
        ...headers,
      };
    }

    try {
      return await fetch(resourceURL.toString(), requestOptions).then(
        async (res: Response) => {
          if (!res.ok) {
            const responseJson = await res.json();
            Sentry.captureException(
              new Error(`Server error trying to reach ${endpoint}`),
              {
                extra: responseJson,
              }
            );
            return Err({ type: "response", response: responseJson });
          }
          return Ok(await res.json());
        }
      );
    } catch (e) {
      let err: Error;
      if (e instanceof Error) {
        err = e;
      } else {
        err = new Error(JSON.stringify(e));
      }
      Sentry.captureException(err);
      // we create a real Error to capture in sentry, but return the actual
      // thrown value
      return Promise.resolve(Err({ type: "unknown", error: e }));
    }
  }

  async profileSearch(
    args: ProfileSearchArgs
  ): Promise<
    CoreAPIResult<PaginatedResponse<MinimalPublicAdoptiveParentProfile>>
  > {
    const { filters, cursor, limit = 9 }: ProfileSearchArgs = args;
    const endpoint = "/account/fam/search";
    const queryParams = new URLSearchParams(
      truthyFilter<[string, string]>(
        limit != null && ["limit", limit.toString()],
        cursor != null && ["cursor", cursor]
      )
    );

    return this.httpRequest({
      type: "POST",
      endpoint: endpoint,
      params: queryParams,
      payload: filters ?? {},
    });
  }

  async profileCount(
    args: Pick<ProfileSearchArgs, "filters">
  ): Promise<CoreAPIResult<{ count: number }>> {
    const { filters }: ProfileSearchArgs = args;
    const endpoint = "/account/fam/search/count";
    return this.httpRequest({
      type: "POST",
      endpoint: endpoint,
      payload: filters ?? {},
    });
  }

  async getProfileDetails(
    slug: string
  ): Promise<CoreAPIResult<PublicAdoptiveParentProfile, ProfileNotFoundError>> {
    const result = await this.httpRequest<PublicAdoptiveParentProfile>({
      type: "GET",
      endpoint: `/account/fam/profile-details/slug/${slug}`,
    });

    if (
      !result.ok &&
      result.error.type === "response" &&
      result.error.response["error"] === "not_found"
    ) {
      return Err({
        type: "profileNotFound",
      });
    } else {
      return result;
    }
  }

  async addAnonymousProfileView(uuid: string): Promise<CoreAPIResult<void>> {
    return this.httpRequest({
      type: "POST",
      endpoint: `/account/fam/add-anonymous-view`,
      payload: {
        viewed_account_uuid: uuid,
      },
    });
  }

  async addProfileView(uuid: string): Promise<CoreAPIResult<void>> {
    return this.httpRequest({
      type: "POST",
      endpoint: `/account/fam/add-view`,
      payload: {
        viewed_account_uuid: uuid,
      },
    });
  }

  async professionalSearch(
    filters?: ProfessionalSearchPayload
  ): Promise<CoreAPIResult<MinimalPublicProfessional[]>> {
    const endpoint = "/professional/search";

    return this.httpRequest({
      type: "POST",
      endpoint: endpoint,
      payload: filters ?? {},
    });
  }

  async getArchetypes(): Promise<CoreAPIResult<MinimalArchetype[]>> {
    const endpoint = "/archetypes";
    return this.httpRequest({
      type: "GET",
      endpoint: endpoint,
    });
  }

  async getPrices(stateCode?: string): Promise<CoreAPIResult<PricingResponse>> {
    const endpoint = `/billing/prices${
      stateCode ? "?us_state=" + stateCode : ""
    }`;
    return this.httpRequest({
      type: "GET",
      endpoint,
    });
  }

  async createCheckoutSession(
    purchasingPriceId: string,
    nextPath: string,
    backPath: string
  ): Promise<CoreAPIResult<CreateCheckoutSessionResponse>> {
    return this.httpRequest<CreateCheckoutSessionResponse>({
      type: "POST",
      endpoint: "/billing/checkout-session",
      payload: {
        purchasing_price_id: purchasingPriceId,
        next_path: nextPath,
        back_path: backPath,
        redirect_destination: "dreamcatcher",
      },
    });
  }

  async getCheckoutSessionResult(
    checkoutSessionId: string
  ): Promise<CoreAPIResult<GetCheckoutSessionResultResponse>> {
    return this.httpRequest<GetCheckoutSessionResultResponse>({
      type: "GET",
      endpoint: "/billing/checkout-session/result",
      params: new URLSearchParams({
        checkout_session_id: checkoutSessionId,
      }),
    });
  }

  async getAccount(): Promise<CoreAPIResult<ConfidentialAccount>> {
    return this.httpRequest<ConfidentialAccount>({
      type: "GET",
      endpoint: "/account/",
    });
  }

  async updateAccount(
    payload: UpdateAccountPayload
  ): Promise<CoreAPIResult<ConfidentialAccount>> {
    return this.httpRequest<ConfidentialAccount>({
      type: "PUT",
      endpoint: "/account/",
      payload,
    });
  }

  async registerToken(
    payload: RegisterPayload
  ): Promise<CoreAPIResult<ConfidentialAccount>> {
    return this.httpRequest<ConfidentialAccount>({
      type: "POST",
      endpoint: "/authentication/register-token",
      payload,
    });
  }

  async createAdoptiveParent(
    payload: CreateAdoptiveParentPayload
  ): Promise<CoreAPIResult<ConfidentialAdoptiveParentInformation>> {
    return this.httpRequest<ConfidentialAdoptiveParentInformation>({
      type: "POST",
      endpoint: "/account/fam/parent",
      payload,
    });
  }

  async mintCustomToken(): Promise<CoreAPIResult<{ token: string }>> {
    return this.httpRequest<{ token: string }>({
      type: "POST",
      endpoint: "/authentication/mint-custom-token",
      payload: {},
    });
  }
}

export const getCoreAPI = (): CoreAPI => {
  return new CoreAPI();
};
