import { Result } from "@pairtreefamily/utils";
import { useQuery } from "@tanstack/react-query";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useCoreAPI } from "../hooks/useCoreAPI";
import { ConfidentialAccount, RegisterPayload } from "../types/coreApiMirror";

export type AccountContextData = {
  account: ConfidentialAccount | null;
  isLoading: boolean;
  error: string | null;
  createAccount: (
    registerPayload: RegisterPayload
  ) => Promise<Result<ConfidentialAccount, string>>;
};

export const AccountContext = createContext<AccountContextData | null>(null);

export type AccountProviderProps = {
  children: ReactNode;
};

export const AccountProvider = (props: AccountProviderProps) => {
  const CoreAPI = useCoreAPI();

  const result = useQuery<ConfidentialAccount, string>(
    ["account"],
    async () => {
      const result = await CoreAPI.getAccount();
      if (result.ok) {
        return result.content;
      } else {
        throw new Error(JSON.stringify(result.error));
      }
    },
    {
      enabled: CoreAPI.isAuthenticated(),
    }
  );

  const createAccount = async (registerPayload: RegisterPayload) => {
    const result = await CoreAPI.registerToken(registerPayload);
    if (!result.ok) {
      return {
        ok: false,
        error: JSON.stringify(result.error),
      } as Result<ConfidentialAccount, string>;
    }
    return result;
  };

  return (
    <AccountContext.Provider
      value={{
        account: result.data ?? null,
        isLoading: result.isLoading,
        createAccount,
        error: result.error,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
