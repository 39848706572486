import { useContext } from "react";
import { PosthogContext } from "../components/PosthogProvider";

/*
```
import { usePosthog } from "@pairtreefamily/ui";

function ExampleComponent() {
  // Get the posthog context
  const posthog = usePosthog();

  // Capture an event
  const handleClick = () => {
    posthog.capture("Button Clicked", { buttonName: "Example Button" });
  };

  // Check feature flag
  if (posthog.enabledFeatureFlags["exampleFeatureFlag"]) {
    // Code to execute if feature flag is enabled
  }

  // Get feature flag payload
  const featureFlagPayload = posthog.getFeatureFlagPayload("exampleFeatureFlag");

  return (
    <button onClick={handleClick}>
      // Use feature flag payload
      Click me: {featureFlagPayload?.exampleFeatureFlagProperty}
    </button>
  );
}

export default ExampleComponent;
```

This example demonstrates how to use the usePosthog hook within a functional component to capture an event when a button is clicked.
It also shows how to check whether a feature flag is enabled and how to get a feature flag payload.

Remember, the component using this hook must be a descendant of PosthogProvider in the component tree, 
otherwise it will throw an error ("usePosthog must be used within a PosthogProvider").
So, make sure your app (or the portion of it that uses this hook) is wrapped by PosthogProvider.

*/
export const usePosthog = () => {
  const posthog = useContext(PosthogContext);
  if (!posthog) {
    throw new Error("usePosthog must be used within a PosthogProvider");
  }
  return posthog;
};
