export function PrivateDocumentIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5446 3.21151H14.6125C14.3214 3.21151 14.123 3.14642 14.0116 3.0038L12.3542 0.960739C11.8758 0.350076 11.1512 0 10.3643 0H2.52299C1.13162 0.000252278 0 1.13539 0 2.53053V18.3164C0 19.9238 1.30896 21.2315 2.91759 21.2315H5.2774V21.4418C5.2774 23.4069 6.8705 25 8.83542 25H15.9614C17.9265 25 19.5194 23.4069 19.5194 21.4418L19.5193 21.2315H22.1504C23.6857 21.2315 24.7995 20.0054 24.7995 18.3167V5.92387C24.7995 4.3522 23.8512 3.21143 22.545 3.21143L22.5446 3.21151ZM17.735 21.4416C17.735 22.4199 16.9391 23.2155 15.9611 23.2155L8.83517 23.2156C7.8571 23.2156 7.06128 22.4197 7.06128 21.4417L7.06119 17.0809C7.06119 16.1026 7.85712 15.3071 8.83508 15.3071H15.961C16.9391 15.3071 17.7349 16.103 17.7349 17.0809L17.735 21.4416ZM10.0394 13.5226V12.363C10.0394 11.0626 11.0976 10.0045 12.398 10.0045C13.6984 10.0045 14.7565 11.0626 14.7565 12.363V13.5226H10.0394ZM23.0152 18.3167C23.0152 18.582 22.953 19.4474 22.1503 19.4474H19.519V17.0809C19.519 15.3137 18.2295 13.8516 16.5408 13.5741V12.3631C16.5408 10.0787 14.6824 8.22023 12.3978 8.22023C10.1136 8.22023 8.25535 10.0786 8.25535 12.3631V13.5742C6.56665 13.8516 5.27706 15.3138 5.27706 17.081V19.4475H2.91724C2.28176 19.4475 1.78394 18.951 1.78394 18.3168V2.53069C1.78394 2.11915 2.11535 1.78438 2.52301 1.78438H10.3643C10.5997 1.78438 10.8135 1.88613 10.9515 2.06389L12.6152 4.11453C12.9285 4.51675 13.5291 4.99596 14.6123 4.99596H22.5444C22.8655 4.99596 23.0151 5.5498 23.0151 5.92441L23.0152 18.3167Z"
        fill="currentColor"
      />
      <path
        d="M12.2935 18.0059C11.9402 18.0059 11.5958 18.1485 11.346 18.3965C11.0962 18.6463 10.9536 18.9907 10.9536 19.344C10.9536 19.6953 11.0965 20.0417 11.346 20.2895C11.5958 20.5394 11.9402 20.6821 12.2935 20.6821C12.6449 20.6821 12.9892 20.5395 13.2391 20.2895C13.487 20.0397 13.6297 19.6953 13.6297 19.344C13.6297 18.9907 13.4868 18.6463 13.2391 18.3965C12.9892 18.1484 12.6449 18.0059 12.2935 18.0059Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PrivateDocumentIcon;
