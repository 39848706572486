import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Toggle, ToggleProps } from "../../Toggle";
import { ErrorMessage } from "../error-message";

export interface ToggleFieldProps
  extends Omit<ToggleProps, "onChange" | "checked"> {
  name: string;
}

export const ToggleField = ({ name, ...rest }: ToggleFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <>
          <Toggle
            checked={Boolean(field.value)}
            onChange={field.onChange}
            name={name}
            {...rest}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </>
      );
    },
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
