export const Mail = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_48_893)">
      <path
        d="M19.7917 5.48511H5.20833C4.05774 5.48511 3.125 6.41785 3.125 7.56844V17.9851C3.125 19.1357 4.05774 20.0684 5.20833 20.0684H19.7917C20.9423 20.0684 21.875 19.1357 21.875 17.9851V7.56844C21.875 6.41785 20.9423 5.48511 19.7917 5.48511Z"
        stroke="#21272A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.125 7.5686L12.5 13.8186L21.875 7.5686"
        stroke="#21272A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_48_893">
        <rect
          width="25"
          height="25"
          fill="white"
          transform="translate(0 0.276855)"
        />
      </clipPath>
    </defs>
  </svg>
);
