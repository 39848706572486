import { Metadata } from "@pairtreefamily/ui";
import { z } from "zod";

type HandledZodTypeDefs =
  | z.ZodStringDef
  | z.ZodUnionDef
  | z.ZodObjectDef
  | z.ZodLiteralDef
  | z.ZodRecordDef
  | z.ZodOptionalDef
  | z.ZodEffectsDef;

export const zodObjectHandler = (
  typeDef: HandledZodTypeDefs
): string | undefined => {
  switch (typeDef.typeName) {
    case z.ZodFirstPartyTypeKind.ZodString:
      return "";
    case z.ZodFirstPartyTypeKind.ZodUnion:
      return zodObjectHandler(typeDef.options[0]._def);
    case z.ZodFirstPartyTypeKind.ZodLiteral:
      return typeDef.value;
    case z.ZodFirstPartyTypeKind.ZodObject:
      return JSON.stringify(
        Object.fromEntries(
          Object.entries(typeDef.shape()).map(([key, value]) => [
            key,
            zodObjectHandler((value as any)._def),
          ])
        )
      );
    case z.ZodFirstPartyTypeKind.ZodRecord:
      return undefined;
    case z.ZodFirstPartyTypeKind.ZodOptional:
      return undefined;
    case z.ZodFirstPartyTypeKind.ZodEffects:
      return zodObjectHandler(typeDef.schema._def);
    default:
      return "";
  }
};
// Takes a ZodObject and returns a Metadata object with default values
// based on the schema.
// See `zodObjectHandler` for how the value for each type is determined.
export function getDefaultMetadataFromSchema(schema: z.AnyZodObject): Metadata {
  const def = schema._def;
  if (def.typeName == z.ZodFirstPartyTypeKind.ZodObject) {
    return Object.fromEntries(
      Object.entries(def.shape()).map(([key, value]) => [
        key,
        zodObjectHandler((value as any)._def),
      ])
    );
  }
  return {};
}
