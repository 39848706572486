import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { TextInput, TextInputProps } from "../../TextInput";
import { ErrorMessage } from "../error-message";

export interface TextFieldProps
  extends Omit<TextInputProps, "onChange" | "value"> {
  name: string;
}

export const TextField = ({ name, ...rest }: TextFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <>
          <TextInput
            value={field.value}
            onChange={field.onChange}
            name={name}
            {...rest}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </>
      );
    },
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
