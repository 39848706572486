import {
  Button,
  DreamcatcherCondition,
  normalizePath,
} from "@pairtreefamily/ui";

export type ConditionCardProps = {
  condition: DreamcatcherCondition;
  deleteCondition: () => void;
  editCondition: () => void;
};

export function ConditionCard({
  condition,
  editCondition,
  deleteCondition,
}: ConditionCardProps) {
  return (
    <div className="rounded-lg bg-backgroundgray p-2">
      <div className="flex gap-2">
        <Button
          otherStyles="ml-auto"
          style="secondary"
          color="green"
          onClick={editCondition}
        >
          Edit
        </Button>
        <Button style="secondary" color="rust" onClick={deleteCondition}>
          Delete
        </Button>
      </div>
      <div>
        <p>
          <strong>Path:</strong>
        </p>
        <p>{normalizePath(condition.dependencyPath)}</p>
      </div>
      <br />
      <div>
        <p>
          <strong>Expected Values:</strong>
        </p>
        <p>{condition.expectedValues.join(", ")}</p>
      </div>
      <br />
      <div>
        <p>
          <strong>Strategy:</strong>
        </p>
        <p>{condition.strategy}</p>
      </div>
    </div>
  );
}

export default ConditionCard;
