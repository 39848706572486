import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { DreamcatcherOption } from "../select";

export const DreamcatcherRemoteConfigurationSchema = z.object({
  url: z.preprocess((value) => {
    if (value === "") {
      return "https://api.pairtreefamily.com";
    }
  }, z.string().url()),
  method: z.union([z.literal("GET"), z.literal("POST")]),
  headers: z
    .string()
    .optional()
    .refine(
      (headers) => {
        try {
          JSON.parse(headers ?? "{}");
          return true;
        } catch (e) {
          return false;
        }
      },
      { message: "Invalid JSON" }
    ),
  body: z
    .string()
    .optional()
    .refine(
      (body) => {
        try {
          JSON.parse(body ?? "{}");
          return true;
        } catch (e) {
          return false;
        }
      },
      { message: "Invalid JSON" }
    ),
  idKey: z.string(),
  labelKey: z.string(),
  requestListKey: z.string().optional(),
});

export type DreamcatcherRemoteConfiguration = z.infer<
  typeof DreamcatcherRemoteConfigurationSchema
>;

export const useDynamicAPIOptions = (
  inputId: string,
  {
    url,
    method,
    body,
    headers,
    idKey,
    labelKey,
    requestListKey,
  }: DreamcatcherRemoteConfiguration
) => {
  const {
    status,
    error,
    data: options,
  } = useQuery<DreamcatcherOption[], Error>({
    queryKey: [`${inputId}-options`],
    retry: 3, // retries 3 times on failure
    queryFn: async () => {
      const response = await fetch(url, {
        method,
        body: method == "POST" ? body : undefined,
        headers: {
          "Content-Type": "application/json",
          ...(headers ? JSON.parse(headers) : {}),
        },
      });
      if (response.status >= 400) {
        throw new Error("Bad response from server: " + (await response.text()));
      }
      const data = await response.json();
      let listData = data;
      if (requestListKey) {
        listData = data[requestListKey];
      }

      return listData && Array.isArray(listData)
        ? listData.map((datum): DreamcatcherOption => {
            return {
              id: datum[idKey],
              label: datum[labelKey],
            };
          })
        : [];
    },
  });

  return {
    status,
    error,
    options,
  };
};
