import {
  BaseDreamcatcherInput,
  ConditionalStrategy,
  DreamcatcherCondition,
  DreamcatcherStepProps,
  Metadata,
  Modal,
  normalizePath,
  Select,
  useDreamcatcherOptionMetadata,
} from "@pairtreefamily/ui";
import { useEffect, useMemo, useState } from "react";

export type EditConditionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  updateCondition: (
    condition: Omit<DreamcatcherCondition, "id">,
    id?: string
  ) => void;
  condition?: DreamcatcherCondition;
  steps: DreamcatcherStepProps[];
  flowId: string; //TODO handle the flow segment better
};

export function EditConditionModal(props: EditConditionModalProps) {
  const [id, setId] = useState<string | null>(null);
  const [step, setStep] = useState<DreamcatcherStepProps | null>(null);
  const [input, setInput] = useState<BaseDreamcatcherInput | null>(null);
  const [expectedValues, setExpectedValues] = useState<Set<string>>(new Set());
  const [strategy, setStrategy] = useState<ConditionalStrategy>("intersection");

  const optionRes = useDreamcatcherOptionMetadata(input?.metadata);

  const stepOptions = useMemo(
    () =>
      props.steps.map((s) => ({
        id: s.id,
        label: s.title,
      })),
    [props.steps]
  );

  const inputOptions = useMemo(() => {
    if (!step) {
      return [];
    }

    return step.inputs.map((i) => ({
      id: i.id,
      label: i.id,
    }));
  }, [step, props.steps]);

  const updateCondition = async () => {
    if (!validPath) {
      return false; // TODO update to use error message
      //return "Dependency path is invalid";
    }
    const condition: Omit<DreamcatcherCondition, "id"> = {
      strategy: strategy,
      dependencyPath: [props.flowId, step.id, input.id],
      expectedValues: [...expectedValues],
    };
    props.updateCondition(condition, id ?? undefined);
    return true;
  };

  const updateExpectedValue = (id: string, isChecked: boolean) => {
    setExpectedValues((vals) => {
      const newSet = new Set(vals);
      if (isChecked) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
  };

  useEffect(() => {
    setId(props.condition?.id ?? null);
    // reset values if the passed condition changes
    let step = null;
    let input = null;
    if (props.condition) {
      const [_flowId, stepId, inputId] = props.condition.dependencyPath;
      step = props.steps.find((s) => s.id === stepId) ?? null;
      if (step) {
        input = step.inputs.find((i) => i.id === inputId) ?? null;
      }
    }
    setStep(step);
    setInput(input);
    setExpectedValues(new Set(props.condition?.expectedValues ?? []));
    setStrategy(props.condition?.strategy ?? "intersection");
  }, [props.condition]);

  const validPath = step?.id && input?.id;

  return (
    <Modal
      title={`${props.condition ? "Edit" : "Create"} Condition`}
      isOpen={props.isOpen}
      onClose={props.onClose}
      primaryAction={{
        label: `${props.condition ? "Update" : "Create"}`,
        onSubmit: updateCondition,
      }}
    >
      <div>
        <div className="bg-backgroundgray p-4">
          {props.condition && (
            <p>
              <strong>(Existing Dependency Path:)</strong>
              <p>{normalizePath(props.condition?.dependencyPath)}</p>
            </p>
          )}
          <p>
            <strong className="text-black">Dependency Path:</strong>
            <p>
              {normalizePath([
                "flow",
                step?.id ?? "STEP",
                input?.id ?? "INPUT",
              ])}
            </p>
          </p>
          <Select
            options={stepOptions}
            value={step?.id ?? null}
            label="Step"
            onChange={(stepId) => {
              setStep(props.steps.find((s) => s.id === stepId) ?? null);
              setInput(null);
            }}
            placeholder={"Select a step"}
          />
          <Select
            disabled={!step}
            options={inputOptions}
            value={input?.id ?? null}
            label="Input"
            onChange={(inputId) =>
              setInput(step?.inputs.find((i) => i.id === inputId) ?? null)
            }
            placeholder={"Select an input"}
          />
        </div>
        {validPath /* TODO show existing values which may not map (anymore) to the selected input's option */ && (
          <>
            <p>Expected Values </p>
            {optionRes.supportsOptions && (
              <div className="flex flex-col">
                {optionRes.options.map((o, i) => (
                  <label key={i}>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        updateExpectedValue(o.id, e.target.checked)
                      }
                      checked={expectedValues.has(o.id)}
                    />
                    {o.label}
                  </label>
                ))}
              </div>
            )}
            {!optionRes.supportsOptions && (
              <p>The selected input does not support options</p>
            )}
          </>
        )}

        <Select
          options={[
            {
              id: "intersection",
              label: "intersection",
            },
            {
              id: "subset",
              label: "subset",
            },
            {
              id: "equality",
              label: "equality",
            },
          ]}
          value={strategy}
          label="Strategy"
          onChange={setStrategy}
          placeholder={"Select a conditional strategy"}
        />
        <p className="text-body-4">
          (If you&apos;re not sure, &quot;intersection&quot; is the right value)
        </p>
      </div>
    </Modal>
  );
}

export default EditConditionModal;
