import { useMemo } from "react";
import { Metadata } from "../../..";
import {
  getDreamcatcherOptionMetadata,
  testMetadataHasOptions,
} from "./shared";

export type MetadataOptions = ReturnType<typeof getDreamcatcherOptionMetadata>;
export type DreamcatcherOptionMetadataResult =
  | { supportsOptions: false; options: null }
  | { supportsOptions: true; options: MetadataOptions };

export const useDreamcatcherOptionMetadata = (
  metadata: Metadata | undefined
): DreamcatcherOptionMetadataResult => {
  return useMemo(() => {
    const res = testMetadataHasOptions(metadata ?? {});
    if (!res.success) {
      return { supportsOptions: false, options: null };
    }
    const options = getDreamcatcherOptionMetadata(res.metadata);

    return {
      supportsOptions: true,
      options: options,
    };
  }, [metadata]);
};
