export function UserIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C11.0217 0 7.20657 1.58034 4.39343 4.39343C1.58029 7.20651 0 11.0217 0 15C0 18.9783 1.58034 22.7934 4.39343 25.6066C7.20651 28.4197 11.0217 30 15 30C18.9783 30 22.7934 28.4197 25.6066 25.6066C28.4197 22.7935 30 18.9783 30 15C30 12.367 29.3069 9.78029 27.9904 7.5C26.6739 5.21974 24.7801 3.326 22.5001 2.00971C20.2199 0.6932 17.6333 0.000114223 15.0001 0.000114223L15 0ZM6.68571 24.7929C7.02557 23.3821 7.71797 22.0808 8.698 21.0109C9.67814 19.9408 10.9137 19.1371 12.2894 18.675C13.1365 19.0771 14.0624 19.2857 15.0001 19.2857C15.9379 19.2857 16.8638 19.0771 17.7109 18.675C19.0864 19.1371 20.322 19.9408 21.3023 21.0109C22.2823 22.0808 22.9747 23.3822 23.3146 24.7929C20.9918 26.7621 18.0454 27.8429 15.0003 27.8429C11.9551 27.8429 9.00886 26.7619 6.686 24.7929H6.68571ZM15 17.1429C13.8634 17.1429 12.7732 16.6913 11.9694 15.8876C11.1657 15.0839 10.7142 13.9937 10.7142 12.857C10.7142 11.7203 11.1657 10.6302 11.9694 9.82646C12.7731 9.02269 13.8633 8.5712 15 8.5712C16.1367 8.5712 17.2268 9.02277 18.0306 9.82646C18.8343 10.6301 19.2858 11.7203 19.2858 12.857C19.2858 13.9937 18.8343 15.0838 18.0306 15.8876C17.2269 16.6914 16.1367 17.1429 15 17.1429ZM25.0071 23.0463C24.0537 20.4959 22.1651 18.4043 19.7249 17.1963C20.8607 15.9651 21.4711 14.3393 21.4261 12.6646C21.3811 10.9901 20.6843 9.39914 19.4841 8.23086C18.2839 7.06243 16.675 6.40854 14.9998 6.40854C13.3247 6.40854 11.7158 7.06246 10.5155 8.23086C9.31531 9.39929 8.61854 10.9901 8.57357 12.6646C8.52859 14.3391 9.13897 15.9651 10.2748 17.1963C7.83474 18.4043 5.94623 20.496 4.99251 23.0463C2.95077 20.5153 1.94394 17.3046 2.175 14.0609C2.40591 10.8171 3.85737 7.78143 6.237 5.56514C8.61669 3.34886 11.7479 2.11657 14.9996 2.11657C18.2513 2.11657 21.3824 3.34871 23.7621 5.56514C26.1418 7.78143 27.5933 10.8171 27.8241 14.0609C28.0551 17.3046 27.0484 20.5151 25.0066 23.0463H25.0071Z"
        fill="currentColor"
      />
    </svg>
  );
}
