import "../styles/globals.css";
import "../styles/fonts.css";
import "../styles/nprogress.css";
import type { AppProps } from "next/app";
import Layout from "../components/layout";
import { CoreAPIProvider } from "../components/CoreAPIProvider";
import {
  AuthStatusProvider,
  ConfirmationModalProvider,
  PosthogProvider,
} from "@pairtreefamily/ui";
import { app } from "../utils/firebaseConfig";
import { AccountContext, AccountProvider } from "../components/AccountProvider";
import { BottomModalSheetProvider } from "../components/BottomModalSheet";
import { QuestionAnswerProvider } from "@/domains/questionAnswering";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CustomTokenProvider } from "@/components/CustomTokenProvider";
import { FlowProvider } from "@/domains/questionAnswering/contexts/Flow";

const queryClient = new QueryClient();

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <AuthStatusProvider app={app}>
      <CoreAPIProvider>
        <QueryClientProvider client={queryClient}>
          <AccountProvider>
            <AccountContext.Consumer>
              {(data) => (
                <PosthogProvider
                  posthogKey="phc_H3M2F9j2pG0mavVUn0NmqoB8PM3daNspn3JGYT0wwGM"
                  posthogApiHost="https://posthog.pairtreefamily.com"
                  accountUuid={data?.account?.uuid ?? null}
                  enabled={
                    process.env.NEXT_PUBLIC_ENABLE_POSTHOG == "true" ||
                    process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
                  }
                  queryParams={router.query as Record<string, string>}
                >
                  <BottomModalSheetProvider modalChildren={<div />}>
                    <CustomTokenProvider>
                      <QuestionAnswerProvider>
                        <FlowProvider>
                          <ConfirmationModalProvider>
                            <Layout>
                              <Component {...pageProps} />
                            </Layout>
                          </ConfirmationModalProvider>
                        </FlowProvider>
                      </QuestionAnswerProvider>
                    </CustomTokenProvider>
                  </BottomModalSheetProvider>
                </PosthogProvider>
              )}
            </AccountContext.Consumer>
          </AccountProvider>
        </QueryClientProvider>
      </CoreAPIProvider>
    </AuthStatusProvider>
  );
}

export default MyApp;
