import { createTheme, ThemeOptions } from "@mui/material";
import * as components from "./components";
import { ThemeProvider as MuiThemeProvider } from "@emotion/react";
import { ReactNode } from "react";
import { typography } from "./typography";
import { palette } from "./palette";

export const themeConfig: Partial<ThemeOptions> = {
  components,
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
};

const theme = createTheme(themeConfig);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export * from "./typography";
export * from "./palette";
