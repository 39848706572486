import { useRouter } from "next/router";
import { useEffect } from "react";
import NProgress from "nprogress";

export type NavigationIndicatorProps = {};

export function NavigationIndicator(props: NavigationIndicatorProps) {
  const router = useRouter();

  // show loading indicator when navigating between pages
  useEffect(() => {
    const onStart = (url: string) => {
      NProgress.start();
    };

    const onStop = () => {
      NProgress.done();
    };

    router.events.on("routeChangeStart", onStart);
    router.events.on("routeChangeComplete", onStop);
    router.events.on("routeChangeError", onStop);

    return () => {
      router.events.off("routeChangeStart", onStart);
      router.events.off("routeChangeComplete", onStop);
      router.events.off("routeChangeError", onStop);
    };
  }, [router]);

  return null;
}

export default NavigationIndicator;
