export const TrashIcon = (props: any) => {
  const { className = "", ...otherProps } = props;

  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`flex-shrink-0 ${className}`}
      {...otherProps}
    >
      <path
        d="M4.5 0L3.75 0.75H0.75C0.3 0.75 0 1.05 0 1.5C0 1.95 0.3 2.25 0.75 2.25H2.25H9.75H11.25C11.7 2.25 12 1.95 12 1.5C12 1.05 11.7 0.75 11.25 0.75H8.25L7.5 0H4.5ZM0.75 3.75V13.5C0.75 14.325 1.425 15 2.25 15H9.75C10.575 15 11.25 14.325 11.25 13.5V3.75H0.75ZM3.75 5.25C4.2 5.25 4.5 5.55 4.5 6V12.75C4.5 13.2 4.2 13.5 3.75 13.5C3.3 13.5 3 13.2 3 12.75V6C3 5.55 3.3 5.25 3.75 5.25ZM8.25 5.25C8.7 5.25 9 5.55 9 6V12.75C9 13.2 8.7 13.5 8.25 13.5C7.8 13.5 7.5 13.2 7.5 12.75V6C7.5 5.55 7.8 5.25 8.25 5.25Z"
        fill="#BD5942"
      />
    </svg>
  );
};
