import React from "react";
import { z } from "zod";
import { BaseDreamcatcherInput, Metadata } from "../../schemas";
import { DreamcatcherDateInputPlugin } from "./DateInput";
import {
  DynamicMultiSelectPlugin,
  DynamicSingleSelectPlugin,
} from "./dynamicInputs";
import {
  DreamcatcherMultiSelectInputPlugin,
  DreamcatcherSingleSelectInputPlugin,
} from "./select";
import { DreamcatcherTextInputPlugin } from "./TextInput";
import DreamcatcherSingleSelectDropdownInputPlugin from "./select/SingleSelectDropdown";

export interface DreamcatcherInputPlugin<M extends Metadata, T extends string> {
  // The name of the input. This is what will be displayed in the Flow Builder UI.
  name: string;
  // The string literal type of the input. This is used to uniquely identify the input type.
  type: T;
  // The React component that will be rendered for this input.
  component: React.FC<BaseDreamcatcherInput<M, T>>;
  // The Zod schema for the metadata that this input will produce. Used for validating the metadata
  // in the Flow Builder UI.
  metadataSchema: z.AnyZodObject; // (Says any zod object, but should be Metadata)
}

type InputType =
  | "dynamic-multi-select"
  | "dynamic-single-select"
  | "single-select"
  | "single-select-dropdown"
  | "multi-select"
  | "text"
  | "date";

// This is the map of all input plugins that are available to the Flow Builder.
// The key is the `type` of the input, and the value is the plugin object.
// Add new input plugins here.
export const inputMap: Record<InputType, DreamcatcherInputPlugin<any, any>> = {
  "dynamic-multi-select": DynamicMultiSelectPlugin,
  "dynamic-single-select": DynamicSingleSelectPlugin,
  text: DreamcatcherTextInputPlugin,
  "single-select": DreamcatcherSingleSelectInputPlugin,
  "single-select-dropdown": DreamcatcherSingleSelectDropdownInputPlugin,
  "multi-select": DreamcatcherMultiSelectInputPlugin,
  date: DreamcatcherDateInputPlugin,
};
// This is the type of all input plugins that are available to the Flow Builder.
// Add new input plugin types here.
export type DreamcatcherInput = BaseDreamcatcherInput<Metadata, InputType>;

export type DreamcatcherInputType = InputType;

export const inputList = Object.values(inputMap);

export const getMetadataSchemaFromType = (type: DreamcatcherInputType) =>
  inputMap[type]?.metadataSchema;

export const getMetadataSchema = (input: DreamcatcherInput) =>
  getMetadataSchemaFromType(input.type);

export const tryGetMetadataSchema = (inputType: string) => {
  if (inputMap[inputType as InputType] === undefined) {
    return null;
  }
  return getMetadataSchemaFromType(inputType as InputType);
};

export function getInputComponent<M extends Metadata, T extends string>(
  inputType: T
): null | React.FC<BaseDreamcatcherInput<M, T>> {
  return inputMap[inputType as InputType]?.component ?? null;
}
