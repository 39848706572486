import { ControllerFieldState } from "react-hook-form/dist/types/controller";

type ErrorMessageProps = {
  fieldState: ControllerFieldState;
};

export const ErrorMessage = ({ fieldState }: ErrorMessageProps) => {
  return (
    <p className="pt-[5px] text-[15px] leading-[25px] text-rust">
      {fieldState?.error?.message}
    </p>
  );
};
