import { initializeApp, type FirebaseOptions } from "firebase/app";

// If the NODE_ENV is not production, then we are in development mode
let firebaseConfig: FirebaseOptions;
if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCFdxRiNCa7YCLdvPjKlJ9g4O9LKewUb7Y",
    authDomain: "pairtree-production.firebaseapp.com",
    projectId: "pairtree-production",
    storageBucket: "pairtree-production.appspot.com",
    messagingSenderId: "21351806198",
    appId: "1:21351806198:web:5d4af34d8ebc92f698e8d7",
    measurementId: "G-7X4Z7PF31Q",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyC-r0P3BbFx-6OKYeNeeLy89jg9GO9YEvA",
    authDomain: "pairtree-development.firebaseapp.com",
    projectId: "pairtree-development",
    storageBucket: "pairtree-development.appspot.com",
    messagingSenderId: "781051164274",
    appId: "1:781051164274:web:6f81d311ac9f5ddedbb4eb",
    measurementId: "G-ZJJ7SBNK7J",
  };
}

export const app = initializeApp(firebaseConfig);
