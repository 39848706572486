import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { filterOptions } from "./utils";
import { SelectOption } from "../Select";
import {
  getListInputClassName,
  getOptionClassName,
  getOptionListClassName,
  ComponentColor,
  getWrapperClassName,
} from "../../shared";
import Button from "../Button";

export interface AutocompleteProps<T extends string = string> {
  options: SelectOption<T>[];
  value: T | null;
  placeholder?: string;
  onChange: (option: T) => void;
  color?: ComponentColor;
  invalid?: boolean;
  whiteBorder?: boolean;
  label?: string;
  disabled?: boolean;
  allowCustomValue?: boolean;
  name?: string;
  autoComplete?: "off" | "on";
}

export function Autocomplete<T extends string = string>({
  options,
  value,
  placeholder,
  color = "teal",
  invalid,
  whiteBorder,
  label,
  onChange,
  disabled,
  allowCustomValue,
  name,
  autoComplete = "off",
}: AutocompleteProps<T>) {
  const [selectedValue, setSelectedValue] = useState<SelectOption<T>>();
  const [query, setQuery] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [customValue, setCustomValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const selectedOption = options.find((option) => option.id === value);
    setSelectedValue(selectedOption);
    setQuery("");
  }, [options, value]);

  const filteredOptions = useMemo(() => {
    return filterOptions(options, query);
  }, [options, query]);

  const handleInputFocus = useCallback(() => {
    setIsListOpen(true);
    inputRef.current?.select();
  }, []);

  const handleInputBlur = useCallback(() => {
    // There are race conditions between input blur and list item click
    setTimeout(() => {
      setIsListOpen(false);
    }, 300);
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    []
  );

  const handleChange = useCallback(
    (value: SelectOption<T>) => {
      onChange(value.id);

      // Right after selection the input is focused we need a timeout to force close the list
      setTimeout(() => {
        setIsListOpen(false);
        inputRef.current?.blur();
      }, 100);
    },
    [onChange]
  );

  const isValueIsInTheList = useMemo(() => {
    return options.some((option) => option.id === value);
  }, [options, value]);

  useEffect(() => {
    if (allowCustomValue && !isValueIsInTheList) {
      setCustomValue(value ?? "");
    }
  }, [allowCustomValue, isValueIsInTheList, value]);

  const handleDisplayValue = useCallback(
    (option: SelectOption) => {
      if (allowCustomValue && !isValueIsInTheList) {
        return customValue;
      }

      return option.label;
    },
    [allowCustomValue, isValueIsInTheList, customValue]
  );

  return (
    <div className={getWrapperClassName({ disabled })}>
      <Combobox
        value={selectedValue || ""}
        onChange={handleChange}
        disabled={disabled}
      >
        {label && (
          <div className="text-left">
            <span className="text-body-4-semi mb-[5px]">{label}</span>
          </div>
        )}

        <div className="relative mt-1">
          <div className="focus-visible:ring-offset-teal-300 sm:text-sm relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2">
            <Combobox.Input
              name={name}
              ref={inputRef}
              placeholder={placeholder}
              className={getListInputClassName({ color, invalid, whiteBorder })}
              displayValue={handleDisplayValue}
              // Headless UI doesn't support List toggle on input click, so we need to handle it manually
              // https://github.com/tailwindlabs/headlessui/discussions/1236
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              autoComplete={autoComplete}
            />

            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="text-gray-400 h-5 w-5"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>

          {isListOpen && (
            <>
              <Combobox.Options
                static
                className={getOptionListClassName({ color })}
              >
                {allowCustomValue && customValue && (
                  <Combobox.Option
                    className={({ selected, active }) =>
                      getOptionClassName({ selected, active, color })
                    }
                    value={{ id: customValue, name: customValue }}
                  >
                    <strong className="pl-4">
                      Custom value - {customValue}
                    </strong>
                  </Combobox.Option>
                )}

                {!filteredOptions.length && query ? (
                  <>
                    {allowCustomValue ? (
                      <div className="text-gray-700 relative flex cursor-default select-none items-center gap-1 py-2 px-4">
                        <Button
                          color="teal"
                          onClick={() => {
                            handleChange({ id: query as T, label: query });
                            setQuery("");
                          }}
                          style="text"
                        >
                          Add Custom Value
                        </Button>
                        - {query}
                      </div>
                    ) : (
                      <div className="text-gray-700 relative cursor-default select-none py-2 px-4">
                        Nothing found.
                      </div>
                    )}
                  </>
                ) : (
                  filteredOptions.map((option) => {
                    return (
                      <Combobox.Option
                        key={option.id}
                        className={({ selected, active }) =>
                          getOptionClassName({ selected, active, color })
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate pl-4 text-left ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {option.label}
                            </span>

                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-1 ${
                                  active ? "text-white" : "text-teal-600"
                                }`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    );
                  })
                )}
              </Combobox.Options>
            </>
          )}
        </div>
      </Combobox>
    </div>
  );
}
