import {
  Accordion,
  AccordionButton,
  AccordionPanel,
  Button,
  DreamcatcherInputMetadataWithOptions,
  useConfirmationModal,
  useDreamcatcherOptionMetadata,
} from "@pairtreefamily/ui";
import { FlowBuilderInputControl } from ".";
import OptionCreator from "../../OptionCreator";
import OptionEditor from "../../OptionEditor";

const OptionControl: FlowBuilderInputControl<
  DreamcatcherInputMetadataWithOptions
> = ({ metadata, setMetadata }) => {
  const optionsRes = useDreamcatcherOptionMetadata(metadata);
  const options = optionsRes.supportsOptions ? optionsRes.options : [];
  const { withConfirmation } = useConfirmationModal();

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="text-head-7">Options</h3>
        <div className="my-5">
          <OptionCreator
            createOption={(option) => {
              const newOptions = JSON.stringify([...options, option]);
              setMetadata({
                ...metadata,
                options: newOptions,
              });
            }}
          />
        </div>
      </div>
      <div>
        {options.map((option, idx) => (
          <div
            key={idx}
            className="mb-1 w-full border border-backgroundgray bg-white"
          >
            <Accordion>
              <div className="flex items-center justify-between border-backgroundgray bg-white px-2 py-1">
                <AccordionButton className="flex w-full items-start">
                  <h3 className="text-head-7">{option.id}</h3>
                </AccordionButton>
                <Button
                  style="secondary"
                  color="peach"
                  onClick={() => {
                    withConfirmation("This option will be deleted.", () =>
                      setMetadata({
                        ...metadata,
                        options: JSON.stringify(
                          options.filter((o) => o.id !== option.id)
                        ),
                      })
                    );
                  }}
                >
                  Delete
                </Button>
              </div>
              <AccordionPanel>
                <OptionEditor
                  option={option}
                  handleOptionChange={(value, name) =>
                    setMetadata({
                      ...metadata,
                      options: JSON.stringify(
                        options.map((o) =>
                          o.id === option.id ? { ...o, [name]: value } : o
                        )
                      ),
                    })
                  }
                />
              </AccordionPanel>
            </Accordion>
          </div>
        ))}
      </div>
    </>
  );
};

export default OptionControl;
