export const ArrowIcon = (props: any) => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="currentColor"
      {...props}
    >
      <path
        d="M20.5036 7.74183L13.5907 0.547954C13.4351 0.377608 13.2483 0.241383 13.0414 0.147302C12.8345 0.0532212 12.6115 0.00318729 12.3857 0.000147377C12.1599 -0.00289253 11.9358 0.0411231 11.7266 0.129602C11.5174 0.21808 11.3273 0.349232 11.1675 0.515333C11.0078 0.681433 10.8816 0.879123 10.7964 1.09676C10.7112 1.31439 10.6687 1.54757 10.6714 1.78256C10.6741 2.01755 10.722 2.24959 10.8122 2.46502C10.9025 2.68046 11.0332 2.87492 11.1968 3.03697L15.2832 7.28293H1.69253C1.24365 7.28293 0.813143 7.4685 0.495732 7.79881C0.17832 8.12912 0 8.57712 0 9.04425C0 9.51138 0.17832 9.95938 0.495732 10.2897C0.813143 10.62 1.24365 10.8056 1.69253 10.8056H15.2454L11.1968 15.0253C10.8926 15.3584 10.7255 15.802 10.7312 16.2615C10.7369 16.7209 10.915 17.1599 11.2273 17.4847C11.5397 17.8094 11.9617 17.9943 12.4032 17.9999C12.8447 18.0054 13.2709 17.8312 13.5907 17.5143L20.5036 10.3204C20.6613 10.1573 20.7863 9.96318 20.8714 9.74936C20.9565 9.53553 20.9999 9.30627 20.9992 9.07484C21.0003 9.0603 21.0003 9.04568 20.9992 9.03114C21.0002 9.01659 21.0002 9.00198 20.9992 8.98743C20.999 8.52032 20.8208 8.07235 20.5036 7.74183Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ArrowIcon;
