/* 
**PostHogProvider Component**

This component is a provider for the PostHog analytics context. 

**Props**

- `children` (ReactNode): This is the content to be rendered within the provider.
- `posthogKey` (string): This is the API key used for PostHog analytics. Default is `"phc_Ke5Lvq6noCJhx48HzTPlExXG3z1SBtT7DExTVArc4WD"`.
- `posthogApiHost` (string): This is the URL of the PostHog API host. Default is `"https://posthog.pairtreefamily.com"`.
- `accountUuid` (string or null): This is the account UUID used for identifying a user with PostHog.
- `enabled` (boolean): Determines whether the PostHog tracking is enabled. Feature flag via query parameter overrides are still supported (see below).

**Features**

- Initializes PostHog with provided `posthogKey` and `posthogApiHost`.
- Automatically identifies the user with the provided `accountUuid` when available.
- Tracks enabled feature flags and provides a method for accessing the payload of a feature flag.
- Provides a method for capturing events with optional properties.
- Provides a method to disable PostHog tracking.
- Overrides feature flags with query parameters prefixed with `ff-`.

**Environment Variables**

- `NEXT_PUBLIC_ENABLE_POSTHOG`: Enables sending events to PostHog when set to `true`. Disabled by default for local environments since it uses the same instance as production.
- `NEXT_PUBLIC_ENVIRONMENT`: When set to `production`, enables PostHog.

**Usage**

Use this provider component to wrap your application, or part of it, to allow child components to access PostHog analytics functions. It's recommended to wrap the whole application in `_app.tsx` or `index.tsx`. Use the PostHog context (`PosthogContext`) within the child components to access the provided methods and variables. 

*/
import posthog from "posthog-js";
import type { JsonType } from "posthog-js";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

// When we use posthog, we care about a few things:
export type PosthogContextData = {
  // 1. Identifying the user (which is done automatically in this hook)
  // identify: (accountUuid: string) => void;
  // 2. Capturing events
  capture: (event: string, properties?: Record<string, any>) => void;
  // 3. Enabled feature flags
  enabledFeatureFlags: Record<string, boolean>;
  // 4. Feature flag payloads (special configuration data)
  getFeatureFlagPayload: (featureFlagKey: string) => JsonType;
  // 5. Disabling posthog (if opting out of tracking)
  disable: () => void;
};

export const PosthogContext = createContext<PosthogContextData | null>(null);

export interface PosthogProviderProps {
  children: ReactNode;
  posthogKey: string;
  posthogApiHost: string;
  accountUuid: string | null;
  enabled: boolean;
  queryParams: Record<string, string>;
}

export const PosthogProvider = ({
  accountUuid,
  posthogKey = "phc_Ke5Lvq6noCJhx48HzTPlExXG3z1SBtT7DExTVArc4WD",
  posthogApiHost = "https://posthog.pairtreefamily.com",
  enabled,
  queryParams,
  children,
}: PosthogProviderProps) => {
  const [enabledFeatureFlags, setEnabledFeatureFlags] = useState({});
  useEffect(() => {
    if (!enabled) {
      return;
    }
    posthog.init(posthogKey, {
      api_host: posthogApiHost,
    });
    posthog.featureFlags.onFeatureFlags((featureFlags) => {
      // featureFlags: string[] with the names of enabled feature flags
      const featureFlagsObject: Record<string, boolean> = {};
      for (const featureFlag of featureFlags) {
        featureFlagsObject[featureFlag] = true;
      }

      setEnabledFeatureFlags((original) => {
        return {
          ...original,
          ...featureFlagsObject,
        };
      });
    });
  }, [enabled, setEnabledFeatureFlags, posthogKey, posthogApiHost]);

  // Override feature flags with query params.
  // Query parameters prefixed with `ff-` will be
  // treated as enabled feature flags.
  useEffect(() => {
    const enabledFeatureFlags: Record<string, boolean> = {};
    for (const [key, value] of Object.entries(queryParams)) {
      if (!key.startsWith("ff-")) {
        continue;
      }

      if (value === "true") {
        enabledFeatureFlags[key.replace("ff-", "")] = true;
      }
    }
    setEnabledFeatureFlags((original) => {
      return {
        ...original,
        ...enabledFeatureFlags,
      };
    });
  }, [queryParams]);

  const identify = useMemo(
    () => (accountUuid: string) => {
      if (!enabled) {
        return;
      }
      posthog.identify(accountUuid);
    },
    [posthog, enabled]
  );

  const capture = useMemo(
    () => (event: string, properties?: Record<string, any>) => {
      if (!enabled) {
        return;
      }
      posthog.capture(event, properties);
    },
    [posthog, enabled]
  );

  const getFeatureFlagPayload = useMemo(
    () => (featureFlagKey: string) => {
      if (!enabled) {
        return {};
      }
      return posthog.getFeatureFlagPayload(featureFlagKey);
    },
    [posthog, enabled]
  );

  const disable = useMemo(
    () => () => {
      if (!enabled) {
        return;
      }
      posthog.opt_out_capturing();
    },
    [posthog, enabled]
  );

  // Identify the user when the account changes
  useEffect(() => {
    if (accountUuid) {
      identify(accountUuid);
    }
  }, [accountUuid, identify]);

  return (
    <PosthogContext.Provider
      value={{
        capture,
        enabledFeatureFlags,
        getFeatureFlagPayload,
        disable,
      }}
    >
      {children}
    </PosthogContext.Provider>
  );
};
