import { useEffect, useState } from "react";
import {
  Button,
  DreamcatcherFlow,
  DreamcatcherFlowProps,
  DreamcatcherPathProvider,
} from "@pairtreefamily/ui";

export interface FlowPreviewProps {
  flow: DreamcatcherFlowProps;
  setFlow: (flow: DreamcatcherFlowProps) => void;
}
const FlowPreview = ({ flow, setFlow }: FlowPreviewProps) => {
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [renderKey, setRenderKey] = useState(0);
  const [rawFlow, setRawFlow] = useState<string>(JSON.stringify(flow, null, 2));
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // When flow changes, reset preview
    setRenderKey(renderKey + 1);
    setRawFlow(JSON.stringify(flow, null, 2));
    setError(null);
  }, [flow]);
  return (
    <div className="h-full">
      <Button
        style="primary"
        color="teal"
        onClick={() => setIsPreviewing(!isPreviewing)}
      >
        {isPreviewing ? "Hide" : "Show"} flow preview
      </Button>
      {!isPreviewing && (
        <>
          <div className="flex h-full flex-col">
            <textarea
              value={rawFlow}
              onChange={(e) => setRawFlow(e.target.value)}
              onBlur={() => {
                try {
                  const newFlow = JSON.parse(rawFlow);
                  const formattedRaw = JSON.stringify(newFlow, null, 2);
                  setFlow(newFlow);
                  setRawFlow(formattedRaw);
                  setError(null);
                } catch (e) {
                  if (e instanceof Error) {
                    setError(e.message);
                  } else {
                    throw e;
                  }
                }
              }}
              key={renderKey}
              className="overflow-x w-80 grow"
            />
            {error && <p className="text-body-2 max-w-sm text-rust">{error}</p>}
            <div>
              Warning: when manually updating these values, there is no
              validation. <br /> Be extra careful.
            </div>
          </div>
        </>
      )}
      {isPreviewing && (
        <div className="border border-backgroundgray-shade p-4">
          <DreamcatcherPathProvider>
            <DreamcatcherFlow key={renderKey} {...flow} />
          </DreamcatcherPathProvider>
        </div>
      )}
    </div>
  );
};

export default FlowPreview;
