import { ButtonProps, Button } from "../Button";
import ArrowIcon from "../icons/Arrow";

const GoBackButton = (props: ButtonProps) => {
  return (
    <Button {...props} otherStyles="mb-7" style="text">
      <ArrowIcon className="mr-2.5 rotate-180" />
      Go back
    </Button>
  );
};

export default GoBackButton;
