import Button from "../../Button";

export type ForModalActionProps = {
  onCancel: () => void;
  isLoading: boolean;
  submitLabel: string;
  isSubmitDisabled?: boolean;
};

export const FormModalActions = ({
  onCancel,
  isLoading,
  submitLabel,
  isSubmitDisabled,
}: ForModalActionProps) => {
  return (
    <div className="mt-5 flex justify-center gap-4">
      <Button onClick={onCancel} color="teal" style="secondary" type="button">
        Cancel
      </Button>

      <Button
        disabled={isSubmitDisabled}
        type="submit"
        style="secondary"
        color="green"
        loading={isLoading}
      >
        {submitLabel}
      </Button>
    </div>
  );
};
