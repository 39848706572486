export type CheckMarkProps = {
  className?: string;
};
export function CheckMarkIcon(props: CheckMarkProps) {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M23.2977 0.00579971C22.8744 0.0352315 22.4775 0.252187 22.1911 0.610831L10.1713 15.5219L3.75276 9.21785C3.5958 9.02477 3.40503 8.87187 3.19294 8.76918C2.98085 8.6665 2.75223 8.61633 2.52206 8.62197C2.29188 8.6276 2.06534 8.68892 1.85727 8.80189C1.6492 8.91486 1.4643 9.07692 1.31466 9.27751C1.16503 9.47809 1.05405 9.71266 0.988992 9.96583C0.92393 10.219 0.906254 10.485 0.937122 10.7466C0.96799 11.0081 1.04671 11.2593 1.16811 11.4835C1.28952 11.7078 1.45088 11.9001 1.64163 12.0479L9.26894 19.5425C9.58851 19.8598 10.0029 20.0229 10.4252 19.9974C10.8474 19.9719 11.2446 19.7599 11.5333 19.4058L24.6087 3.16758C24.8372 2.89308 24.9877 2.54552 25.0402 2.17087C25.0926 1.79621 25.0447 1.41215 24.9026 1.06945C24.7605 0.726738 24.5309 0.441553 24.2444 0.251632C23.9578 0.0617102 23.6277 -0.0240317 23.2977 0.00579971Z"
        fill="currentColor"
      />
    </svg>
  );
}
