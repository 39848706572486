import { useCoreAPI } from "@/hooks/useCoreAPI";
import { useQuery } from "@tanstack/react-query";
import { createContext } from "react";

// Custom tokens are used to log in across domains.
// https://docs.craft.do/editor/d/4913d32a-1010-9848-ea5f-61eddc7e7e4b/2bf96004-1d90-452c-b2dc-332c70624a01
const useCustomTokenContext = () => {
  const CoreAPI = useCoreAPI();
  const result = useQuery(
    ["mintCustomToken"],
    async () => {
      const response = await CoreAPI.mintCustomToken();
      if (response.ok) {
        return response;
      }
      throw new Error(JSON.stringify(response.error));
    },
    {
      // Only fetch if the user is authenticated from the POV of CoreAPI.
      enabled: CoreAPI.isAuthenticated(),
      // 30 minutes in ms
      staleTime: 30 * 60 * 1000,
    }
  );

  return {
    token: result.data?.ok ? result.data.content.token : null,
    error: result.error,
    isLoading: result.isLoading,
  };
};

export type CustomTokenContextData = ReturnType<typeof useCustomTokenContext>;

export const CustomTokenContext = createContext<CustomTokenContextData | null>(
  null
);

export const CustomTokenProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const value = useCustomTokenContext();

  return (
    <CustomTokenContext.Provider value={value}>
      {children}
    </CustomTokenContext.Provider>
  );
};
