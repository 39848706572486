import { ReactNode, useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { getClassName } from "@pairtreefamily/utils";

export type AccordionProps = {
  children:
    | ReactNode
    | ((props: { open: boolean; close: () => void }) => JSX.Element);
};

export function Accordion(props: AccordionProps) {
  return <Disclosure>{props.children}</Disclosure>;
}

type AccordionButtonProps = {
  className?: string;
  children: ReactNode | ((props: { open: boolean }) => JSX.Element);
};

export function AccordionButton(props: AccordionButtonProps) {
  return (
    <Disclosure.Button className={props.className}>
      {props.children}
    </Disclosure.Button>
  );
}

export type AccordionPanelProps = {
  children: ReactNode;
  className?: string;
};

/* TODO better animation; this looks smooth but does not fully support
 * panels which are greater in height than the window
 * using overflow-scroll (instead of overflow-hidden) to make sure we can
 * access all content even in these edge cases. Should replace with a js
 * implementation with no such limitation
 */
export function AccordionPanel(props: AccordionPanelProps) {
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  return (
    <div className={getClassName("w-full", !isAnimating && "overflow-y-auto")}>
      <Transition
        enter="transition-all duration-500 ease-in"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-screen opacity-100"
        leave="transition-all duration-200 ease-out"
        leaveFrom="max-h-screen opacity-100"
        leaveTo="max-h-0 opacity-0"
        afterEnter={() => setIsAnimating(false)}
        beforeEnter={() => setIsAnimating(true)}
      >
        <Disclosure.Panel className={props.className}>
          {props.children}
        </Disclosure.Panel>
      </Transition>
    </div>
  );
}

export default Accordion;
