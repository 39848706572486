import {
  CSSProperties,
  TypographyOptions,
  TypographyStyleOptions,
} from "@mui/material/styles/createTypography";

export const typography: CustomTypographyOptions = {
  fontFamily: "Montserrat, sans-serif",
  fontSize: 20,
  body1: {
    fontSize: 20,
    lineHeight: "30px",
    fontWeight: 400,
  },
  body2: {
    fontSize: 17,
    lineHeight: "27px",
    fontWeight: 400,
  },
  body3: {
    fontSize: 15,
    lineHeight: "25px",
    fontWeight: 700,
  },
  body4: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 700,
  },
  body5: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 700,
    fontStyle: "italic",
  },
  body6: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 400,
  },
  body7: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 400,
    fontStyle: "italic",
  },
  body8: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 500,
  },
  body9: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 600,
  },
  body10: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 500,
    fontStyle: "italic",
  },
  caption1: {
    fontSize: 12,
    lineHeight: "22px",
    fontWeight: 400,
  },
  caption2: {
    fontSize: 12,
    lineHeight: "22px",
    fontWeight: 600,
  },
  alerts: {
    fontSize: 10,
    lineHeight: "16px",
    fontWeight: 700,
  },
  input: {
    fontSize: 15,
    lineHeight: "25px",
    fontWeight: 400,
    fontFamily: "Montserrat, sans-serif",
  },
};

declare module "@mui/material/Typography" {
  // Turn on custom variants
  interface TypographyPropsVariantOverrides {
    body2: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    body8: true;
    body9: true;
    body10: true;
    caption1: true;
    caption2: true;
    alerts: true;
    input: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body2: CSSProperties;
    body3: CSSProperties;
    body4: CSSProperties;
    body5: CSSProperties;
    body6: CSSProperties;
    body7: CSSProperties;
    body8: CSSProperties;
    body9: CSSProperties;
    body10: CSSProperties;
    caption1: CSSProperties;
    caption2: CSSProperties;
    alerts: CSSProperties;
    input: CSSProperties;
  }
}

export interface CustomTypographyOptions extends TypographyOptions {
  body2: TypographyStyleOptions;
  body3: TypographyStyleOptions;
  body4: TypographyStyleOptions;
  body5: TypographyStyleOptions;
  body6: TypographyStyleOptions;
  body7: TypographyStyleOptions;
  body8: TypographyStyleOptions;
  body9: TypographyStyleOptions;
  body10: TypographyStyleOptions;
  caption1: TypographyStyleOptions;
  caption2: TypographyStyleOptions;
  alerts: TypographyStyleOptions;
  input: TypographyStyleOptions;
}
