export function ApprovedBadgeIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89267 15.2722C5.06553 15.4444 5.16269 15.6783 5.16269 15.9223V18.9223C5.16269 19.1656 5.25937 19.3989 5.4314 19.571C5.60344 19.7431 5.83684 19.8397 6.08017 19.8397H9.08017C9.32415 19.8397 9.55805 19.9369 9.73015 20.1098L11.8527 22.2323H11.8527C12.0248 22.4043 12.2581 22.501 12.5014 22.501C12.7448 22.501 12.9782 22.4043 13.1502 22.2323L15.2727 20.1098C15.4449 19.9369 15.6788 19.8397 15.9228 19.8397H18.9228C19.1661 19.8397 19.3994 19.7431 19.5715 19.571C19.7436 19.3989 19.8402 19.1656 19.8402 18.9223V15.9223C19.8402 15.6783 19.9374 15.4444 20.1103 15.2722L22.2328 13.1497C22.4048 12.9777 22.5015 12.7443 22.5015 12.5009C22.5015 12.2576 22.4048 12.0243 22.2328 11.8522L20.1103 9.72975V9.72966C19.9374 9.55755 19.8402 9.32366 19.8402 9.07968V6.07968C19.8402 5.83635 19.7436 5.60296 19.5715 5.43091C19.3994 5.25888 19.1661 5.16221 18.9228 5.16221H15.9228C15.6788 5.16221 15.4449 5.06504 15.2727 4.89218L13.1502 2.76968C12.9782 2.59765 12.7448 2.50098 12.5014 2.50098C12.2581 2.50098 12.0248 2.59766 11.8527 2.76968L9.73023 4.89218H9.73015C9.55803 5.06504 9.32415 5.16221 9.08017 5.16221H6.08017C5.83684 5.16221 5.60344 5.25888 5.4314 5.43091C5.25937 5.60295 5.16269 5.83635 5.16269 6.07968V9.07968C5.16269 9.32366 5.06553 9.55756 4.89267 9.72966L2.77017 11.8522V11.8522C2.59814 12.0243 2.50146 12.2576 2.50146 12.5009C2.50146 12.7443 2.59814 12.9777 2.77017 13.1497L4.89267 15.2722ZM10.1852 11.2497L11.6052 12.6672L14.5852 9.69968L15.9152 11.0297L12.0952 14.8472C11.9649 14.9774 11.7882 15.0506 11.604 15.0506C11.4197 15.0506 11.243 14.9774 11.1127 14.8472L8.86267 12.5972L10.1852 11.2497Z"
        fill="currentColor"
      />
    </svg>
  );
}
