import React, { useCallback } from "react";
import { IMaskInput } from "react-imask";

import { formatPhoneNumber } from "./utils";

export type PhoneNumberInputProps = {
  name?: string;
  value: string;
  label: JSX.Element | string;
  disabled?: boolean;
  mask: string;
  unmask?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
  "data-test"?: string;
};

const inputClassName =
  "text-body-2 h-[50px] w-full rounded-lg border border-platinum px-4 py-1.5 placeholder:italic placeholder:text-quicksilver focus:border-teal disabled:opacity-50";

export function PhoneNumberInput(props: PhoneNumberInputProps) {
  const { label, onChange, ...restProps } = props;

  const handleInputAccept = useCallback(
    (value: string) => {
      const formattedNumber = formatPhoneNumber(value);
      onChange(formattedNumber);
    },
    [onChange]
  );

  return (
    <label className="mt-5 flex flex-col items-start">
      <span className="text-body-4-semi mb-[5px]">{label}</span>

      <IMaskInput
        {...restProps}
        onAccept={handleInputAccept}
        className={inputClassName}
      />
    </label>
  );
}
