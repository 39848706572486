import * as Sentry from "@sentry/react";
import { DreamcatcherValue } from "@pairtreefamily/ui";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { useQuestionAnswer } from "..";

export type FlowContextData = ReturnType<typeof useCreateFlowValue>;
export const FlowContext = createContext<FlowContextData | null>(null);

export const useFlow = () => {
  const context = useContext(FlowContext);
  if (!context) {
    throw new Error("useFlow must be used within a FlowProvider");
  }
  return context;
};

export const useCreateFlowValue = () => {
  const [flowId, setFlowId] = useState<string | null>(null);
  const { questionAnswerService } = useQuestionAnswer();

  const flowQuery = useQuery(
    ["flow", flowId ?? "none"],
    () => (flowId ? questionAnswerService.getFlow(flowId) : null),
    {
      enabled: !!flowId,
    }
  );

  // Maintain a single temporary variable for the flow values, so we
  // can use it if we're not logged in yet.
  const [flowValues, setFlowValues] = useState<DreamcatcherValue[] | null>(
    null
  );

  const submitFlowAnswersMutation = useMutation(
    async (args: { values: DreamcatcherValue[] }) => {
      if (!flowId) {
        throw new Error("No flowId provided");
      }
      if (!questionAnswerService.isAuthorized()) {
        setFlowValues(args.values);
        throw new Error("Not authorized to submit flow answers");
      }
      const response = await questionAnswerService.submitFlowAnswers(
        flowId,
        args.values
      );
      setFlowValues(null);
      return response;
    },
    {
      retry: true,
    }
  );

  return {
    setFlowId,
    flow: flowQuery.data,
    flowIsLoading: flowQuery.isLoading,
    flowError: flowQuery.error,
    submitFlow: submitFlowAnswersMutation.mutateAsync,
    isQueuingSubmission: flowValues !== null,
  };
};

export const FlowProvider = ({ children }: { children: React.ReactNode }) => {
  const contextValue = useCreateFlowValue();

  return (
    <FlowContext.Provider value={contextValue}>{children}</FlowContext.Provider>
  );
};
