export const PairTreeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    {/* teal circle */}
    <g transform="translate(0 0)">
      <circle cx="50" cy="50" r="50" fill="#44978D" />
    </g>
    {/* white tree logo */}
    <g transform="translate(30 25)">
      <path
        d="M29.6687 25.2715C29.4759 24.1144 29.6247 22.9251 30.0962 21.854C30.5677 20.7829 31.3408 19.8782 32.3177 19.2542C33.2946 18.6302 34.4314 18.3149 35.5843 18.3484C36.7373 18.3818 37.8546 18.7623 38.795 19.4419C39.7354 20.1214 40.4566 21.0695 40.8674 22.1661C41.2783 23.2628 41.3603 24.4587 41.1031 25.6028C40.846 26.7469 40.2611 27.7877 39.4226 28.5935C38.5841 29.3994 37.5295 29.9342 36.3923 30.1302C34.8675 30.393 33.3026 30.0288 32.0417 29.1176C30.7807 28.2065 29.9272 26.823 29.6687 25.2715Z"
        fill="white"
      />
      <path
        d="M11.44 5.69208C11.2813 4.74186 11.4031 3.76508 11.7899 2.88528C12.1768 2.00548 12.8114 1.26218 13.6135 0.749375C14.4155 0.236572 15.349 -0.0226949 16.2959 0.00436256C17.2428 0.03142 18.1605 0.343587 18.933 0.901384C19.7055 1.45918 20.2981 2.23755 20.6359 3.13806C20.9736 4.03856 21.0413 5.02076 20.8304 5.96042C20.6195 6.90008 20.1395 7.755 19.4511 8.41706C18.7627 9.07911 17.8967 9.51857 16.9628 9.67986C15.7108 9.89565 14.4259 9.59683 13.3903 8.84908C12.3547 8.10133 11.6532 6.96582 11.44 5.69208Z"
        fill="white"
      />
      <path
        d="M0.719358 28.4152C0.585822 27.6127 0.689143 26.788 1.01626 26.0452C1.34337 25.3025 1.87958 24.6751 2.5571 24.2424C3.23461 23.8098 4.023 23.5912 4.82257 23.6145C5.62214 23.6377 6.39699 23.9017 7.04913 24.373C7.70128 24.8443 8.20143 25.5017 8.48635 26.2623C8.77128 27.0228 8.82817 27.8522 8.64985 28.6456C8.47152 29.4391 8.06598 30.1609 7.48451 30.7198C6.90304 31.2787 6.17174 31.6496 5.38309 31.7857C4.85937 31.876 4.32328 31.8605 3.80545 31.74C3.28762 31.6195 2.79819 31.3964 2.36511 31.0834C1.93203 30.7704 1.56378 30.3737 1.2814 29.9159C0.999021 29.4581 0.808038 28.9481 0.719358 28.4152Z"
        fill="white"
      />
      <path
        d="M32.4165 13.9873C32.2988 13.2797 32.3898 12.5525 32.6782 11.8976C32.9666 11.2426 33.4393 10.6894 34.0367 10.3079C34.634 9.9263 35.3291 9.73352 36.0341 9.75391C36.7391 9.77429 37.4224 10.0069 37.9975 10.4224C38.5726 10.8378 39.0137 11.4175 39.265 12.088C39.5164 12.7585 39.5667 13.4898 39.4097 14.1894C39.2526 14.8891 38.8952 15.5256 38.3826 16.0186C37.8701 16.5115 37.2254 16.8388 36.53 16.959C36.0682 17.0388 35.5953 17.0252 35.1386 16.9191C34.6819 16.8129 34.2502 16.6162 33.8682 16.3403C33.4862 16.0643 33.1614 15.7145 32.9123 15.3107C32.6632 14.907 32.4948 14.4573 32.4165 13.9873Z"
        fill="white"
      />
      <path
        d="M23.9592 17.0528C23.8562 16.4395 23.9343 15.8089 24.1836 15.2408C24.4329 14.6726 24.8422 14.1925 25.3597 13.8611C25.8773 13.5297 26.4797 13.3618 27.091 13.3789C27.7022 13.3959 28.2948 13.597 28.7937 13.9567C29.2926 14.3164 29.6755 14.8186 29.8939 15.3997C30.1123 15.9809 30.1564 16.6149 30.0207 17.2216C29.8849 17.8282 29.5754 18.3803 29.1313 18.808C28.6872 19.2356 28.1284 19.5196 27.5256 19.6241C27.1252 19.6932 26.7155 19.6813 26.3197 19.5893C25.9239 19.4972 25.5498 19.3266 25.2187 19.0874C24.8877 18.8482 24.6062 18.545 24.3904 18.1951C24.1745 17.8451 24.0285 17.4554 23.9607 17.048"
        fill="white"
      />
      <path
        d="M15.0962 14.6376C14.8109 12.9196 15.9992 11.2878 17.7534 10.9784C19.5076 10.6689 21.1615 11.8238 21.4483 13.5417C21.7352 15.2597 20.5454 16.8915 18.7912 17.2009C17.037 17.5104 15.3831 16.3571 15.0962 14.6376Z"
        fill="white"
      />
      <path
        d="M5.94241 36.2859C5.84662 35.7114 5.92039 35.121 6.15441 34.5891C6.38843 34.0573 6.77218 33.6081 7.25713 33.2982C7.74208 32.9883 8.30646 32.8316 8.87889 32.8481C9.45132 32.8646 10.0061 33.0534 10.4731 33.3907C10.94 33.728 11.2982 34.1986 11.5023 34.743C11.7064 35.2874 11.7473 35.8812 11.6198 36.4493C11.4922 37.0173 11.202 37.5341 10.7858 37.9344C10.3696 38.3346 9.84608 38.6002 9.28149 38.6977C8.52444 38.828 7.74751 38.6472 7.12142 38.195C6.49532 37.7427 6.07126 37.0561 5.94241 36.2859Z"
        fill="white"
      />
      <path
        d="M3.92634 5.34709C3.83541 4.80059 3.90578 4.23893 4.12856 3.73312C4.35133 3.22731 4.7165 2.80007 5.1779 2.50543C5.63929 2.21079 6.17619 2.06198 6.7207 2.0778C7.26521 2.09363 7.79289 2.27339 8.237 2.59436C8.68111 2.91532 9.02171 3.36307 9.21574 3.881C9.40977 4.39892 9.44852 4.96376 9.32707 5.50409C9.20563 6.04441 8.92945 6.53597 8.53346 6.91659C8.13748 7.29722 7.63946 7.54982 7.10239 7.64246C6.74572 7.70398 6.38064 7.6934 6.02799 7.61134C5.67534 7.52928 5.34203 7.37733 5.0471 7.16418C4.75216 6.95103 4.50139 6.68085 4.30908 6.36907C4.11678 6.05729 3.98673 5.71002 3.92634 5.34709Z"
        fill="white"
      />
      <path
        d="M28.1072 34.4665C28.0131 33.9016 28.0857 33.321 28.3159 32.7982C28.5461 32.2753 28.9235 31.8337 29.4003 31.5291C29.8772 31.2245 30.4321 31.0706 30.995 31.0869C31.5578 31.1032 32.1032 31.2889 32.5623 31.6206C33.0213 31.9523 33.3734 32.4151 33.574 32.9504C33.7746 33.4857 33.8147 34.0696 33.6892 34.6281C33.5637 35.1866 33.2783 35.6947 32.869 36.0881C32.4597 36.4815 31.9449 36.7426 31.3898 36.8384C31.0212 36.9022 30.6438 36.8914 30.2793 36.8067C29.9147 36.722 29.5702 36.565 29.2653 36.3447C28.9604 36.1245 28.7012 35.8452 28.5025 35.5229C28.3038 35.2006 28.1694 34.8416 28.1072 34.4665Z"
        fill="white"
      />
      <path
        d="M22.3869 6.89083C22.1947 5.73993 22.3422 4.55688 22.8108 3.49129C23.2794 2.42571 24.0481 1.52547 25.0196 0.904433C25.9911 0.283397 27.1217 -0.0305353 28.2686 0.00234177C29.4154 0.0352188 30.5269 0.413429 31.4625 1.08914C32.3981 1.76484 33.1157 2.70769 33.5246 3.79843C33.9335 4.88917 34.0153 6.0788 33.7597 7.21686C33.5041 8.35491 32.9225 9.39027 32.0885 10.192C31.2546 10.9937 30.2057 11.5257 29.0745 11.7208C27.5583 11.9823 26.002 11.6204 24.7479 10.7147C23.4939 9.80894 22.6446 8.43352 22.3869 6.89083Z"
        fill="white"
      />
      <path
        d="M10.7316 29.2495C10.4357 27.4763 10.6632 25.6536 11.3855 24.012C12.1077 22.3704 13.2923 20.9837 14.7892 20.0272C16.2862 19.0707 18.0283 18.5875 19.7952 18.6387C21.5621 18.6899 23.2744 19.2731 24.7155 20.3146C26.1566 21.3562 27.2617 22.8092 27.8911 24.49C28.5204 26.1707 28.6457 28.0036 28.2511 29.7568C27.8565 31.51 26.9598 33.1047 25.6743 34.3393C24.3888 35.5738 22.7724 36.3927 21.0295 36.6923C18.6943 37.0937 16.298 36.5355 14.367 35.1402C12.4361 33.7449 11.1285 31.6268 10.7316 29.2511"
        fill="white"
      />
      <path
        d="M0.0920057 16.6989C-0.491157 13.1609 1.75841 9.82233 5.12414 9.24171C8.48987 8.66109 11.6926 11.0585 12.2836 14.5965C12.8746 18.1345 10.6234 21.4746 7.25927 22.0552C3.89511 22.6359 0.68771 20.2368 0.0920057 16.6989Z"
        fill="white"
      />
      <path
        d="M21.4811 49.9999H17.5918L18.2533 39.2058H20.8196L21.4811 49.9999Z"
        fill="white"
      />
    </g>
  </svg>
);
