export const MagnifyingGlass = () => (
  <svg
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1808 19.7086C15.5033 19.7086 19.0074 16.2045 19.0074 11.882C19.0074 7.55949 15.5033 4.05542 11.1808 4.05542C6.85832 4.05542 3.35425 7.55949 3.35425 11.882C3.35425 16.2045 6.85832 19.7086 11.1808 19.7086Z"
      stroke="#21272A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.94458 9.64575L13.4169 14.1181"
      stroke="#21272A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.4169 9.64575L8.94458 14.1181"
      stroke="#21272A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.4797 24.1809L16.7712 17.4724"
      stroke="#21272A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
