import { useAuthStatus } from "@pairtreefamily/ui";
import { createContext, ReactNode, useEffect, useState } from "react";
import { CoreAPI } from "../utils/coreClient";

export const CoreAPIContext = createContext<CoreAPI | null>(null);

export type CoreAPIProviderProps = {
  children: ReactNode;
};

export const CoreAPIProvider = (props: CoreAPIProviderProps) => {
  const [coreApi, setCoreApi] = useState<CoreAPI>(new CoreAPI());
  const { getToken } = useAuthStatus();

  useEffect(() => {
    const initCoreApi = async () => {
      const coreApi = new CoreAPI();
      const token = await getToken();
      coreApi.setUserAuthorizationHeader(token);
      setCoreApi(coreApi);
    };
    initCoreApi();
  }, [getToken]);

  return (
    <CoreAPIContext.Provider value={coreApi}>
      {props.children}
    </CoreAPIContext.Provider>
  );
};
