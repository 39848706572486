import { useContext } from "react";
import { AccountContext } from "../components/AccountProvider";

export const useAccount = () => {
  const data = useContext(AccountContext);
  if (data === null) {
    throw new Error("Tried to useAccount without a suitable parent provider.");
  }
  return data;
};
