// When adding a function to this file, please ensure that it is idempotent.
// This means that it can be run multiple times on the same input and produce the same output.
// This is important because there is no way to know if a flow has already been transformed.
// You should write your functions keeping in mind that the flow may have already been transformed.
import {
  BaseDreamcatcherInput,
  DreamcatcherFlowProps,
  DreamcatcherStepProps,
} from "@pairtreefamily/ui";

type FlowTransform = (flow: DreamcatcherFlowProps) => DreamcatcherFlowProps;
type StepTransform = (step: DreamcatcherStepProps) => DreamcatcherStepProps;
type InputTransform = (input: BaseDreamcatcherInput) => BaseDreamcatcherInput;

const flowTransforms: FlowTransform[] = [
  // More transforms...
];

const stepTransforms: StepTransform[] = [
  // More transforms...
];

const inputTransforms: InputTransform[] = [
  // Move options to metadata.
  (input) => {
    if (input.type === "single-select" || input.type === "multi-select") {
      if ((input as any).options) {
        const options = (input as any).options;
        delete (input as any).options;
        input.metadata = {
          ...input.metadata,
          options: JSON.stringify(options),
        };
      }
    }
    return input;
  },
  // Move hubspotSyncInternalValue, coreSyncInternalValue, hubspotSyncInternalName, coreSyncInternalName
  // from top level on input to input.metadata and option to option.metadata.
  (input) => {
    const metadataKeys = [
      "hubspotSyncInternalValue",
      "coreSyncInternalValue",
      "hubspotSyncInternalName",
      "coreSyncInternalName",
    ];

    for (const key of metadataKeys) {
      if ((input as any)[key]) {
        const value = (input as any)[key];
        delete (input as any)[key];
        (input.metadata as any) = {
          ...input.metadata,
          [key]: value,
        };
      }
    }

    if ((input as any).metadata?.options) {
      try {
        // Parse the options string
        const options = JSON.parse((input as any).metadata.options);
        // Assert that it's an array of objects
        if (!Array.isArray(options)) {
          throw new Error("Options is not an array");
        }
        // Check each metadata key to see if it exists at the options top level
        for (const key of metadataKeys) {
          for (let idx = 0; idx < options.length; idx++) {
            const option = options[idx];
            if (option[key]) {
              // If it does, move it to the metadata object
              if (!option.metadata) {
                option.metadata = {};
              }
              option.metadata = {
                ...option.metadata,
                [key]: option[key],
              };
              delete option[key];
            }
          }
        }

        // Finally, set the options to the parsed options
        input.metadata = {
          ...input.metadata,
          options: JSON.stringify(options),
        };
      } catch (e) {
        // If there's an error, just return the input
        return input;
      }
    }

    return input;
  },
  // More transforms...
];

const transformInput = (
  input: BaseDreamcatcherInput
): BaseDreamcatcherInput => {
  for (const transform of inputTransforms) {
    input = transform(input);
  }
  return input;
};

const transformStep = (step: DreamcatcherStepProps): DreamcatcherStepProps => {
  for (const transform of stepTransforms) {
    step = transform(step);
  }
  step.inputs = step.inputs.map(transformInput);
  return step;
};

export const runIdempotentBackwardsCompatibilityFlowTransformations = (
  flow: DreamcatcherFlowProps
): DreamcatcherFlowProps => {
  for (const transform of flowTransforms) {
    flow = transform(flow);
  }
  flow.steps = flow.steps.map(transformStep);
  return flow;
};
