export const InfoIcon = (props: any) => {
  const { className = "", ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className={`ml-2 ${className}`}
      {...otherProps}
    >
      <circle cx="7.5" cy="7.5" r="7" stroke="#414141" />
      <path
        d="M7.8961 5.34783C7.67871 5.34783 7.50117 5.28261 7.36349 5.15217C7.23306 5.02174 7.16784 4.84783 7.16784 4.63044C7.16784 4.36957 7.24755 4.15942 7.40697 4C7.56639 3.83333 7.77291 3.75 8.02653 3.75C8.23668 3.75 8.41059 3.81522 8.54827 3.94565C8.68595 4.07609 8.7548 4.25 8.7548 4.46739C8.7548 4.72101 8.67508 4.93116 8.51566 5.09783C8.35624 5.26449 8.14972 5.34783 7.8961 5.34783ZM7.36349 11.25C7.00117 11.25 6.73306 11.1486 6.55914 10.9457C6.38523 10.7355 6.33451 10.4275 6.40697 10.0217L6.83088 7.59783L6.99393 6.19565L8.37436 6.09783L7.72219 9.84783C7.67146 10.1159 7.76204 10.25 7.99393 10.25C8.0519 10.25 8.13523 10.2283 8.24393 10.1848L7.99393 11.1304C7.83451 11.2101 7.62436 11.25 7.36349 11.25Z"
        fill="#414141"
      />
    </svg>
  );
};
