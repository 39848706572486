import { getClassName } from "@pairtreefamily/utils";
import { useState } from "react";
import { Modal, ModalProps } from "../..";
import ArrowIcon from "../../components/icons/Arrow";

export type DreamcatcherCubeProps = {
  icon?: React.ReactNode;
  text: string;
  selected?: boolean;
  style?:
    | "pathing" // pathing is the default. It's the style used which immediately navigates to a new page.
    | "input"; // input is the style used for selecting a single or multiple options.
  action:
    | { type: "link"; url: string }
    | { type: "noop" }
    | { type: "modal"; modal: Pick<ModalProps, "title" | "children"> };
  // called when the button is clicked.
  actionCallback?: () => void;
  "data-test"?: string;
  bgColor?: "bg-darkblue" | "bg-teal" | "bg-green";
};
export const DreamcatcherCube = ({
  icon,
  text,
  selected = false,
  style = "pathing",
  action,
  bgColor,
  actionCallback,
  ...props
}: DreamcatcherCubeProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onClickAction = () => {
    actionCallback && actionCallback();
    if (action.type === "link") {
      const openedWindow = window.open(action.url, "_blank");
      if (!openedWindow) {
        console.warn("Failed to open a new window from dreamcatcher action");
      }
    }
    if (action.type == "modal") {
      setIsModalOpen(true);
    }
  };

  const cubeDimensionStyles = style == "pathing" ? "w-[236px]" : "w-[175px]";
  const textStyles =
    style == "pathing" ? "text-head-4 mb-9" : "text-body-1 font-bold mb-4";
  const showArrow = style == "pathing";

  return (
    <>
      <button
        className={getClassName(
          cubeDimensionStyles,
          "relative flex h-full flex-col items-start rounded-[10px] border-[2px] border-[#F3F4F5] p-2 transition-colors hover:border-teal-tint",
          selected ? "bg-teal text-white" : "text-[#414141]",
          bgColor ?? "",
          style === "pathing" && "py-8"
        )}
        onClick={onClickAction}
        aria-label="button"
        data-test={props["data-test"]}
      >
        <div className="flex w-full justify-center pb-[10px]">
          <span
            className={getClassName("m-auto", bgColor ? "!text-white" : "")}
          >
            {icon}
          </span>
        </div>
        <div
          className={getClassName(
            textStyles,
            "m-auto  text-center",
            bgColor
              ? "text-head-3 font-rebrand text-[35px] italic !text-white"
              : ""
          )}
        >
          {text}
        </div>
        {showArrow && (
          <div className="flex w-full justify-center ">
            <span
              className={getClassName("m-auto", bgColor ? "!text-white" : "")}
            >
              <ArrowIcon />
            </span>
          </div>
        )}
      </button>
      {action.type == "modal" && (
        <Modal
          title={action.modal.title}
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
        >
          {action.modal.children}
        </Modal>
      )}
    </>
  );
};
