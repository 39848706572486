import { ModalProps, Modal } from "../Modal";
import { Form, FormProps } from "../form";
import {
  FormModalActions,
  ForModalActionProps,
} from "./components/FormModalActions";

type FormModalProps<T extends Record<string, any>> = Omit<
  ModalProps,
  "primaryAction"
> &
  FormProps<T> &
  Omit<ForModalActionProps, "onCancel" | "isLoading">;

export function FormModal<T extends Record<string, any>>({
  formMethods,
  onSubmit,
  children,
  submitLabel,
  isSubmitDisabled,
  ...rest
}: FormModalProps<T>) {
  return (
    <Modal {...rest}>
      <Form<T> formMethods={formMethods} onSubmit={onSubmit}>
        {children}

        <FormModalActions
          onCancel={rest.onClose}
          isSubmitDisabled={isSubmitDisabled}
          isLoading={formMethods.formState.isSubmitting}
          submitLabel={submitLabel}
        />
      </Form>
    </Modal>
  );
}
