import { Metadata } from "../..";
import { testMetadataHasLabel } from "./label";

export type MetadataLabelProps<M extends Metadata> = {
  metadata: M;
};

export function MetadataLabel<M extends Metadata>(
  props: MetadataLabelProps<M>
) {
  const labelRes = testMetadataHasLabel(props.metadata ?? {});

  if (!labelRes.success || labelRes.metadata.label === undefined) {
    return null;
  }

  return (
    <p className="text-body-3 mb-8 text-center text-center text-[#A0A0A0]">
      <em>{labelRes.metadata.label}</em>
    </p>
  );
}
