import { Fragment, ReactNode, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, Button } from "@pairtreefamily/ui";
import { ModalActionsWrapper } from "./ModalActionsWrapper";

export type ValidationError = {
  field: string;
  message: string;
};

export interface ModalProps {
  // allows titles to have customizable styles in specific use cases
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  primaryAction?: {
    label?: string;
    // returns a promise whose result determines whether the modal should close
    // or remain open
    // e.g.
    //    onClick: () => { return Promise.resolve(confirm('close modal?')) }
    // if a `string` is returned, the modal will remain open and display the
    // returned string as an error message. If resolve as true will just close
    //and if resolve as false will just keep opened without any error message
    onSubmit: () => Promise<boolean | string>;
    disabled?: boolean;
    hideErrorMessage?: boolean;
  };
  children: ReactNode;
  maxWidth?: string;
  validationErrors?: ValidationError[];
}

export function Modal(props: ModalProps) {
  const {
    title,
    validationErrors,
    isOpen,
    primaryAction,
    maxWidth,
    onClose,
    children,
  } = props;
  const [primaryLoading, setPrimaryLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const cancelButtonRef = useRef(null);

  const hasValidationErrors = (validationErrors?.length || 0) > 0;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-lightgray bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                style={{
                  maxWidth: maxWidth ?? "unset",
                  width: maxWidth ? "100%" : "unset",
                }}
                className="relative transform rounded-[25px] bg-white px-4 pt-5 pb-4 text-left text-darkgray shadow-xl transition-all sm:my-8 sm:p-6"
              >
                <div>
                  <div className="absolute top-0 right-0 hidden pt-5 pr-5 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray hover:scale-110 hover:text-darkgray focus:ring-teal-tint "
                      onClick={() => onClose()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title className="text-head-7 text-onyx">
                      {title}
                    </Dialog.Title>
                    {hasValidationErrors && (
                      <div className="w-[500px]">
                        {validationErrors?.map((error, idx) => (
                          <p key={idx} className="text-rust">
                            {error.message}
                          </p>
                        ))}
                      </div>
                    )}
                    <div className="mt-2">{children}</div>
                    {error !== null && !primaryAction?.hideErrorMessage && (
                      <p className="text-rust">{error}</p>
                    )}
                    {/* modal bottom tray */}
                    {primaryAction && (
                      <ModalActionsWrapper>
                        <Button
                          color="teal"
                          style="secondary"
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={async () => {
                            setPrimaryLoading(true);
                            const res = await primaryAction.onSubmit();
                            setPrimaryLoading(false);
                            if (res === false) {
                              setError(null);
                              return;
                            }
                            if (res === true) {
                              setError(null);
                              onClose();
                              return;
                            }
                            const err =
                              res ||
                              "We encountered a server error while trying to submit; please try again soon.";
                            setError(err);
                            return;
                          }}
                          loading={primaryLoading}
                          disabled={primaryAction.disabled}
                          color="teal"
                          style="primary"
                        >
                          {primaryAction.label ?? "Submit"}
                        </Button>
                      </ModalActionsWrapper>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
