import React, { ReactNode, useState } from "react";

export interface CheckboxProps {
  label?: ReactNode;
  isChecked: boolean;
  onCheckboxChange: (checked: boolean) => void;
  className?: string;
  labelClickable?: boolean;
  name?: string;
  disabled?: boolean;
}

export const Checkbox = ({
  label,
  isChecked,
  onCheckboxChange,
  className,
  name,
  labelClickable = true,
  disabled,
}: CheckboxProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onCheckboxChange(!isChecked);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const styles = {
    hiddenCheckbox: {
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      overflow: "hidden",
      width: "1px",
      height: "1px",
      position: "absolute" as const,
    },
    customCheckbox: {
      height: "25px",
      width: "25px",
      borderRadius: "3px",
      boxShadow: isChecked ? "inset 0 0 0 2px white" : "none",
      display: "inline-block",
      cursor: "pointer",
      padding: "4px",
      verticalAlign: "middle",
    },
    checkmark: {
      stroke: isChecked ? "white" : "transparent",
      strokeWidth: "2",
    },
    label: {
      cursor: labelClickable ? "pointer" : "default",
      width: "100%",
    },
    checkboxContainer: {
      display: "inline-flex",
      alignItems: "start",
      gap: "10px",
      position: "relative" as const,
    },
  };

  return (
    <div className={className} style={styles.checkboxContainer}>
      <div onClick={handleClick}>
        <input
          name={name}
          type="checkbox"
          style={styles.hiddenCheckbox}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
        <svg
          className={`checkbox ${isChecked ? "bg-teal" : "white"} ${
            isFocused ? "border-[2px] border-ring" : "border-[1px] border-teal"
          }`}
          style={styles.customCheckbox}
          aria-hidden="true"
          viewBox="0 0 15 11"
          fill="none"
          onClick={handleClick}
        >
          <path d="M1 4.5L5 9L14 1" style={styles.checkmark} />
        </svg>
      </div>
      <span
        style={styles.label}
        className={"text-body-3 text-left"}
        onClick={labelClickable ? handleClick : undefined}
      >
        {label}
      </span>
    </div>
  );
};
