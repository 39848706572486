import {
  BaseDreamcatcherInput,
  DreamcatcherInput,
  tryGetMetadataSchema,
} from "@pairtreefamily/ui";
import { CustomInputControl } from "./contrib/customInputControls";
import MetadataInput from "./MetadataInput";

export interface InputEditorProps {
  //input: DreamcatcherInput;
  input: BaseDreamcatcherInput;
  setInput: (input: Partial<DreamcatcherInput>) => void;
}

const InputEditor = ({ input, setInput }: InputEditorProps) => {
  return (
    <div className="p-2">
      <CustomInputControl
        metadata={input.metadata ?? {}}
        setMetadata={(metadata) => {
          setInput({
            metadata,
          });
        }}
      />
      <MetadataInput
        metadata={input.metadata ?? {}}
        documentationLink="https://docs.pairtreefamily.com/uhvhwNxzutFsz5/b/77044B5E-4F96-4BA7-BB9E-09F0CAE73910/Metadata-on-Input"
        setMetadata={(metadata) => {
          // @ts-ignore
          setInput({ metadata });
        }}
        schemaValidator={tryGetMetadataSchema(input.type) ?? undefined}
      />
    </div>
  );
};

export default InputEditor;
