import { useEffect, useMemo, useState } from "react";
import * as firebaseAuthWrapper from "firebase/auth";
import type { FirebaseApp } from "firebase/app";
import { getAnalytics, setUserId } from "firebase/analytics";
import { IdTokenResult } from "firebase/auth";

export type UseFirebaseAuthData = {
  isAuthenticated: boolean;
  isLoading: boolean;
  signOut: () => void;
  signInWithGoogle: () => Promise<{ token: string; needsAccount: boolean }>;
  signUpWithEmail: (
    email: string,
    password: string
  ) => Promise<{ token: string; needsAccount: boolean }>;
  signInWithEmail: (
    email: string,
    password: string
  ) => Promise<{ token: string; needsAccount: boolean }>;
  currentUser: firebaseAuthWrapper.User | null;
  // Does this user need to create a pairtree account?
  // Prefer using the `needsAccount` property from the response of
  // the signIn/signUp methods if you have it.
  needsAccount: boolean;
  isAdmin: boolean;
  isFlowBuilderUser: boolean;
};

function getUseFirebaseEmulator() {
  // If Cypress is defined, we're in a test environment
  if (typeof Cypress !== "undefined") {
    return Cypress.env("NEXT_PUBLIC_USE_FIREBASE_EMULATOR") ?? false;
  } else if (typeof process !== "undefined") {
    // We're not in Cypress, so let's use process.env
    return process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR ?? false;
  }

  // If neither Cypress nor process is available, default to false
  return false;
}
const USE_FIREBASE_EMULATOR = Boolean(getUseFirebaseEmulator());

const checkTokenNeedsAccount = (tokenResult: IdTokenResult): boolean => {
  return !tokenResult.claims.pairtreeAccountUUID;
};

/**
 * This lives in a hook so queries can automatically update when the token changes.
 * It automatically watches for changes to the firebase auth token.
 * It also supports providing an initialJwt to log in with automatically.
 */
export const useFirebaseAuth = ({
  app,
  getSSOCustomJwt,
}: {
  app: FirebaseApp;
  getSSOCustomJwt?: () => string | null;
}): UseFirebaseAuthData => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] =
    useState<firebaseAuthWrapper.User | null>(null);
  const [needsAccount, setNeedsAccount] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const checkTokenIsAdmin = (tokenResult: IdTokenResult): boolean => {
    return tokenResult.claims.isAdmin;
  };
  const [isFlowBuilderUser, setIsFlowBuilderUser] = useState(false);
  const checkTokenIsFlowBuilderUser = (tokenResult: IdTokenResult): boolean => {
    return tokenResult.claims.isFlowBuilderUser;
  };

  const auth = useMemo(() => firebaseAuthWrapper.getAuth(app), [app]);
  const googleProvider = useMemo(
    () => new firebaseAuthWrapper.GoogleAuthProvider(),
    []
  );

  useEffect(() => {
    if (USE_FIREBASE_EMULATOR && auth.emulatorConfig === null) {
      firebaseAuthWrapper.connectAuthEmulator(auth, "http://localhost:9099");
    }

    // When we have app already initialized in one tab and then open in another one, setPersistence function
    // executes onAuthStateChanged callback with null value and then again with correct value.
    // This leads to page refresh at the first tab.
    // We need a workaround to prevent this behavior.
    // There are ability to set Persistence with initializeAuth function.
    firebaseAuthWrapper.setPersistence(
      auth,
      firebaseAuthWrapper.browserLocalPersistence
    );
  }, []);

  // On mount, check for an initialJwt
  useEffect(() => {
    if (getSSOCustomJwt) {
      const initialJwt = getSSOCustomJwt();
      if (initialJwt) {
        firebaseAuthWrapper.signInWithCustomToken(auth, initialJwt);
      }
    }
  }, [getSSOCustomJwt, auth]);

  // TODO is there any way to move the token creation code here?
  // It's currently in the `platform` project.
  // I can't do it right now, because would rely on us having a Core API client here, which creates a circular
  // dependency between this file and the Core API client. It's currently not possible,
  // but would be nice so the cohesion is better.

  useEffect(() => {
    // in addition to changes in auth state, this will also be called
    // once when firebase initializes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoading(true);
      setCurrentUser(user);

      // unauthenticated
      if (!user) {
        setIsLoading(false);
        setNeedsAccount(true);
        return;
      }

      // authenticated
      user
        .getIdTokenResult()
        .then((idTokenResult: IdTokenResult) => {
          const needsAccount = checkTokenNeedsAccount(idTokenResult);
          setNeedsAccount(needsAccount);
          setIsFlowBuilderUser(checkTokenIsFlowBuilderUser(idTokenResult));
          setIsAdmin(checkTokenIsAdmin(idTokenResult));
          if (!needsAccount) {
            // Google Analytics identification
            setUserId(
              getAnalytics(app),
              idTokenResult.claims.pairtreeAccountUUID
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
    return unsubscribe;
  }, [auth]);

  const signInWithGoogle = async () => {
    const credentials = await firebaseAuthWrapper.signInWithPopup(
      auth,
      googleProvider
    );
    const tokenResult = await credentials.user.getIdTokenResult();
    return {
      token: tokenResult.token,
      needsAccount: checkTokenNeedsAccount(tokenResult),
    };
  };

  const signInWithEmail = async (email: string, password: string) => {
    const credentials = await firebaseAuthWrapper.signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const tokenResult = await credentials.user.getIdTokenResult();
    return {
      token: tokenResult.token,
      needsAccount: false,
    };
  };

  const signUpWithEmail = async (email: string, password: string) => {
    const credentials =
      await firebaseAuthWrapper.createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
    const tokenResult = await credentials.user.getIdTokenResult();
    return {
      token: tokenResult.token,
      needsAccount: checkTokenNeedsAccount(tokenResult),
    };
  };

  return {
    isAuthenticated: !!currentUser,
    isLoading: isLoading,
    signOut: () => auth.signOut(),
    signInWithGoogle,
    signInWithEmail,
    signUpWithEmail,
    currentUser,
    needsAccount,
    isAdmin,
    isFlowBuilderUser,
  };
};
