import { z, ZodDate, ZodEffects } from "zod";

const todayDate = new Date();

export const dateValidationSchema = z.date({
  invalid_type_error: "Date is Invalid",
});

export const withMinDateValidation = (
  schema: ZodDate
): ZodEffects<ZodDate, Date, Date> => {
  return schema.refine((date) => date > todayDate, {
    params: {
      i18n: "errors.too_small.date.not_inclusive",
    },
  });
};
