// why is this called "website2"?
export const Website2Icon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 3.01923C1 1.90404 1.90404 1 3.01923 1H13.9808C15.096 1 16 1.90404 16 3.01923V13.9808C16 15.096 15.096 16 13.9808 16H3.01923C1.90404 16 1 15.096 1 13.9808V5.76607C1 5.60676 1.12915 5.47761 1.28846 5.47761C1.44777 5.47761 1.57692 5.60676 1.57692 5.76607V13.9808C1.57692 14.7773 2.22267 15.4231 3.01923 15.4231H13.9808C14.7773 15.4231 15.4231 14.7773 15.4231 13.9808V3.01923C15.4231 2.22267 14.7773 1.57692 13.9808 1.57692H3.01923C2.22267 1.57692 1.57692 2.22267 1.57692 3.01923V3.83295C1.57692 3.99226 1.70607 4.12141 1.86538 4.12141H13.9894C14.1487 4.12141 14.2778 4.25056 14.2778 4.40987C14.2778 4.56919 14.1487 4.69834 13.9894 4.69834H1.86538C1.38745 4.69834 1 4.31089 1 3.83295V3.01923Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.22589 2.94595C3.22589 3.12429 3.08132 3.26886 2.90298 3.26886C2.72465 3.26886 2.58008 3.12429 2.58008 2.94595C2.58008 2.76762 2.72465 2.62305 2.90298 2.62305C3.08132 2.62305 3.22589 2.76762 3.22589 2.94595Z"
      fill="currentColor"
    />
    <path
      d="M4.3023 2.94595C4.3023 3.12429 4.15773 3.26886 3.9794 3.26886C3.80106 3.26886 3.65649 3.12429 3.65649 2.94595C3.65649 2.76762 3.80106 2.62305 3.9794 2.62305C4.15773 2.62305 4.3023 2.76762 4.3023 2.94595Z"
      fill="currentColor"
    />
    <path
      d="M5.37872 2.94595C5.37872 3.12429 5.23415 3.26886 5.05581 3.26886C4.87748 3.26886 4.73291 3.12429 4.73291 2.94595C4.73291 2.76762 4.87748 2.62305 5.05581 2.62305C5.23415 2.62305 5.37872 2.76762 5.37872 2.94595Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.1731 8.49989C4.1731 8.34057 4.30224 8.21143 4.46156 8.21143H12.5385C12.6978 8.21143 12.8269 8.34057 12.8269 8.49989C12.8269 8.6592 12.6978 8.78835 12.5385 8.78835H4.46156C4.30224 8.78835 4.1731 8.6592 4.1731 8.49989Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.1731 11.3847C4.1731 11.2253 4.30224 11.0962 4.46156 11.0962H12.5385C12.6978 11.0962 12.8269 11.2253 12.8269 11.3847C12.8269 11.544 12.6978 11.6731 12.5385 11.6731H4.46156C4.30224 11.6731 4.1731 11.544 4.1731 11.3847Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
  </svg>
);
