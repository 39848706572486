import { z } from "zod";
import { BaseDreamcatcherInput } from "../../..";
import { Loading } from "../../../..";
import { DreamcatcherInputPlugin } from "../../inputs";
import { DreamcatcherInputBaseMetadataSchema } from "../../metadata";
import { DreamcatcherInputMetadataWithLabelSchema } from "../../metadata";
import { MetadataLabel } from "../../metadata";
import { DreamcatcherMultiSelectComponent } from "../select";
import {
  DreamcatcherRemoteConfigurationSchema,
  useDynamicAPIOptions,
} from "./shared";

type inputType = "dynamic-multi-select";

export const DreamcatcherDynamicMultiSelectMetadataSchema =
  DreamcatcherRemoteConfigurationSchema.merge(
    DreamcatcherInputMetadataWithLabelSchema
  ).merge(DreamcatcherInputBaseMetadataSchema);

export type DreamcatcherDynamicMultiSelectMetadata = z.infer<
  typeof DreamcatcherDynamicMultiSelectMetadataSchema
>;

export type DynamicMultiSelectInput = BaseDreamcatcherInput<
  DreamcatcherDynamicMultiSelectMetadata,
  inputType
>;

const DynamicMultiSelect = (props: DynamicMultiSelectInput) => {
  if (!props.metadata) {
    throw new Error("Metadata is required for DynamicMultiSelect");
  }

  const remote = DreamcatcherRemoteConfigurationSchema.parse(props.metadata);

  const { options, status, error } = useDynamicAPIOptions(props.id, remote);
  const childProps = {
    ...props,
    type: "multi-select" as "multi-select",
    metadata: {
      ...props.metadata,
      options: JSON.stringify(options),
    },
  };
  return (
    <div>
      <>
        {props.metadata && <MetadataLabel metadata={props.metadata} />}
        {status == "loading" && <Loading />}
        {error && (
          <div className="text-red-600">
            <>Error: {error.toString()}</>
          </div>
        )}
        <DreamcatcherMultiSelectComponent {...childProps} />
      </>
    </div>
  );
};

const DynamicMultiSelectPlugin: DreamcatcherInputPlugin<
  DreamcatcherDynamicMultiSelectMetadata,
  inputType
> = {
  component: DynamicMultiSelect,
  type: "dynamic-multi-select",
  name: "Dynamic Multi-select",
  metadataSchema: DreamcatcherDynamicMultiSelectMetadataSchema,
};

export default DynamicMultiSelectPlugin;
