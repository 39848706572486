import { ReactNode } from "react";
import { Spinner } from "../icons";
import { getButtonStyle } from "./style";

export type ButtonProps = {
  children?: ReactNode;
  onClick?: () => void;
  style?: ButtonStyle;
  otherStyles?: string;
  color?: ButtonColor;
  "data-test"?: string;
  disabled?: boolean;
  loading?: boolean;
  textAlign?: "center" | "left";
  height?: "50px" | "25px";
  type?: "button" | "submit" | "reset";
};

export type ButtonStyle = "primary" | "secondary" | "text";

export type ButtonColor = //Extract<
  //Colors.Solid,
  "colorless" | "teal" | "green" | "purple" | "peach" | "rust";
//>;

export function Button(props: ButtonProps) {
  const buttonStyle = getButtonStyle(props);

  return (
    <button
      type={props.type}
      disabled={props.disabled || props.loading}
      className={`${buttonStyle} ${props.otherStyles}`}
      onClick={props.onClick}
      data-test={props["data-test"]}
    >
      {props.loading && <Spinner adaptive className="mr-2 w-[16px]" />}
      {props.children}
    </button>
  );
}

export default Button;
