import { Button, Metadata, TextInput } from "@pairtreefamily/ui";
import { useEffect, useState } from "react";
import { z } from "zod";
export interface MetadataInputProps {
  // This is a function that will be called when the metadata is updated and
  // the metadata passes the schemaValidator check (if provided).
  setMetadata: (metadata: Metadata) => void;
  metadata: Metadata;
  documentationLink?: string;
  schemaValidator?: z.AnyZodObject;
}

const MetadataInput = (props: MetadataInputProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localMetadata, setLocalMetadata] = useState(props.metadata);
  const [validationError, setValidationError] = useState<string | null>(null);

  // If a new metadata comes in through props, clear the error,
  // set to not editing, and update the local metadata.
  useEffect(() => {
    setValidationError(null);
    setIsEditing(false);
    setLocalMetadata(props.metadata);
  }, [props.metadata]);

  return (
    <div>
      <div className="mb-2 mt-7 flex w-full flex-row items-center justify-between">
        <h6 className="font-bold">Metadata</h6>
        <Button
          style="secondary"
          color="green"
          disabled={isEditing}
          onClick={() => setIsEditing(!isEditing)}
        >
          Edit Metadata
        </Button>
      </div>

      {isEditing && (
        <div>
          <p className="text-darkgray-tint">
            Metadata is a set of key-value pairs that can be used to describe
            your data. Metadata is optional, but can be useful for adding
            additional functionality to your data. For example, you can use
            special metadata keys to sync data to Hubspot or Core API. Depending
            on the place you&apos;re using the form, different metadata keys may
            be supported.
            {props.documentationLink && (
              <a
                href={props.documentationLink}
                target="_blank"
                rel="noreferrer"
                className="text-teal"
              >
                {" "}
                Learn more about supported metadata.
              </a>
            )}
          </p>
        </div>
      )}

      {!isEditing ? (
        <div>
          {Object.entries(props.metadata).map(([key, value]) => (
            <div key={key} className="mb-2 flex">
              <span className="flex-1 font-bold text-darkgray-shade">
                {key}
              </span>
              <span className="w-64 flex-initial truncate text-darkgray-tint">
                {value}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {Object.entries(localMetadata).map(([key, value], idx) => (
            <div key={idx} className="flex">
              <TextInput
                label="Key"
                value={key}
                onChange={(value) => {
                  const oldValue = localMetadata[key];
                  delete localMetadata[key];
                  setLocalMetadata({
                    ...localMetadata,
                    [value]: oldValue,
                  });
                }}
              />
              <textarea
                className="ml-2 flex-1 rounded-md border border-backgroundgray-shade p-2"
                value={value}
                onChange={(e) => {
                  const newValue =
                    e.target.value == "" ? undefined : e.target.value;
                  const newLocalMetadata = {
                    ...localMetadata,
                    [key]: newValue,
                  };
                  setLocalMetadata(newLocalMetadata);
                  if (props.schemaValidator) {
                    const result =
                      props.schemaValidator?.safeParse(newLocalMetadata);
                    if (!result.success) {
                      setValidationError(JSON.stringify(result.error.format()));
                    } else {
                      setValidationError(null);
                    }
                  }
                }}
              />
            </div>
          ))}
        </div>
      )}

      {isEditing && (
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            style="secondary"
            onClick={() => {
              setLocalMetadata({
                ...localMetadata,
                "": "",
              });
            }}
          >
            + Add new item
          </Button>
          <div>
            <Button
              style="secondary"
              onClick={() => {
                setLocalMetadata(props.metadata);
                setIsEditing(false);
                setValidationError(null);
              }}
            >
              Cancel
            </Button>
            <Button
              style="primary"
              color="teal"
              disabled={!!validationError}
              onClick={() => {
                if (validationError) {
                  return;
                }
                props.setMetadata(localMetadata);
                setIsEditing(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {validationError && <div className="text-red-600">{validationError}</div>}
    </div>
  );
};

export default MetadataInput;
