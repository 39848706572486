import {
  BaseDreamcatcherInput,
  ConditionalStrategy,
  DreamcatcherCondition,
  DreamcatcherConditionalConfig,
  DreamcatcherFlowProps,
  DreamcatcherPath,
  DreamcatcherStepProps,
  DreamcatcherValue,
  Metadata,
} from ".";
//import { dreamcatcherInputMap, DreamcatcherInputType } from "..";

// Recursively searches the inputObject for the object at the given path.
// Returns null if the path is not found.
// The path is an array of strings, where each string is the ID of the object.
// For example, ["myFlow", "step1", "input1"] would return the input object with ID "input1"
export const findDreamcatcherObjectByPath = (
  inputObject:
    | DreamcatcherFlowProps
    | DreamcatcherStepProps
    | BaseDreamcatcherInput,
  path: string[]
):
  | DreamcatcherFlowProps
  | DreamcatcherStepProps
  | BaseDreamcatcherInput
  | null => {
  if (path.length === 0) {
    return inputObject;
  }
  const [first, ...rest] = path;
  if (inputObject.object == "flow") {
    if (first === inputObject.id) {
      if (rest.length === 0) {
        return inputObject;
      }
      // Search each step in the flow
      for (let step of inputObject.steps) {
        const found = findDreamcatcherObjectByPath(step, rest);
        if (found) {
          return found;
        }
      }
      return null;
    } else {
      return null;
    }
  }

  if (inputObject.object == "step") {
    if (first === inputObject.id) {
      if (rest.length === 0) {
        return inputObject;
      }
      // Search each input in the step,
      // if the input's ID matches first, return it.
      for (let input of inputObject.inputs) {
        const found = findDreamcatcherObjectByPath(input, rest);
        if (found) {
          return found;
        }
      }
      return null;
    } else {
      return null;
    }
  }

  if (inputObject.object == "input") {
    if (first === inputObject.id) {
      return findDreamcatcherObjectByPath(inputObject, rest);
    } else {
      return null;
    }
  }

  console.warn("Unknown object type", inputObject);

  return null;
};

export function pathsEqual(
  path1: DreamcatcherPath,
  path2: DreamcatcherPath
): boolean {
  if (path1.length !== path2.length) {
    return false;
  }
  for (let i = 0; i < path1.length; i++) {
    if (path1[i] !== path2[i]) {
      return false;
    }
  }
  return true;
}

type ConditionResolverArgs = {
  expectedValues: string[];
  actualValues: string[];
};

type ResolveConditionArgs = {
  strategy: ConditionalStrategy;
  negate?: boolean;
} & ConditionResolverArgs;

export function resolveCondition({
  expectedValues,
  actualValues,
  strategy,
  negate,
}: ResolveConditionArgs): boolean {
  let resolver: (args: ConditionResolverArgs) => boolean;

  switch (strategy) {
    case "intersection": {
      resolver = resolveIntersection;
      break;
    }
    case "subset": {
      resolver = resolveSubset;
      break;
    }
    case "equality": {
      resolver = resolveEquality;
      break;
    }
    default: {
      resolver = resolveIntersection;
    }
  }

  const normalResolution = resolver({ expectedValues, actualValues });
  return negate ? !normalResolution : normalResolution;
}

function resolveIntersection({
  expectedValues,
  actualValues,
}: ConditionResolverArgs): boolean {
  const actualValueSet = new Set(actualValues);
  for (let i = 0; i < expectedValues.length; i++) {
    // if any expected values are present, we pass.
    if (actualValueSet.has(expectedValues[i])) {
      return true;
    }
  }
  return false;
}

function resolveSubset({
  expectedValues,
  actualValues,
}: ConditionResolverArgs): boolean {
  const actualValueSet = new Set(actualValues);
  for (let i = 0; i < expectedValues.length; i++) {
    // if any expected values are absent from the actual, we fail.
    if (!actualValueSet.has(expectedValues[i])) {
      return false;
    }
  }
  return true;
}

function resolveEquality({
  expectedValues,
  actualValues,
}: ConditionResolverArgs): boolean {
  if (actualValues.length !== expectedValues.length) {
    return false;
  }
  const actualValueSet = new Set(actualValues);
  for (let i = 0; i < expectedValues.length; i++) {
    // if any expected values are absent from the actual, we fail.
    if (!actualValueSet.has(expectedValues[i])) {
      return false;
    }
  }
  return true;
}

export const getMetadataValueFromObject = (
  object: { metadata?: Metadata } | undefined,
  metadataKey: string
): string | undefined => {
  if (!object) {
    return undefined;
  }
  if (object.metadata?.[metadataKey]) {
    return object.metadata[metadataKey];
  }
  // For backwards compatibility, check in the old location
  if ((object as any)[metadataKey]) {
    return (object as any)[metadataKey] as string;
  }
  return undefined;
};

export function resolveDreamcatcherCondition(
  condition: DreamcatcherCondition,
  valueAccessor: (path: DreamcatcherPath) => DreamcatcherValue | null
): boolean {
  const { dependencyPath, expectedValues } = condition;
  const pathValue = valueAccessor(dependencyPath);
  return resolveCondition({
    expectedValues: expectedValues,
    actualValues: pathValue?.value ?? [],
    strategy: condition.strategy,
    negate: condition.negate,
  });
}

export function resolveAllConditions(
  conditionalConfig: DreamcatcherConditionalConfig,
  conditionResolver: (condition: DreamcatcherCondition) => boolean
): boolean {
  switch (conditionalConfig.logicalOperator) {
    case "and":
      return conditionalConfig.conditions.reduce(
        (acc: boolean, cur: DreamcatcherCondition) => {
          return acc && conditionResolver(cur);
        },
        true
      );
    case "or":
      return conditionalConfig.conditions.reduce(
        (acc: boolean, cur: DreamcatcherCondition) => {
          return acc || conditionResolver(cur);
        },
        false
      );
    default:
      return false;
  }
}

// recursively tries to find the next eligible step in the graph.
// any conditional steps whose conditions are not met will be skipped.
// if `null` is returned, there is no valid next step.
export function findNextStep(
  currentStep: DreamcatcherStepProps,
  stepAccessor: (id: string) => DreamcatcherStepProps | null,
  conditionResolver: (condition: DreamcatcherCondition) => boolean
): DreamcatcherStepProps | null {
  if (!currentStep.nextStep) {
    return null;
  }

  const nextStep = stepAccessor(currentStep.nextStep);
  if (!nextStep) {
    return null;
  }

  if (!nextStep.conditional) {
    return nextStep;
  }

  const conditionsMet = resolveAllConditions(
    nextStep.conditional,
    conditionResolver
  );

  if (conditionsMet) {
    return nextStep;
  }

  return findNextStep(nextStep, stepAccessor, conditionResolver);
}
